import React, { Component } from "react";
import Cookie from "js-cookie";
import Header2 from "components/Headers/Header2";
import { Link } from "react-router-dom";
import Select from "react-select";

import {
  Card,
  CardHeader,
  Container,
  Row,
  Col,
  Button,
  Table,
  Pagination,
  PaginationItem,
  PaginationLink,
  CardFooter,
  FormGroup,
  Input,
  InputGroupAddon,
  InputGroupText,
  InputGroup,
  Spinner,
} from "reactstrap";

const axios = require("axios").default;
const URL = require("../../ApiUrl");

export default class DailyDeals extends Component {
  constructor(props) {
    super(props);

    this.ProductDetails = this.ProductDetails.bind(this);
    this.GetSearchProducts = this.GetSearchProducts.bind(this);

    this.state = {
      succsess: false,
      error: false,
      isLoaded: false,
      selectedOption: null,
      limit: 20,
      page: 1,
      hasNextPage: false,
      orginalData: [],
      products: {},
      category: [],
      dailyDealDate: null,
      id:""
    };
  }
  GetFranchiseList = async () => {
    const token = Cookie.get("patrickToken")
      ? Cookie.get("patrickToken")
      : null;
    const config = {
      headers: { Authorization: `Bearer ${token}` },
    };

    let response = await axios
      .get(
        `${URL.BASE_URL}/admin-franchises/list?page=${1}&limit=${100}`,
        config
      )
      .catch(function (error) {
        console.log(error);
      });
    console.log("👇👇👇👇👇👇👇👇👇👇");
    console.log(response);
    if (response && response.data !== null) {
      this.setState({
        category: response.data.data.items,
      });
    } else if (response && response.data.statusCode !== 200) {
      this.setState({
        error: true,
        errorCode: response.data.msg,
      });
    } else {
      this.setState({
        status: false,
      });
    }
  };


  GetProducts = async (p) => {
    this.setState({ isLoaded: false });
    const token = Cookie.get("patrickToken")
      ? Cookie.get("patrickToken")
      : null;
    const config = {
      headers: { Authorization: `Bearer ${token}` },
    };
    let id = this.state.id;
    console.log(id,"🎆🎆🎆🎆")
    let page = p ? p : this.state.page;
    let limit = this.state.limit;
    console.log(this.state.page, "--------------");
    let dailyDealDate = Date.parse(
      new Date(this.state.dailyDealDate).toString()
    );
    console.log(dailyDealDate, "🎎🎎🎎🎎");
    let url = `${URL.BASE_URL}/admin-products/list?isDailyDeals=true&dailyDealDate=${dailyDealDate}&page=${page}&limit=${limit}&franchiseId=${id}`;

    let response = await axios.get(url, config).catch(function (error) {
      console.log(error);
    });

    console.log("👇👇👇👇👇👇👇👇👇👇");
    console.log(response);

    if (response && response.data !== 200) {
      this.setState({
        products: response.data.data,
        orginalData: response.data.data,
        isLoaded: true,
      });
    } else if (response && response.data.statusCode !== 200) {
      this.setState({
        error: true,
        errorCode: response.data.msg,
      });
    } else {
      this.setState({
        status: false,
      });
    }
  };
  async GetSearchProducts(e) {
    this.setState({ isLoaded: false });

    let searchText = e.target.value;
    if (searchText === "") {
      this.GetProducts();
    } else {
      const token = Cookie.get("patrickToken")
        ? Cookie.get("patrickToken")
        : null;
      const config = {
        headers: { Authorization: `Bearer ${token}` },
      };
      let page = this.state.page;
      let limit = this.state.limit;
      let response = await axios
        .get(
          `${URL.BASE_URL}/admin-products/search-products?searchText=${searchText}&page=${page}&limit=${limit}`,
          config
        )
        .catch(function (error) {
          console.log(error);
        });

      console.log("🎉🎉🎉🎉🎉🎉SEARCHING................❤❤💖💖💖");
      console.log(response);

      if (response && response.data !== null) {
        this.setState({
          products: response.data.data,
          page: 1,
          isLoaded: true,
        });
      } else if (response && response.data.statusCode !== 200) {
        this.setState({
          error: true,
          errorCode: response.data.msg,
        });
      } else {
        this.setState({
          status: false,
        });
      }
    }
  }

  handleChange(e) {
    this.setState({
      [e.target.data]: e.target.value,
    });
  }

  handleChangeDate = async (e) => {
    await this.setState({
      [e.target.name]: e.target.value,
    });
    console.log(this.state.dailyDealDate, "🎆🎆🎆🎆");
    this.GetProducts();
  };

  componentDidMount() {
    this.GetFranchiseList();
    this.GetProducts();
  }

  ProductDetails() {
    if (this.state.isLoaded) {
      return (
        <>
          <thead>
            <tr>
              <th className=" text-center">#</th>
              <th className=" text-center"> </th>
              <th>Product</th>
              <th>Offer Price</th>
              <th>Qty</th>
              <th>Seller Name</th>
              {/* <th>Status</th> */}
              <th className=" td-actions">Actions</th>
            </tr>
          </thead>
          <tbody>
            {this.state.products &&
              this.state.products.items.map((data, key) => {
                return (
                  <tr>
                    <td className=" text-center">
                      {(this.state.page - 1) * this.state.limit + key + 1}
                    </td>
                    <td>
                      <div className=" img-container">
                        <a onClick={(e) => e.preventDefault()}>
                          {data.imgUrl[0] ? (
                            <img
                              alt="Loading..."
                              src={data.imgUrl[0].publicUrl}
                              width="150px"
                            />
                          ) : (
                            <img
                              alt="..."
                              width="150px"
                              src={require("assets/img/theme/no.png")}
                            />
                          )}
                        </a>
                      </div>
                    </td>

                    <td>{data.name}</td>

                    <td>₹ {data.actualPrice}</td>
                    <td>{data.quantity}</td>
                    <td>{data.sellerId.sellerName}</td>
                    {/* <td>
                    {this.state.selectedOption
                      ? this.state.selectedOption.value
                      : "Aproved"}
                  </td> */}
                    <td className=" td-actions">
                      <Link to={`/admin/productsdetails/${data.id}/detail`}>
                        <Button
                          className=" btn-icon"
                          color="info"
                          size="sm"
                          type="button"
                        >
                          <i className=" ni ni-circle-08 pt-1"></i>
                          <span className="btn-inner--text">View</span>
                        </Button>
                      </Link>
                    </td>
                  </tr>
                );
              })}
          </tbody>
        </>
      );
    } else {
      return (
        <div className=" d-flex justify-content-center ">
          <Spinner
            style={{ width: "3rem", height: "3rem" }}
            type="grow"
            color="warning"
          />
        </div>
      );
    }
  }
  handleChange2 = async (selectedOptionType) => {
    this.setState({ selectedOptionType });
    console.log(`Option selected:`, selectedOptionType);
    console.log(selectedOptionType.value);
    await this.setState({
      id: selectedOptionType.value,
    });
    this.GetProducts();
  };
  FarnchiseSelect = () => {
    const { selectedOptionType } = this.state;
    let options = [{ value: "", label: "All" }];
    this.state.category.map((item) =>
      options.push({
        value: item.id,
        label: item.franchiseName,
      })
    );
    return (
      <>
        <Select
          value={selectedOptionType}
          onChange={this.handleChange2}
          options={options}
          placeholder="Select Franchise"
        />
      </>
    );
  };

  Pagination = () => {
    let totalPages = this.state.products ? this.state.products.totalPages : 0;
    let currentPage = this.state.page;
    console.log(currentPage, "🎄🎄🎄🎄🎄🎄");
    let pageItems = Array.apply(null, Array(totalPages)).map(function (x, i) {
      return i;
    });
    return (
      <>
        <nav aria-label="Page navigation example">
          <Pagination
            className="pagination justify-content-end"
            listClassName="justify-content-end"
          >
            {pageItems.map((item, key) => {
              if (currentPage === key + 1) {
                return (
                  <PaginationItem className="active">
                    <PaginationLink onClick={(e) => e.preventDefault()}>
                      {key + 1}
                    </PaginationLink>
                  </PaginationItem>
                );
              } else {
                return (
                  <PaginationItem>
                    <PaginationLink
                      onClick={(type) => {
                        this.setState({ page: key + 1 });
                        this.GetProducts(key + 1);
                      }}
                    >
                      {key + 1}
                    </PaginationLink>
                  </PaginationItem>
                );
              }
            })}
          </Pagination>
        </nav>
      </>
    );
  };

  render() {
    return (
      <>
        <Header2 />
        <Container className=" mt--7" fluid>
          {/* Table */}
          <Row>
            <div className=" col">
              <Card className=" shadow">
                <CardHeader className=" border-0">
                  <Row>
                    <Col>
                      <h2 className="mb-0">Daily Deals Products</h2>
                    </Col>
                    <Col md="3">
                    <this.FarnchiseSelect />
                    </Col>
                    <Col md="3">
                      <FormGroup>
                        <Input
                          placeholder="Regular"
                          type="date"
                          name="dailyDealDate"
                          onChange={this.handleChangeDate}
                        />
                      </FormGroup>
                      
                    </Col>
                    <Col md="4">
                      <FormGroup>
                        <InputGroup className="mb-2">
                          <InputGroupAddon addonType="prepend">
                            <InputGroupText>
                              <i className="ni ni-zoom-split-in" />
                            </InputGroupText>
                          </InputGroupAddon>
                          <Input
                            placeholder="Search"
                            type="text"
                            onChange={this.GetSearchProducts}
                          />
                        </InputGroup>
                      </FormGroup>
                    </Col>
                  </Row>
                </CardHeader>

                <Table className="align-items-center table-flush" responsive>
                  {" "}
                  <this.ProductDetails />
                </Table>

                <CardFooter className="py-4">
                  <this.Pagination />
                </CardFooter>
              </Card>
            </div>
          </Row>
        </Container>
      </>
    );
  }
}
