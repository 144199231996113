import React, { Component } from "react";
import { Link } from "react-router-dom";
import { Redirect } from "react-router-dom";

import Header2 from "../../../components/Headers/Header2";
import {
  Card,
  CardHeader,
  Container,
  Row,
  Button,
  Col,
  CardBody,
  FormGroup,
  Input,
  UncontrolledAlert,
  ListGroup,
  ListGroupItem,
  Modal,
  InputGroup,
  InputGroupAddon,
  InputGroupText,
  Table,
  Spinner,
  ModalBody,
  CardFooter,
} from "reactstrap";
import Cookie from "js-cookie";
const axios = require("axios").default;
const URL = require("../../ApiUrl");
const style = {
  color: "red",
};
export default class Offers extends Component {
  state = {
    name: "",
    code: "",
    type: "",
    franchiseId: "",
    percentage: "",
    description: "",
    shoppingAmount: "",
    maximumOfferAmount: "",
    expiryDate: "",
    isActive: null,
    franchisedata: [],
    category: [],
    page: 1,
    limit: 20,
    deleteId: "",
    isLoad: false,
    offerdetails: {},
    today: "",
  };
  resetErrorMsg = () => {
    this.setState({
      nameError: "",
      codeError: "",
      typeError: "",
      percentageError: "",
      franchiseIdError: "",
      descriptionError: "",
      shoppingAmountError: "",
      maximumOfferAmountError: "",
      expiryDateError: "",
      isActiveError: "",
    });
  };
  AddToItems = (e) => {
    this.resetErrorMsg();
    let allitems = this.state.allitems;
    let isValid = true;
    // console.log(this.state.franchiseName,'🎈🎈🎈🎈🎈')
    if (this.state.name === "") {
      isValid = false;
      this.setState({
        nameError: "*Offer Name is required",
      });

      console.log(this.state.name, "Name error");
    }
    if (this.state.code === "") {
      isValid = false;
      this.setState({
        codeError: "*Offer Code is required",
      });

      console.log(this.state.code, "code error");
    }
    if (this.state.type === "") {
      isValid = false;
      this.setState({
        typeError: "*Offer Type is required",
      });

      console.log(this.state.type, "code error");
    }
    if (this.state.percentage === "") {
      isValid = false;
      this.setState({
        percentageError: "*Offer Percentage is required",
      });

      console.log(this.state.percentage, "%error");
    }
    if (this.state.franchiseId === "") {
      isValid = false;
      this.setState({
        franchiseIdError: "*Franchise is required",
      });

      console.log(this.state.franchiseId, "franchiseId error");
    }
    if (this.state.description === "") {
      isValid = false;
      this.setState({
        descriptionError: "*Description is required",
      });

      console.log(this.state.description, "description error");
    }
    if (this.state.shoppingAmount === "") {
      isValid = false;
      this.setState({
        shoppingAmountError: "*Shopping Amount is required",
      });

      console.log(this.state.shoppingAmount, "shoppingAmount error");
    }
    if (this.state.maximumOfferAmount === "") {
      isValid = false;
      this.setState({
        maximumOfferAmountError: "*Maximum Offer Amount is required",
      });

      console.log(this.state.maximumOfferAmount, "maximumOfferAmount error");
    }
    if (this.state.expiryDate === "") {
      isValid = false;
      this.setState({
        expiryDateError: "*Expiry Date  is required",
      });

      console.log(this.state.expiryDate, "expiryDate error");
    }
    if (this.state.isActive === null) {
      isValid = false;
      this.setState({
        isActiveError: "*Offer Active is required",
      });

      console.log(this.state.isActive, "isActive error");
    }

    if (!isValid) {
      return;
    } else {
      var obj = {
        name: this.state.name,
        code: this.state.code.toUpperCase(),
        type: this.state.type,
        franchiseId: this.state.franchiseId,
        percentage: this.state.percentage,
        description: this.state.description,
        shoppingAmount: this.state.shoppingAmount,
        maximumOfferAmount: this.state.maximumOfferAmount,
        expiryDate: Date.parse(new Date(this.state.expiryDate).toString()),
        isActive: this.state.isActive,
      };
      console.log(obj, "🎁🎁🎁🎁🎁🎁");
      this.PostOffer(obj);
    }
  };

  PostOffer = async (data) => {
    // let timestamp = Date.parse(new Date(this.state.expiryDate).toString());
    // console.log(timestamp, "🎈🎈🎈🎈🎈🎈");
    const token = Cookie.get("patrickToken")
      ? Cookie.get("patrickToken")
      : null;
    const config = {
      headers: { Authorization: `Bearer ${token}` },
    };
    console.log(data, "🎈🎈🎈🎈🎈🎈🎈");
    // return;
    let response = await axios
      .post(`${URL.BASE_URL}/admin-offers/add`, data, config)
      .catch(function (error) {
        console.log(error);
      });
    // console.log(data);
    if (response && response.data.statusCode === 200) {
      this.setState({
        succsess: true,
        isRedirect: true,
      });
    } else if (response && response.data.statusCode !== 200) {
      this.setState({
        error: true,
        errorCode: response.data.msg,
      });
    } else {
      this.setState({
        error: true,
      });
    }
    console.log(response);
  };

  GetFranchiseList = async () => {
    const token = Cookie.get("patrickToken")
      ? Cookie.get("patrickToken")
      : null;
    const config = {
      headers: { Authorization: `Bearer ${token}` },
    };

    let response = await axios
      .get(
        `${URL.BASE_URL}/admin-franchises/list?page=${1}&limit=${100}`,
        config
      )
      .catch(function (error) {
        console.log(error);
      });
    console.log("👇👇👇👇👇👇👇👇👇👇");
    console.log(response);
    if (response && response.data !== null) {
      this.setState({
        category: response.data.data.items,
      });
    } else if (response && response.data.statusCode !== 200) {
      this.setState({
        error: true,
        errorCode: response.data.msg,
      });
    } else {
      this.setState({
        status: false,
      });
    }
  };

  GetOffersList = async () => {
    if (this.props.match.params.id) {
      const token = Cookie.get("patrickToken")
        ? Cookie.get("patrickToken")
        : null;
      const config = {
        headers: { Authorization: `Bearer ${token}` },
      };
      let id = this.props.match.params.id;
      let response = await axios
        .get(
          `${URL.BASE_URL}/admin-offers/${id}/details?page=${1}&limit=${100}`,
          config
        )
        .catch(function (error) {
          console.log(error);
        });
      console.log("👇👇👇👇👇👇👇👇👇👇");
      console.log(response);
      if (response && response.data !== null) {
        console.log(response.data.data.franchiseId.franchiseName, "🎈🎈🎈🎈");
        this.setState({
          offerdetails: response.data.data,
          franchise: response.data.data.franchiseId.franchiseName,
          isActive : response.data.data.isActive,
          isLoaded: true,
        });
        console.log(this.state.offerdetails, "🎄🎄🎄🎄🎄🎄🎄🎄🎄");
      } else if (response && response.data.statusCode !== 200) {
        this.setState({
          error: true,
          errorCode: response.data.msg,
        });
      } else {
        this.setState({
          status: false,
        });
      }
    }
  };

  componentDidMount() {
    this.GetFranchiseList();
    this.GetOffersList();
  }

  PatchNote = async (e) => {
    let id = this.props.match.params.id;
    // console.log(id, "________________________________");
    const token = Cookie.get("patrickToken")
      ? Cookie.get("patrickToken")
      : null;
    const config = {
      headers: { Authorization: `Bearer ${token}` },
    };
    let Data = {
      name: this.state.name,
      code: this.state.code.toUpperCase(),
      type: this.state.type,
      franchiseId: this.state.franchiseId,
      percentage: this.state.percentage,
      description: this.state.description,
      shoppingAmount: this.state.shoppingAmount,
      maximumOfferAmount: this.state.maximumOfferAmount,
      expiryDate: Date.parse(new Date(this.state.expiryDate).toString()),
      isActive: this.state.isActive,
    };
    console.log(Data);

    let response = await axios
      .patch(`${URL.BASE_URL}/admin-offers/${id}/update`, Data, config)
      .catch(function (error) {
        console.log(error);
      });
    if (response && response.data.statusCode === 200) {
      this.setState({
        status: true,
        updated: true,
        isRedirect: true,
        errorCode: response.data.msg,
      });
    } else if (response && response.data.statusCode !== 200) {
      this.setState({
        error: true,
        errorCode: response.data.msg,
      });
    } else {
      this.setState({
        status: false,
      });
    }
    setTimeout(() => {
      this.setState({
        status: false,
        error: false,
        updated: false,
      });
    }, 2000);
    console.log(response);
  };

  handleChange = (e) => {
    this.setState({
      [e.target.name]: e.target.value,
    });
  };

  handleRadio = async (event) => {
    const isActive = event.currentTarget.value === "true" ? true : false;
    console.log("handle", isActive);
    await this.setState({ isActive });
  };

  Alert = () => {
    if (this.state.succsess) {
      return (
        <UncontrolledAlert color="success" fade={false}>
          <span className="alert-inner--icon">
            <i className="ni ni-like-2" />
          </span>{" "}
          <span className="alert-inner--text">
            <strong>Success!</strong> Category Added Successfully
          </span>
        </UncontrolledAlert>
      );
    } else if (this.state.error) {
      return (
        <UncontrolledAlert color="danger" fade={false}>
          <span className="alert-inner--icon"></span>{" "}
          <span className="alert-inner--text">
            <strong>Opps!</strong> <strong>{this.state.errorCode}</strong>
          </span>
        </UncontrolledAlert>
      );
    } else {
      return <></>;
    }
  };
  DeleteOffer = async () => {
    let id = this.props.match.params.id;
    const token = Cookie.get("patrickToken")
      ? Cookie.get("patrickToken")
      : null;
    const config = {
      headers: { Authorization: `Bearer ${token}` },
    };
    let response = await axios
      .delete(`${URL.BASE_URL}/admin-offers/${id}/delete`, config)
      .catch(function (error) {
        console.log(error);
      });
    if (response.data.statusCode === 200) {
      this.setState({
        isRedirect: true,
      });
    }
  };
  toggleModal = (state) => {
    this.setState({
      [state]: !this.state[state],
    });
  };

  AddFields = () => {
    const { isActive } = this.state;
    
    let date = this.state.offerdetails.expiryDate;
    console.log(date, "💕💕💕💕💕💕");
    if (date) {
      const formatYmd = (date) => date.toISOString().slice(0, 10);
      this.state.today = formatYmd(new Date(date));
      console.log(this.state.today, "🎎🎎🎎🎎🎎");
    }

    return (
      <>
        <Row className=" icon-examples">
          <Col lg="6">
            <FormGroup>
              <label htmlFor="#username">Offer Name</label>
              <Input
                type="text"
                name="name"
                onChange={this.handleChange}
                defaultValue={this.state.offerdetails.name}
              />
              <h4 style={style}>{this.state.nameError}</h4>
            </FormGroup>
          </Col>
          <Col lg="6">
            <FormGroup>
              <label htmlFor="#username">Offer Code</label>
              <Input
                type="text"
                style={{ textTransform: "uppercase" }}
                name="code"
                onChange={this.handleChange}
                defaultValue={this.state.offerdetails.code}
              />
              <h4 style={style}>{this.state.codeError}</h4>
            </FormGroup>
          </Col>
        </Row>
        <Row>
          <Col lg="4">
            <FormGroup>
              <label htmlFor="#username">Offer Type</label>
              <Input
                type="select"
                name="type"
                onChange={this.handleChange}
                defaultValue={this.state.offerdetails.type}
              >
                <option value="">
                  {this.state.offerdetails.type === "coupon"
                    ? "Coupon Offers"
                    : this.state.offerdetails.type === "referral"
                    ? "Referal Offers"
                    : null}
                </option>
                ;<option value={null}>Select</option>
                <option value="coupon">Coupon Offers</option>
                <option value="referral">Referal Offers</option>
              </Input>
              <h4 style={style}>{this.state.typeError}</h4>
            </FormGroup>
          </Col>
          <Col lg="4">
            <FormGroup>
              <label htmlFor="#username">Offer Percentage (%)</label>
              <Input
                type="number"
                name="percentage"
                onChange={this.handleChange}
                defaultValue={this.state.offerdetails.percentage}
              />
              <h4 style={style}>{this.state.percentageError}</h4>
            </FormGroup>
          </Col>
          <Col lg="4">
            <FormGroup>
              <label htmlFor="#username">Select Franchise</label>
              <Input
                type="select"
                name="franchiseId"
                onChange={this.handleChange}
              >
                <option value="">{this.state.franchise}</option>;
                <option value="">Select</option>
                {this.state.category.map((data, key) => {
                  return <option value={data.id}>{data.franchiseName}</option>;
                })}
              </Input>
              <h4 style={style}>{this.state.franchiseIdError}</h4>
            </FormGroup>
          </Col>
        </Row>
        <Row>
          <Col lg="12">
            <FormGroup>
              <label htmlFor="#username">Description</label>
              <Input
                type="textarea"
                name="description"
                onChange={this.handleChange}
                defaultValue={this.state.offerdetails.description}
              />
              <h4 style={style}>{this.state.descriptionError}</h4>
            </FormGroup>
          </Col>
        </Row>
        <Row className=" icon-examples">
          <Col lg="6">
            <FormGroup>
              <label htmlFor="#username">Shopping Amount</label>
              <Input
                type="number"
                name="shoppingAmount"
                onChange={this.handleChange}
                defaultValue={this.state.offerdetails.shoppingAmount}
              />
              <h4 style={style}>{this.state.shoppingAmountError}</h4>
            </FormGroup>
          </Col>
          <Col lg="6">
            <FormGroup>
              <label htmlFor="#username">Maximum Offer Amount</label>
              <Input
                type="number"
                name="maximumOfferAmount"
                onChange={this.handleChange}
                defaultValue={this.state.offerdetails.maximumOfferAmount}
              />
              <h4 style={style}>{this.state.maximumOfferAmountError}</h4>
            </FormGroup>
          </Col>
        </Row>
        <Row className=" icon-examples">
          <Col lg="6">
            <FormGroup>
              <label htmlFor="#username">Expiry Date</label>
              {date ? (
                <Input
                  type="date"
                  name="expiryDate"
                  onChange={this.handleChange}
                  defaultValue={this.state.today}
                />
              ) : (
                <Input
                  type="date"
                  name="expiryDate"
                  onChange={this.handleChange}
                />
              )}

              <h4 style={style}>{this.state.expiryDateError}</h4>
            </FormGroup>
          </Col>

          <Col md="3">
            <FormGroup>
              <label htmlFor="input-username">Offer Active</label>
              <Row>
                <Col>
                  <div>
                    <input
                      type="radio"
                      name="isActive"
                      value="true"
                      checked={isActive === true}
                      onChange={this.handleRadio}
                      style={{
                        marginTop: "2px",
                        marginRight: "10px",
                        fontSize: "16px",
                        border: "0px",
                        width: "20px",
                        height: "20px",
                      }}
                    />

                    <label> Yes</label>
                  </div>
                </Col>
                <Col>
                  {" "}
                  <div>
                    <input
                      type="radio"
                      name="isActive"
                      value="false"
                      checked={isActive === false}
                      onChange={this.handleRadio}
                      style={{
                        marginTop: "2px",
                        marginRight: "10px",
                        fontSize: "16px",
                        border: "0px",
                        width: "20px",
                        height: "20px",
                      }}
                    />
                    <label> No</label>
                  </div>
                </Col>
              </Row>
              <h4 style={style}>{this.state.isActiveError}</h4>
            </FormGroup>
          </Col>
        </Row>
        <Row className=" d-flex justify-content-center mr-mt-md-4">
          {this.props.match.params.id ? (
            <>
              <Col lg="3" gap="2">
                <Button
                  className="btn-icon btn-2"
                  color="success"
                  type="button"
                  block
                  onClick={this.PatchNote}
                >
                  <span className="btn-inner--text">Update</span>
                </Button>
              </Col>
              <Col lg="3">
                <Button
                  block
                  className="mb-3"
                  color="danger"
                  type="button"
                  onClick={() => this.toggleModal("notificationModal")}
                >
                  Delete
                </Button>
                <Modal
                  className="modal-dialog-centered modal-danger"
                  contentClassName="bg-gradient-danger"
                  isOpen={this.state.notificationModal}
                  toggle={() => this.toggleModal("notificationModal")}
                >
                  <div className="modal-header">
                    <h6 className="modal-title" id="modal-title-notification">
                      Your confirmation is required
                    </h6>
                    <button
                      aria-label="Close"
                      className="close"
                      data-dismiss="modal"
                      type="button"
                      onClick={() => this.toggleModal("notificationModal")}
                    >
                      <span aria-hidden={true}>×</span>
                    </button>
                  </div>
                  <div className="modal-body">
                    <div className="py-3 text-center">
                      <i class="fas fa-trash-alt fa-3x"></i>
                      <h4 className="heading mt-4">ATTENTION !!</h4>
                      <p>Are you sure want to delete this Offer ?</p>
                      <p>You can't undo this operation!</p>
                    </div>
                  </div>
                  <div className="modal-footer">
                    <Button
                      className="btn-white"
                      color="default"
                      type="button"
                      onClick={() => this.DeleteOffer()}
                    >
                      Ok, Delete
                    </Button>
                    <Button
                      className="text-white ml-auto"
                      color="link"
                      data-dismiss="modal"
                      type="button"
                      onClick={() => this.toggleModal("notificationModal")}
                    >
                      Close
                    </Button>
                  </div>
                </Modal>
              </Col>
            </>
          ) : (
            <>
              <Col lg="3" className="mt-2">
                <Button
                  className="btn-icon btn-2"
                  color="info"
                  type="button"
                  block
                  onClick={this.AddToItems}
                >
                  <span className="btn-inner--text">Add</span>
                </Button>
              </Col>
            </>
          )}
        </Row>
      </>
    );
  };

  render() {
    if (this.state.isRedirect) {
      return <Redirect to="/admin/offers" />;
    }
    return (
      <>
        <Header2 />
        <Container className=" mt--7" fluid>
          {/* Table */}
          <Row>
            <div className=" col">
              <Card className=" shadow">
                <CardHeader className=" bg-transparent">
                  <h3 className=" mb-0">Offers</h3>
                </CardHeader>
                <CardBody>
                  <this.AddFields />
                </CardBody>
              </Card>
              <br />
              <this.Alert />
            </div>
          </Row>
        </Container>
      </>
    );
  }
}
