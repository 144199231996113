import React from "react";
import { Link } from "react-router-dom";

import {
  Card,
  CardHeader,
  CardFooter,
  Pagination,
  PaginationItem,
  PaginationLink,
  Table,
  Container,
  Row,
  FormGroup,
  Input,
  InputGroupAddon,
  InputGroupText,
  InputGroup,
  Col,
  Button,
  Spinner,
} from "reactstrap";

import Cookie from "js-cookie";
import Header2 from "components/Headers/Header2";
const axios = require("axios").default;
const URL = require("../../ApiUrl");

class Customer extends React.Component {
  constructor(props) {
    super(props);

    this.GetCustomer = this.GetCustomer.bind(this);
    this.GetSearchCustomer = this.GetSearchCustomer.bind(this);
    this.CustomerDetails = this.CustomerDetails.bind(this);

    this.state = {
      succsess: false,
      error: false,
      isLoaded: false,
      loading: false,
      limit: 20,
      page: 1,
      customer:{},
      hasNextPage: false,
      originalData: [],
    };
  }

  async GetCustomer(p) {
    this.setState({ isLoaded: false})
    const token = Cookie.get("patrickToken")
      ? Cookie.get("patrickToken")
      : null;
    const config = {
      headers: { Authorization: `Bearer ${token}` },
    };
    let page = p ? p : this.state.page;
    let limit = this.state.limit;
    let response = await axios
      .get(
        `${URL.BASE_URL}/admin-accounts/all-customers?page=${page}&limit=${limit}`,
        config
      )
      .catch(function (error) {
        console.log(error);
      });

    console.log("👇👇👇👇👇👇👇👇👇👇");
    console.log(response);

    if (response && response.data !== null) {
      this.setState({
        customer: response.data.data,
        orginalData: response.data.data,
        isLoaded: true,
      });
    } else if (response && response.data.statusCode !== 200) {
      this.setState({
        error: true,
        errorCode: response.data.msg,
      });
    } else {
      this.setState({
        status: false,
      });
    }
  }
  async GetSearchCustomer(e) {
    this.setState({ isLoaded: false });
    let searchText = e.target.value;


    if (searchText === "") {
      this.GetCustomer();
    } else {
    const token = Cookie.get("patrickToken")
      ? Cookie.get("patrickToken")
      : null;
    const config = {
      headers: { Authorization: `Bearer ${token}` },
    };
    let page = this.state.page;
    let limit = this.state.limit;
    let response = await axios
      .get(
        `${URL.BASE_URL}/admin-accounts/search-customers?limit=${limit}&page=${page}&searchText=${searchText}`,
        config
      )
      .catch(function (error) {
        console.log(error);
      });

    console.log("🎉🎉🎉🎉🎉🎉SEARCHING................❤❤");
    console.log(response);

    if (response && response.data !== null) {
      this.setState({
        customer: response.data.data,
        page: 1,
        isLoaded: true,
      });
    } else if (response && response.data.statusCode !== 200) {
      this.setState({
        error: true,
        errorCode: response.data.msg,
      });
    } else {
      this.setState({
        status: false,
      });
    }
  }
}  

  

  componentDidMount() {
    this.GetCustomer();
  }
  CustomerDetails() {
    if (this.state.isLoaded) {
      return (
        <>
          <thead className="thead-light">
            <tr>
              <th scope="col">#</th>
              <th scope="col">First Name</th>
              <th scope="col">Last Name</th>
              <th scope="col">Email</th>
              <th scope="col">Phone Number</th>
              <th scope="col">Action</th>
              
            </tr>
          </thead>
          <tbody>
            {this.state.customer &&
              this.state.customer.items.map((data, key) => {
                return (
                  <tr>
                    <td>
                    {(this.state.page - 1) * this.state.limit + key + 1}
                    </td>
                    <td>{data.firstName}</td>
                    <td>{data.lastName}</td>
                    <td>{data.email}</td>
                    <td>{data.mobile}</td>
                    <td>
                      <Link to={`/admin/customerdetails/${data.id}`}>
                        <Button
                          className=" btn-icon"
                          color="info"
                          size="sm"
                          type="button"
                        >
                          <i className=" ni ni-circle-08 pt-1"></i>
                          <span className="btn-inner--text">View</span>
                        </Button>
                      </Link>
                    </td>
                  </tr>
                );
              })}
          </tbody>
        </>
      );
    } else {
      return (
        <div className=" d-flex justify-content-center mr-mt-md-4">
          <Spinner
            style={{ width: "3rem", height: "3rem" }}
            type="grow"
            color="warning"
          />
        </div>
      );
    }
  }
  Pagination = () => {
    let totalPages = this.state.customer ? this.state.customer.totalPages : 0;
    let currentPage = this.state.page;
    let pageItems = Array.apply(null, Array(totalPages)).map(function (x, i) {
      return i;
    });
    return (
      <>
        <nav aria-label="Page navigation example">
          <Pagination
            className="pagination justify-content-end"
            listClassName="justify-content-end"
          >
            {pageItems.map((item, key) => {
              if (currentPage === key + 1) {
                return (
                  <PaginationItem className="active">
                    <PaginationLink
                      href="#pablo"
                      onClick={(e) => e.preventDefault()}
                    >
                      {key + 1}
                    </PaginationLink>
                  </PaginationItem>
                );
              } else {
                return (
                  <PaginationItem>
                    <PaginationLink
                      href="#pablo"
                      onClick={(e) => {
                        e.preventDefault();
                        this.setState({ page: key + 1 });
                        this.GetCustomer(key + 1);
                      }}
                    >
                      {key + 1}
                    </PaginationLink>
                  </PaginationItem>
                );
              }
            })}
          </Pagination>
        </nav>
      </>
    );
  };
  render() {
    return (
      <>
        <Header2 />
        {/* Page content */}
        <Container className="mt--7" fluid>
          {/* Table */}
          <Row>
            <div className="col">
              <Card className="shadow">
                <CardHeader className="border-0">
                  <Row>
                    <Col>
                      <h2 className="mb-0">Customer</h2>
                    </Col>
                    <Col md="4">
                      <FormGroup>
                        <InputGroup className="mb-2">
                          <InputGroupAddon addonType="prepend">
                            <InputGroupText>
                              <i className="ni ni-zoom-split-in" />
                            </InputGroupText>
                          </InputGroupAddon>
                          <Input
                            type="text"
                            id="search-input"
                            placeholder="Search for..."
                            onChange={this.GetSearchCustomer}
                          />
                        </InputGroup>
                      </FormGroup>
                    </Col>
                  </Row>
                </CardHeader>
                <Table className="align-items-center table-flush" responsive>
                  <this.CustomerDetails />{" "}
                </Table>
                <CardFooter className="py-4">
                <this.Pagination/>
                </CardFooter>
              </Card>
            </div>
          </Row>
        </Container>
      </>
    );
  }
}

export default Customer;
