import React, { Component } from "react";
import Header2 from "../../components/Headers/Header2";
import {
  Card,
  CardHeader,
  Container,
  Row,
  Button,
  Col,
  CardBody,
  FormGroup,
  Input,
  UncontrolledAlert,
  ListGroup,
  ListGroupItem,
  Modal,
  Table,
  Spinner,
  ModalBody,
  CardFooter,
} from "reactstrap";
import Cookie from "js-cookie";
const axios = require("axios").default;
const URL = require("../ApiUrl");
const style = {
  color: "red",
};
export default class Attributes extends Component {
  state = {
    partentName: [],
    childList: [],
    isLoaded: null,
    name: "",
    parentId: "",
    deleteId: "",
    update: "",
    isEdit: false,
    selectedParent: {},
    categorySubName: "",
    isChild: null,
    id: [],
  };

  resetErrorMsg = () => {
    this.setState({
      nameError: "",
      isChildError: "",
      parentIdError: "",
    });
  };
  AddToItems = (e) => {
    this.resetErrorMsg();

    let isValid = true;

    if (this.state.name === "") {
      isValid = false;
      this.setState({
        nameError: "*Name is required",
      });

      console.log(this.state.name, "Name error");
    }
    if (this.state.isChild === null) {
      isValid = false;
      this.setState({
        isChildError: "*Parent Attribute is required",
      });

      console.log(this.state.isChild, "isChild error");
    }
    if (this.state.parentId === "" && this.state.isChild === false) {
      isValid = false;
      this.setState({
        parentIdError: "*Parent Attribute is required",
      });

      console.log(this.state.parentId, "parentId error");
    }

    if (!isValid) {
      return;
    } else {
      var obj = {
        name: this.state.name,
      };
      if (this.state.parentId && this.state.isChild === false) {
        obj.parentId = this.state.parentId;
      }
      console.log(obj, "🎁🎁🎁🎁🎁🎁");
      this.PostAttributes(obj);
    }
  };

  PostAttributes = async (data) => {
    
    const token = Cookie.get("patrickToken")
      ? Cookie.get("patrickToken")
      : null;
    const config = {
      headers: { Authorization: `Bearer ${token}` },
    };
    console.log(data, "🎈🎈🎈🎈🎈🎈🎈");
    // return;
    let response = await axios
      .post(`${URL.BASE_URL}/masters/product-attribute/add`, data, config)
      .catch(function (error) {
        console.log(error);
      });
    // console.log(data);
    if (response && response.data.statusCode === 200) {
      this.GetAttributeParent();
      this.setState({
        succsess: true,
        isRedirect: true,
      });
    } else if (response && response.data.statusCode !== 200) {
      this.setState({
        error: true,
        errorCode: response.data.msg,
      });
    } else {
      this.setState({
        error: true,
      });
    }
    console.log(response);
  };
  GetAttributeParent = async () => {
    this.setState({ isLoaded: false });
    const token = Cookie.get("patrickToken")
      ? Cookie.get("patrickToken")
      : null;
    const config = {
      headers: { Authorization: `Bearer ${token}` },
    };

    let response = await axios
      .get(`${URL.BASE_URL}/masters/product-attribute/list`, config)
      .catch(function (error) {
        console.log(error);
      });
    console.log("👇👇👇👇👇👇👇👇👇👇");
    console.log(response);
    if (response && response.data !== null) {
      this.setState({
        partentName: response.data,
        isLoaded: true,
      });

      console.log(this.state.partentName, "🎈🎈🎈🎈🎈🎈🎈");
    } else if (response && response.data.statusCode !== 200) {
      this.setState({
        error: true,
        errorCode: response.data.msg,
      });
    } else {
      this.setState({
        status: false,
      });
    }
  };
  componentDidMount() {
    this.GetAttributeParent();
  }

  handleChange = (e) => {
    this.setState({
      [e.target.name]: e.target.value,
    });
  };

  handleSelectedParent = (e) => {
    console.log(e.target.value);
    // console.log(this.state.category[e.target.value]._id,"🎁🎁🎁🎁")
    // console.log(this.state.category, "🎈🎈🎈🎈🎈🎈🎈");
    if (e.target.value === "") {
      console.log("not selected");
      this.setState({
        deleteId: null,
        selectedParent: "",
      });
    } else {
      this.setState({
        selectedParent: this.state.partentName[e.target.value],
        deleteId: this.state.partentName[e.target.value]._id,
      });
      console.log(this.state.partentName[e.target.value]._id, "/////");
    }
  };
  handleRadio2 = async (event) => {
    const isChild = event.currentTarget.value === "true" ? true : false;
    console.log("handle", isChild);
    await this.setState({ isChild });
  };
  Alert = () => {
    // console.log(this.state.error, "🎆🎆🎆🎆🎆");
    if (this.state.succsess) {
      return (
        <UncontrolledAlert color="success" fade={false}>
          <span className="alert-inner--icon">
            <i className="ni ni-like-2" />
          </span>{" "}
          <span className="alert-inner--text">
            <strong>Success!</strong> Category Added Successfully
          </span>
        </UncontrolledAlert>
      );
    } else if (this.state.error) {
      return (
        <UncontrolledAlert color="danger" fade={false}>
          <span className="alert-inner--icon"></span>{" "}
          <span className="alert-inner--text">
            <strong>Opps!</strong> <strong>{this.state.errorCode}</strong>
          </span>
        </UncontrolledAlert>
      );
    } else {
      return <></>;
    }
  };
  addAttributes = () => {
    const { isChild } = this.state;
    return (
      <>
        <Row className=" icon-examples">
          <Col lg="5" md="4">
            <FormGroup>
              <label className="form-control-label" htmlFor="input-username">
                Attribute Name
              </label>
              <Input
                placeholder="Name"
                type="text"
                name="name"
                onChange={this.handleChange}
              />
              <h4 style={style}>{this.state.nameError}</h4>
            </FormGroup>
          </Col>

          <Col lg="2" md="4">
            <FormGroup>
              <label className="form-control-label" htmlFor="input-username">
                Parent Attribute
              </label>
              <Row>
                <Col>
                  <div>
                    <input
                      type="radio"
                      name="isChild"
                      value="true"
                      checked={isChild === true}
                      onChange={this.handleRadio2}
                      style={{
                        marginRight: "10px",
                        fontSize: "16px",
                        border: "0px",
                        width: "20px",
                        height: "20px",
                      }}
                    />

                    <label> Yes</label>
                  </div>
                </Col>
                <Col>
                  {" "}
                  <div>
                    <input
                      type="radio"
                      name="isChild"
                      value="false"
                      checked={isChild === false}
                      onChange={this.handleRadio2}
                      style={{
                        marginRight: "10px",
                        fontSize: "16px",
                        border: "0px",
                        width: "20px",
                        height: "20px",
                      }}
                    />
                    <label> No</label>
                  </div>
                </Col>
              </Row>
              <h4 style={style}>{this.state.isChildError}</h4>
            </FormGroup>
          </Col>
          {this.state.isChild === false ? (
            <>
              <Col lg="5" md="4">
                <label className="form-control-label" htmlFor="input-username">
                  Select Parent Attribute
                </label>
                <Input
                  type="select"
                  name="parentId"
                  onChange={this.handleChange}
                >
                  <option value={null}>Select Parent</option>
                  {this.state.partentName.map((data, key) => {
                    return <option value={data._id}>{data.name}</option>;
                  })}
                </Input>
              <h4 style={style}>{this.state.parentIdError}</h4>
              </Col>
            </>
          ) : null}
        </Row>
        <Row className=" d-flex justify-content-center mr-mt-md-4">
          <Col lg="3">
            <Button
              className="btn-icon btn-2"
              color="info"
              type="button"
              block
              onClick={this.AddToItems}
            >
              <span className="btn-inner--icon">
                <i class="fas fa-plus"></i>
              </span>
              <span className="btn-inner--text">Add</span>
            </Button>
          </Col>
        </Row>
      </>
    );
  };
  EditOption = (id) => {
    // console.log(id, "🎎🎎🎎🎎🎎");
    if (id === "") {
      console.log("not selected");
      this.setState({
        error: true,
        errorCode: "Please Select Category",
      });
      return;
    }
    const [modalOpen, setModalOpen] = React.useState(false);
    return (
      <>
        <Button
          className="btn-icon btn-3"
          color="success"
          type="button"
          onClick={() => setModalOpen(!modalOpen)}
        >
          <span className="btn-inner--icon"></span>
          <span className="btn-inner--text">Edit</span>
        </Button>
        <Modal toggle={() => setModalOpen(!modalOpen)} isOpen={modalOpen}>
          <div className=" modal-header">
            <h4 className=" modal-title">Edit</h4>
            <button
              aria-label="Close"
              className=" close"
              type="button"
              onClick={() => setModalOpen(!modalOpen)}
            >
              <span aria-hidden={true}>×</span>
            </button>
          </div>
          <ModalBody>
            <div>
              <Row className="justify-content-lg-center">
                <Col lg="12">
                  <FormGroup>
                    <Input
                      placeholder="Name"
                      defaultValue={this.state.selectedParent.name}
                      type="text"
                      name="name"
                      onChange={this.handleChange}
                    />
                  </FormGroup>
                </Col>
              </Row>

              <Row className="justify-content-lg-center">
                <Col lg="0">
                  <Button
                    className="btn-tooltip mr-3"
                    color="info"
                    type="button"
                    size="lg"
                    onClick={() => this.PatchCategoryEdit()}
                  >
                    <span className="btn-inner--text">Save Changes</span>
                  </Button>
                </Col>
              </Row>
            </div>
          </ModalBody>
        </Modal>
      </>
    );
  };
  toggleModal = (state, id) => {
    this.setState({
      [state]: !this.state[state],
      deleteId: id,
    });
  };
  toggleModal2 = (state, id) => {
    if (this.state.deleteId === null) {
      this.setState({
        error: true,
        errorCode: "Please Select Category",
      });
    } else {
      this.setState({
        [state]: !this.state[state],
        deleteId: this.state.deleteId,
      });
    }
  };
  DeleteShift = async (key) => {
    let id = this.state.deleteId;
    const token = Cookie.get("patrickToken")
      ? Cookie.get("patrickToken")
      : null;
    const config = {
      headers: { Authorization: `Bearer ${token}` },
    };
    console.log(id, "❤️❤️❤️❤️❤️");
    let response = await axios
      .delete(`${URL.BASE_URL}/masters/product-attribute/${id}/delete`, config)
      .catch(function (error) {
        console.log(error);
      });
    if (response.data.statusCode === 200) {
      this.setState({
        isRedirect: true,
      });
    }
    this.toggleModal("notificationModal");
  };
  Delete = () => {
    return (
      <>
        <Col md="12">
          <Modal
            className="modal-dialog-centered modal-danger"
            contentClassName="bg-gradient-danger"
            isOpen={this.state.notificationModal}
            toggle={() => this.toggleModal("notificationModal")}
          >
            <div className="modal-header">
              <h6 className="modal-title" id="modal-title-notification">
                Your confirmation is required
              </h6>
              <button
                aria-label="Close"
                className="close"
                data-dismiss="modal"
                type="button"
                onClick={() => this.toggleModal("notificationModal")}
              >
                <span aria-hidden={true}>×</span>
              </button>
            </div>
            <div className="modal-body">
              <div className="py-3 text-center">
                <i class="fas fa-trash-alt fa-3x"></i>
                <h4 className="heading mt-4">ATTENTION !!</h4>
                <p>Are you sure want to delete this Category ?</p>
                <p>You can't undo this operation!</p>
              </div>
            </div>
            <div className="modal-footer">
              <Button
                className="btn-white"
                color="default"
                type="button"
                onClick={() => this.DeleteShift("id")}
              >
                Ok, Delete
              </Button>
              <Button
                className="text-white ml-auto"
                color="link"
                data-dismiss="modal"
                type="button"
                onClick={() => this.toggleModal("notificationModal")}
              >
                Close
              </Button>
            </div>
          </Modal>
        </Col>
      </>
    );
  };
  PatchSubCategoryEdit = async () => {
    let id = this.state.update;
    console.log(id, "🎄🎄🎄🎄🎄🎄");
    const token = Cookie.get("patrickToken")
      ? Cookie.get("patrickToken")
      : null;
    const config = {
      headers: { Authorization: `Bearer ${token}` },
    };
    let data = {
      name: this.state.categorySubName,
      id: this.state.update,
    };
    console.log(data);
    let response = await axios
      .patch(
        `${URL.BASE_URL}/masters/product-attribute/update?${id}`,
        data,
        config
      )

      .catch(function (error) {
        console.log(error);
      });
    if (response && response.data.statusCode === 200) {
      this.setState({
        isRedirect: true,
      });
    } else if (response && response.data.statusCode !== 200) {
      this.setState({
        error: true,
        errorCode: response.data.msg,
      });
    } else {
      this.setState({
        error: true,
      });
    }
    this.GetAttributeParent();
    this.handleEditClick();
    console.log(response);
  };
  PatchCategoryEdit = async () => {
    let id = this.state.selectedParent._id;
    console.log(id, "🎄🎄🎄🎄🎄🎄");
    const token = Cookie.get("patrickToken")
      ? Cookie.get("patrickToken")
      : null;
    const config = {
      headers: { Authorization: `Bearer ${token}` },
    };
    let data = {
      name: this.state.name,
      id: this.state.selectedParent._id,
    };
    console.log(data);
    // return;
    let response = await axios
      .patch(
        `${URL.BASE_URL}/masters/product-attribute/update?${id}`,
        data,
        config
      )

      .catch(function (error) {
        console.log(error);
      });
    if (response && response.data.statusCode === 200) {
      this.setState({
        isRedirect: true,
      });
    } else if (response && response.data.statusCode !== 200) {
      this.setState({
        error: true,
        errorCode: response.data.msg,
      });
    } else {
      this.setState({
        error: true,
      });
    }
    this.GetAttributeParent();
    this.handleEditClick();

    console.log(response);
  };

  handleEditClick = (id) => {
    this.setState({ isEdit: !this.state.isEdit, update: id });
  };
  CategoryTable = () => {
    let tempkey = this.state.tempkey;
    let isEdit = this.state.isEdit;
    let children;
    if (this.state.selectedParent) {
      children = this.state.selectedParent.children;
      console.log();
      if (children && this.state.isLoaded) {
        return (
          <>
            <thead className="thead-light">
              <tr>
                <th scope="col">#</th>
                <th scope="col">Attributes Name</th>

                <th scope="col">Action</th>
              </tr>
            </thead>
            <tbody>
              {children.map((list, key) => (
                <tr>
                  <td>{key + 1}</td>
                  <td>
                    {isEdit && list._id === this.state.update ? (
                      <Input
                        name="categorySubName"
                        defaultValue={list.name}
                        onChange={this.handleChange}
                      />
                    ) : (
                      list.name
                    )}
                  </td>
                  <td>
                    {isEdit && list._id === this.state.update ? (
                      <Button
                        onClick={this.PatchSubCategoryEdit}
                        color="success"
                        size="sm"
                      >
                        <i class="far fa-save"></i>
                        <span className="btn-inner--text">Save</span>
                      </Button>
                    ) : (
                      <Button
                        onClick={() => this.handleEditClick(list._id, key)}
                        color="success"
                        size="sm"
                      >
                        <span className="btn-inner--text">Edit</span>
                      </Button>
                    )}

                    {isEdit && list._id === this.state.update ? (
                      <Button
                        onClick={this.handleEditClick}
                        className="btn-fat-remove mr-3"
                        color="info"
                        size="sm"
                      >
                        <i class="far fa-times-circle"></i>
                        <span className="btn-inner--text">Cancel</span>
                      </Button>
                    ) : (
                      <Button
                        className="btn-tooltip mr-3"
                        color="danger"
                        size="sm"
                        type="button"
                        onClick={() =>
                          this.toggleModal("notificationModal", list._id)
                        }
                      >
                        <span className="btn-inner--text">Delete</span>
                      </Button>
                    )}
                  </td>
                </tr>
              ))}
            </tbody>
          </>
        );
      } else {
        return <></>;
      }
    } else {
      return <></>;
    }
  };
  render() {
    // if (this.state.isRedirect) {
    //   return window.location.reload(false);
    // }
    return (
      <>
        <Header2 />
        <Container className=" mt--7" fluid>
          <this.Alert />
          {/* Table */}
          <Row>
            <div className=" col">
              <Card className=" shadow">
                <CardHeader className=" bg-transparent">
                  <h3 className=" mb-0">Add Attributes</h3>
                </CardHeader>
                <CardBody>
                  <this.addAttributes />
                </CardBody>
              </Card>
            </div>
          </Row>
        </Container>
        <br />
        <Container className=" mt--10" fluid>
          {/* Table */}
          <Row>
            <div className=" col">
              <Card className=" shadow">
                <CardHeader className=" border-2">
                  <Row>
                    <Col lg="9">
                      <Input type="select" onChange={this.handleSelectedParent}>
                        <option value="">Select Parent</option>
                        {this.state.partentName.map((data, key) => {
                          return <option value={key}>{data.name}</option>;
                        })}
                      </Input>
                    </Col>

                    <Col lg="1">
                      <this.EditOption id={this.state.deleteId} />
                    </Col>
                    <Col lg="1">
                      <Button
                        className=" btn-icon"
                        color="danger"
                        type="button"
                        onClick={() =>
                          this.toggleModal2(
                            "notificationModal",
                            this.state.deleteId
                          )
                        }
                      >
                        <span className="btn-inner--text">Delete</span>
                      </Button>
                    </Col>
                  </Row>
                </CardHeader>

                <Table className="align-items-center table-flush" responsive>
                  {this.state.isRedirect === true ? (
                    window.location.reload(false)
                  ) : (
                    <this.CategoryTable />
                  )}
                  {/* <this.CategoryTable /> */}
                </Table>
                <this.Delete />
                <CardFooter></CardFooter>
              </Card>
            </div>
          </Row>
        </Container>
      </>
    );
  }
}
