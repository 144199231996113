import React, { Component } from "react";
import moment from "moment";
import Header2 from "../../../components/Headers/Header2";
import {
  Card,
  CardHeader,
  Container,
  Row,
  Col,
  CardBody,
  FormGroup,
  Form,
  Input,
  Button,
  InputGroupAddon,
  InputGroupText,
  InputGroup,
  Spinner,
  Table,
  CardFooter,
  UncontrolledAlert,
  Modal,
} from "reactstrap";
import Cookie from "js-cookie";
const axios = require("axios").default;
const URL = require("../../ApiUrl");
const style = {
  color: "red",
};
export default class AddShift extends Component {
  state = {
    name: "",
    startTime: "",
    endTime: "",
    time: 0,
    page: 1,
    limit: 20,
    isPartTime: null,
    deleteId: "",
    isLoad: false,
  };
  resetErrorMsg = () => {
    this.setState({
      nameError: "",
      startTimeError: "",
      endTimeError: "",
      isPartTimeError: "",
    });
  };
  PostShift = async () => {
    this.resetErrorMsg();
    const token = Cookie.get("patrickToken")
      ? Cookie.get("patrickToken")
      : null;
    const config = {
      headers: { Authorization: `Bearer ${token}` },
    };
    if (this.state.name === "") {
      this.setState({
        nameError: "*Name is required",
      });
      console.log(this.state.name, "name error");
    }
    if (this.state.startTime === "") {
      this.setState({
        startTimeError: "*Start Time is required",
      });
      console.log(this.state.startTime, "startTime error");
    }
    if (this.state.endTime === "") {
      this.setState({
        endTimeError: "*End Time is required",
      });
      console.log(this.state.endTime, "endTime error");
    }
    if (this.state.isPartTime === null) {
      this.setState({
        isPartTimeError: "*Part Time is required",
      });
      console.log(this.state.isPartTime, "isPartTime error");
    } else {
      let data = {
        name: this.state.name,
        startTime: this.ConvertDurationtoSeconds(this.state.startTime),
        endTime: this.ConvertDurationtoSeconds(this.state.endTime),
        isPartTime: this.state.isPartTime,
      };
      console.log(data, "🎈🎈🎈🎈🎈🎈🎈");
      // return;
      let response = await axios
        .post(`${URL.BASE_URL}/masters/shifts/add`, data, config)
        .catch(function (error) {
          console.log(error);
        });
      // console.log(data);
      if (response && response.data.statusCode === 200) {
        this.GetShiftList();
        this.setState({
          succsess: true,
          isRedirect: true,
        });
      } else if (response && response.data.statusCode !== 200) {
        this.setState({
          error: true,
          errorCode: response.data.msg,
        });
      } else {
        this.setState({
          error: true,
        });
      }
      console.log(response);
    }
  };

  async GetShiftList(p) {
    this.setState({ isLoaded: false });
    const token = Cookie.get("patrickToken")
      ? Cookie.get("patrickToken")
      : null;
    const config = {
      headers: { Authorization: `Bearer ${token}` },
    };
    let page = p ? p : this.state.page;
    let limit = this.state.limit;
    let response = await axios
      .get(
        `${URL.BASE_URL}/masters/shifts/list?page=${page}&limit=${limit}`,
        config
      )
      .catch(function (error) {
        console.log(error);
      });

    console.log("👇👇👇👇👇👇👇👇👇👇");
    console.log(response);

    if (response && response.data !== null) {
      this.setState({
        shift: response.data.data,
        orginalData: response.data.data,
        isLoaded: true,
      });
    } else if (response && response.data.statusCode !== 200) {
      this.setState({
        error: true,
        errorCode: response.data.msg,
      });
    } else {
      this.setState({
        status: false,
      });
    }
  }

  componentDidMount() {
    this.GetShiftList();
  }
  GetShiftDetails = async (id) => {
    this.setState({
      isLoad: false,
    });
    console.log(id, "/////Api Section/////");
    const token = Cookie.get("patrickToken")
      ? Cookie.get("patrickToken")
      : null;
    const config = {
      headers: { Authorization: `Bearer ${token}` },
    };
    let response = await axios
      .get(`${URL.BASE_URL}/masters/shifts/${id}/details`, config)
      .catch(function (error) {
        console.log(error);
      });

    // console.log("👓👓👓SHIFT DETAILS👓👓👓");
    // console.log(response);
    if (response && response.data !== null) {
      this.setState({
        isLoad: true,
        shiftdetail: response.data.data,
        isPartTime: response.data.data.isPartTime,
      });
    } else if (response && response.data.statusCode !== 200) {
      this.setState({
        error: true,
        errorCode: response.data.msg,
      });
    } else {
      this.setState({
        status: false,
      });
    }
  };
  PatchShift = async () => {
    let id = this.state.deleteId;
    const token = Cookie.get("patrickToken")
      ? Cookie.get("patrickToken")
      : null;
    const config = {
      headers: { Authorization: `Bearer ${token}` },
    };
    console.log(config);
    let data = {
      deleteId: id,
      name: this.state.name,
      startTime: this.ConvertDurationtoSeconds(this.state.startTime),
      endTime: this.ConvertDurationtoSeconds(this.state.endTime),
      isPartTime: this.state.isPartTime,
    };

    let response = await axios

      .patch(`${URL.BASE_URL}/masters/shifts/${id}/update`, data, config)
      .catch(function (error) {
        console.log(error);
      });

    if (response && response.data.statusCode === 200) {
      this.setState({
        isRedirect: true,
      });
    } else if (response && response.data.statusCode !== 200) {
      this.setState({
        error: true,
        errorCode: response.data.msg,
      });
    } else {
      this.setState({
        error: true,
      });
    }
    this.GetShiftList();
    this.toggleModal("formModal");
    console.log(response);
  };
  DeleteShift = async (key) => {
    let id = this.state.deleteId;
    const token = Cookie.get("patrickToken")
      ? Cookie.get("patrickToken")
      : null;
    const config = {
      headers: { Authorization: `Bearer ${token}` },
    };
    let response = await axios
      .delete(`${URL.BASE_URL}/masters/shifts/${id}/delete`, config)
      .catch(function (error) {
        console.log(error);
      });
    if (response.data.statusCode === 200) {
      this.setState({
        isRedirect: true,
      });
    }
    this.GetShiftList();
    this.toggleModal("notificationModal");
  };
  toggleModal = (state, id) => {
    this.setState({
      [state]: !this.state[state],
      deleteId: id,
    });
  };
  handleChange = (e) => {
    this.setState({
      [e.target.name]: e.target.value,
    });
  };
  handleRadio = async (event) => {
    const isPartTime = event.currentTarget.value === "true" ? true : false;
    console.log("handle", isPartTime);
    await this.setState({ isPartTime });
  };
  handleRadio2 = async (event) => {
    const isPartTime = event.currentTarget.value === "true" ? true : false;
    console.log("handle", isPartTime);
    await this.setState({ isPartTime });
  };

  Alert = () => {
    if (this.state.succsess) {
      return (
        <UncontrolledAlert color="success" fade={false} className="mt-1">
          <span className="alert-inner--icon">
            <i className="ni ni-like-2" />
          </span>{" "}
          <span className="alert-inner--text">
            <strong>Success!</strong> Shift Added Successfully
          </span>
        </UncontrolledAlert>
      );
    } else if (this.state.error) {
      return (
        <UncontrolledAlert color="danger" fade={false} className="mt-1">
          <span className="alert-inner--icon"></span>{" "}
          <span className="alert-inner--text">
            <strong>{this.state.errorCode}</strong>
          </span>
        </UncontrolledAlert>
      );
    } else {
      return <></>;
    }
  };
  Edit = () => {
    let id = this.state.deleteId;
    const { isPartTime } = this.state;
    let details;
    console.log(id, "///Edit Section///");
    if (this.state.deleteId) {
      React.useEffect(() => {
        this.GetShiftDetails(id);
      }, []);
      // this.GetShiftDetails(id);
      if (this.state.isLoad && this.state.shiftdetail) {
        details = this.state.shiftdetail;
        console.log(details, "🎈🎈🎈🎈🎈🎈");
        let endTime = new Date(details.endTime * 1000)
          .toISOString()
          .substr(11, 8);
        let startTime = new Date(details.startTime * 1000)
          .toISOString()
          .substr(11, 8);
        console.log(endTime, "💕💕💕💕💕💕💕");
        console.log(startTime, "💕💕💕💕💕💕💕");

        return (
          <>
            <Col md="4">
              <Modal
                className="modal-dialog-centered"
                size="sm"
                isOpen={this.state.formModal}
                toggle={() => this.toggleModal("formModal")}
              >
                <div className="modal-body p-0">
                  <Card className="bg-secondary shadow border-0">
                    <CardHeader className="bg-transparent pb-3">
                      <Row>
                        <Col>
                          <h3 className=" mb-0">Edit</h3>
                        </Col>
                        <Col>
                          <button
                            aria-label="Close"
                            className="close"
                            data-dismiss="modal"
                            type="button"
                            onClick={() => this.toggleModal("formModal")}
                          >
                            <span aria-hidden={true}>×</span>
                          </button>
                        </Col>
                      </Row>
                    </CardHeader>
                    <CardBody className="px-lg-5 py-lg-5">
                      <Form role="form">
                        <FormGroup className="mb-3">
                          <label
                            className="form-control-label"
                            htmlFor="input-username"
                          >
                            Name
                          </label>
                          <InputGroup className="input-group-alternative">
                            <Input
                              type="text"
                              name="name"
                              defaultValue={details.name}
                              onChange={this.handleChange}
                            />
                          </InputGroup>
                        </FormGroup>
                        <FormGroup>
                          <label
                            className="form-control-label"
                            htmlFor="input-username"
                          >
                            Start Time
                          </label>
                          <InputGroup className="input-group-alternative">
                            <Input
                              type="time"
                              name="startTime"
                              defaultValue={startTime}
                              onChange={this.handleChange}
                            />
                          </InputGroup>
                        </FormGroup>

                        <FormGroup>
                          <label
                            className="form-control-label"
                            htmlFor="input-username"
                          >
                            End Time
                          </label>
                          <InputGroup className="input-group-alternative">
                            <Input
                              type="time"
                              name="endTime"
                              defaultValue={endTime}
                              onChange={this.handleChange}
                            />
                          </InputGroup>
                        </FormGroup>
                        <FormGroup>
                          <label
                            className="form-control-label"
                            htmlFor="input-username"
                          >
                            Part Time
                          </label>
                          <Row>
                            <Col>
                              <div>
                                <input
                                  type="radio"
                                  name="isPartTime"
                                  checked={isPartTime === true}
                                  value="true"
                                  onChange={this.handleRadio2}
                                  style={{
                                    marginTop: "2px",
                                    marginRight: "10px",
                                    fontSize: "16px",
                                    border: "0px",
                                    width: "20px",
                                    height: "20px",
                                  }}
                                />

                                <label> Yes</label>
                              </div>
                            </Col>
                            <Col>
                              {" "}
                              <div>
                                <input
                                  type="radio"
                                  name="isPartTime"
                                  checked={isPartTime === false}
                                  value="false"
                                  onChange={this.handleRadio2}
                                  style={{
                                    marginTop: "2px",
                                    marginRight: "10px",
                                    fontSize: "16px",
                                    border: "0px",
                                    width: "20px",
                                    height: "20px",
                                  }}
                                />
                                <label> No</label>
                              </div>
                            </Col>
                          </Row>
                        </FormGroup>
                        <div className="text-center">
                          <Button
                            className="my-4"
                            color="primary"
                            type="button"
                            onClick={() => this.PatchShift("id")}
                          >
                            Save Changes
                          </Button>
                        </div>
                      </Form>
                    </CardBody>
                  </Card>
                </div>
              </Modal>
            </Col>
          </>
        );
      } else
        return (
          <>
            <div className=" d-flex justify-content-center mr-mt-md-4">
              <Spinner
                style={{ width: "3rem", height: "3rem" }}
                type="grow"
                color="warning"
              />
            </div>
          </>
        );
    } else return <></>;
  };
  Delete = () => {
    return (
      <>
        <Col md="12">
          <Modal
            className="modal-dialog-centered modal-danger"
            contentClassName="bg-gradient-danger"
            isOpen={this.state.notificationModal}
            toggle={() => this.toggleModal("notificationModal")}
          >
            <div className="modal-header">
              <h6 className="modal-title" id="modal-title-notification">
                Your confirmation is required
              </h6>
              <button
                aria-label="Close"
                className="close"
                data-dismiss="modal"
                type="button"
                onClick={() => this.toggleModal("notificationModal")}
              >
                <span aria-hidden={true}>×</span>
              </button>
            </div>
            <div className="modal-body">
              <div className="py-3 text-center">
                <i class="fas fa-trash-alt fa-3x"></i>
                <h4 className="heading mt-4">ATTENTION !!</h4>
                <p>Are you sure want to delete this Shift ?</p>
                <p>You can't undo this operation!</p>
              </div>
            </div>
            <div className="modal-footer">
              <Button
                className="btn-white"
                color="default"
                type="button"
                onClick={() => this.DeleteShift("id")}
              >
                Ok, Delete
              </Button>
              <Button
                className="text-white ml-auto"
                color="link"
                data-dismiss="modal"
                type="button"
                onClick={() => this.toggleModal("notificationModal")}
              >
                Close
              </Button>
            </div>
          </Modal>
        </Col>
      </>
    );
  };
  ShiftList = () => {
    if (this.state.isLoaded) {
      return (
        <>
          <thead className="thead-light">
            <tr>
              <th scope="col">#</th>
              <th scope="col">Name</th>
              <th scope="col">Start Time</th>
              <th scope="col">End time</th>
              <th scope="col">Part Time</th>
              <th scope="col">Action</th>
            </tr>
          </thead>
          <tbody>
            {this.state.shift.items.map((data, key) => {
              return (
                <tr>
                  <td>{(this.state.page - 1) * this.state.limit + key + 1}</td>
                  <td>{data.name}</td>
                  <td>
                    {new Date(data.startTime * 1000)
                      .toISOString()
                      .substr(11, 8)}
                  </td>
                  <td>
                    {new Date(data.endTime * 1000).toISOString().substr(11, 8)}
                  </td>
                  <td>{data.isPartTime === true ? "Yes" : "No"}</td>
                  <td>
                    <Button
                      className="btn-tooltip mr-3"
                      color="success"
                      size="sm"
                      type="button"
                      onClick={() => this.toggleModal("formModal", data.id)}
                    >
                      <span className="btn-inner--icon">
                        <i className="ni ni-ruler-pencil" />
                      </span>
                      <span className="btn-inner--text">Edit</span>
                    </Button>
                    <Button
                      className="btn-tooltip mr-3"
                      color="danger"
                      size="sm"
                      type="button"
                      onClick={() =>
                        this.toggleModal("notificationModal", data.id)
                      }
                    >
                      <span className="btn-inner--text">Delete</span>
                    </Button>
                  </td>
                </tr>
              );
            })}
          </tbody>
        </>
      );
    } else {
      return (
        <div className=" d-flex justify-content-center mr-mt-md-4">
          <Spinner
            style={{ width: "3rem", height: "3rem" }}
            type="grow"
            color="warning"
          />
        </div>
      );
    }
  };
  ConvertDurationtoSeconds = (duration) => {
    const [hours, minutes] = duration.split(":");
    return Number(hours) * 60 * 60 + Number(minutes) * 60;
  };
  Shift = () => {
    const { isPartTime } = this.state;
    return (
      <>
        <Form>
          <Row>
            <Col lg="3">
              <FormGroup>
                <label className="form-control-label" htmlFor="input-username">
                  Name
                </label>
                <Input type="text" name="name" onChange={this.handleChange} />
                <h4 style={style}>{this.state.nameError}</h4>
              </FormGroup>
            </Col>
            <Col md="3">
              <FormGroup>
                <label className="form-control-label" htmlFor="input-username">
                  Start Time
                </label>
                <Input
                  placeholder="Name"
                  type="time"
                  name="startTime"
                  onChange={this.handleChange}
                />
                <h4 style={style}>{this.state.startTimeError}</h4>
              </FormGroup>
            </Col>
            <Col md="3">
              <FormGroup>
                <label className="form-control-label" htmlFor="input-username">
                  End Time
                </label>
                <Input
                  placeholder="Name"
                  type="time"
                  name="endTime"
                  onChange={this.handleChange}
                />
                <h4 style={style}>{this.state.endTimeError}</h4>
              </FormGroup>
            </Col>
            <Col md="2">
              <FormGroup>
                <label className="form-control-label" htmlFor="input-username">
                  Part Time
                </label>
                <Row>
                  <Col>
                    <div>
                      <input
                        type="radio"
                        name="isPartTime"
                        value="true"
                        // checked={isPartTime === true}
                        onChange={this.handleRadio}
                        style={{
                          marginTop: "2px",
                          marginRight: "10px",
                          fontSize: "16px",
                          border: "0px",
                          width: "20px",
                          height: "20px",
                        }}
                      />

                      <label> Yes</label>
                    </div>
                  </Col>
                  <Col>
                    {" "}
                    <div>
                      <input
                        type="radio"
                        name="isPartTime"
                        value="false"
                        // checked={isPartTime === false}
                        onChange={this.handleRadio}
                        style={{
                          marginTop: "2px",
                          marginRight: "10px",
                          fontSize: "16px",
                          border: "0px",
                          width: "20px",
                          height: "20px",
                        }}
                      />
                      <label> No</label>
                    </div>
                  </Col>
                </Row>
                <h4 style={style}>{this.state.isPartTimeError}</h4>
              </FormGroup>
            </Col>
          </Row>
          <Row></Row>
          <Row className=" d-flex justify-content-center mr-mt-md-4">
            <Col lg="0">
              <Button
                className="btn-icon btn-2"
                color="info"
                type="button"
                block
                onClick={this.PostShift}
              >
                <span className="btn-inner--text">Add Shift</span>
              </Button>
            </Col>
          </Row>
        </Form>
      </>
    );
  };
  render() {
    return (
      <>
        <Header2 />
        <Container className=" mt--7" fluid>
          {/* Table */}
          <Row>
            <div className=" col">
              <Card className=" shadow">
                <CardHeader className=" bg-transparent">
                  <h3 className=" mb-0">Add Shift</h3>
                </CardHeader>
                <CardBody>
                  <this.Shift />
                </CardBody>
              </Card>
              <this.Alert />
              <br />
            </div>
          </Row>
        </Container>
        <div>
          <Container className=" mt--10" fluid>
            {/* Table */}
            <Row>
              <div className=" col">
                <Card className=" shadow">
                  <CardHeader className=" border-0"></CardHeader>

                  <Table className="align-items-center table-flush" responsive>
                    <this.ShiftList />
                  </Table>
                  <this.Delete />
                  <this.Edit />
                  <CardFooter className="py-4"></CardFooter>
                </Card>
              </div>
            </Row>
          </Container>
        </div>
      </>
    );
  }
}
