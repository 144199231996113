import React from 'react';

import { DropdownItem } from 'reactstrap';

export default class Logout extends React.Component {

  render() {
    return (
      <a href='/admin/logout'>
        <DropdownItem className='text-primary'>
          <i className='ni ni-user-run'>&#xE879;</i>
          Logout
        </DropdownItem>
      </a>
    );
  }
}
