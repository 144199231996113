import React from "react";
import { Link } from "react-router-dom";

// node.js library that concatenates classes (strings)
import classnames from "classnames";
// javascipt plugin for creating charts
import Chart from "chart.js";
// react plugin used to create charts
import { Line, Bar } from "react-chartjs-2";
// reactstrap components
import {
  Button,
  Card,
  CardHeader,
  CardBody,
  NavItem,
  NavLink,
  Nav,
  Progress,
  Table,
  Container,
  Row,
  Col,
  CardTitle,
  Spinner,
} from "reactstrap";
import Cookie from "js-cookie";
// core components
import {
  chartOptions,
  parseOptions,
  chartExample1,
  chartExample2,
} from "variables/charts.js";

import Header2 from "components/Headers/Header2";
const axios = require("axios").default;
const URL = require("./ApiUrl");
class Index extends React.Component {
  constructor(props) {
    super(props);
    this.state = {
      activeNav: 1,
      chartExample1Data: "data1",
      dashboardItems: [],
      isLoaded: false,
    };
    if (window.Chart) {
      parseOptions(Chart, chartOptions());
    }
  }
  toggleNavs = (e, index) => {
    e.preventDefault();
    this.setState({
      activeNav: index,
      chartExample1Data:
        this.state.chartExample1Data === "data1" ? "data2" : "data1",
    });
  };

  GetDashboard = async () => {
    const token = Cookie.get("patrickToken")
      ? Cookie.get("patrickToken")
      : null;
    const config = {
      headers: { Authorization: `Bearer ${token}` },
    };

    let response = await axios
      .get(`${URL.BASE_URL}/admin-accounts/dashboard`, config)
      .catch(function (error) {
        console.log(error);
      });
    console.log("👇👇👇👇👇👇👇👇👇👇");
    console.log(response);
    if (response && response.data !== null) {
      this.setState({
        dashboardItems: response.data.data,
        isLoaded: true,
      });
    } else if (response && response.data.statusCode !== 200) {
      this.setState({
        error: true,
        errorCode: response.data.msg,
      });
    } else {
      this.setState({
        status: false,
      });
    }
  };
  componentDidMount() {
    this.GetDashboard();
  }
  render() {
    if (this.state.isLoaded) {
      let data = this.state.dashboardItems;
      console.log(data.orders, "🤩🤩🤩🤩🤩");
      return (
        <>
          <div className="header bg-gradient-warning pb-8 pt-5 pt-md-8">
            <Container fluid>
              <div className="header-body">
                {/* Card stats */}
                <Row>
                  <Col lg="6" xl="3">
                    <Card className="card-stats mb-4 mb-xl-0">
                      <CardBody>
                        <Row>
                          <div className="col">
                            <CardTitle
                              tag="h5"
                              className="text-uppercase text-muted mb-0"
                            >
                              Total Sales
                            </CardTitle>
                            <span className="h2 font-weight-bold mb-0">{data.totalSalesCount}</span>
                          </div>
                          <Col className="col-auto">
                            <div className="icon icon-shape bg-danger text-white rounded-circle shadow">
                              <i className="fas fa-chart-bar" />
                            </div>
                          </Col>
                        </Row>
                        <p className="mt-3 mb-0 text-muted text-sm">
                          {/* <span className="text-success mr-2">
                          <i className="fa fa-arrow-up" /> 3.48%
                        </span>{" "}
                        <span className="text-nowrap">Since last month</span> */}
                        </p>
                      </CardBody>
                    </Card>
                  </Col>
                  <Col lg="6" xl="3">
                    <Card className="card-stats mb-4 mb-xl-0">
                      <CardBody>
                        <Row>
                          <div className="col">
                            <CardTitle
                              tag="h5"
                              className="text-uppercase text-muted mb-0"
                            >
                              Total Users
                            </CardTitle>
                            <span className="h2 font-weight-bold mb-0">{data.totalCustomerCount}</span>
                          </div>
                          <Col className="col-auto">
                            <div className="icon icon-shape bg-warning text-white rounded-circle shadow">
                            <i className="fas fa-users" />
                            </div>
                          </Col>
                        </Row>
                        <p className="mt-3 mb-0 text-muted text-sm">
                          {/* <span className="text-danger mr-2">
                          <i className="fas fa-arrow-down" /> 3.48%
                        </span>{" "}
                        <span className="text-nowrap">Since last week</span> */}
                        </p>
                      </CardBody>
                    </Card>
                  </Col>
                  <Col lg="6" xl="3">
                    <Card className="card-stats mb-4 mb-xl-0">
                      <CardBody>
                        <Row>
                          <div className="col">
                            <CardTitle
                              tag="h5"
                              className="text-uppercase text-muted mb-0"
                            >
                             Total Orders
                            </CardTitle>
                            <span className="h2 font-weight-bold mb-0">{data.totalOrderCount}</span>
                          </div>
                          <Col className="col-auto">
                            <div className="icon icon-shape bg-yellow text-white rounded-circle shadow">
                              <i className="ni ni-cart" />
                            </div>
                          </Col>
                        </Row>
                        <p className="mt-3 mb-0 text-muted text-sm">
                          {/* <span className="text-warning mr-2">
                          <i className="fas fa-arrow-down" /> 1.10%
                        </span>{" "}
                        <span className="text-nowrap">Since yesterday</span> */}
                        </p>
                      </CardBody>
                    </Card>
                  </Col>
                  <Col lg="6" xl="3">
                    <Card className="card-stats mb-4 mb-xl-0">
                      <CardBody>
                        <Row>
                          <div className="col">
                            <CardTitle
                              tag="h5"
                              className="text-uppercase text-muted mb-0"
                            >
                              Total Products
                            </CardTitle>
                            <span className="h2 font-weight-bold mb-0">{data.totalProductCount}</span>
                          </div>
                          <Col className="col-auto">
                            <div className="icon icon-shape bg-info text-white rounded-circle shadow">
                              <i className="ni ni-box-2" />
                            </div>
                          </Col>
                        </Row>
                        <p className="mt-3 mb-0 text-muted text-sm">
                          {/* <span className="text-success mr-2">
                          <i className="fas fa-arrow-up" /> 12%
                        </span>{" "}
                        <span className="text-nowrap">Since last month</span> */}
                        </p>
                      </CardBody>
                    </Card>
                  </Col>
                </Row>
              </div>
            </Container>
          </div>
          {/* Page content */}
          <Container className="mt--7" fluid>
            <Row className="mt-5">
              <Col className="mb-5 mb-xl-0" xl="6">
                <Card className="shadow">
                  <CardHeader className="border-0">
                    <Row className="align-items-center">
                      <div className="col">
                        <h3 className="mb-0">Recent Products</h3>
                      </div>
                      <div className="col text-right">
                        <Link to={`/admin/products`}>
                          <Button color="primary" size="sm">
                            See all
                          </Button>
                        </Link>
                      </div>
                    </Row>
                  </CardHeader>
                  <Table className="align-items-center table-flush" responsive>
                    <thead className="thead-light">
                      <tr>
                        <th scope="col">Product name</th>
                        <th scope="col">Qty</th>
                        <th scope="col">Price</th>
                        <th scope="col">SELLER NAME</th>
                      </tr>
                    </thead>
                    <tbody>
                      {data.products &&
                      data.products.map((data, key) => {
                        return (
                          <tr>
                            <td>{data.name}</td>
                            <td>{data.quantity}</td>
                            <td>{data.actualPrice}</td>
                            <td>{data.sellerId.sellerName}</td>
                          </tr>
                        );
                      })}
                    </tbody>
                  </Table>
                </Card>
              </Col>
              <Col xl="6">
                <Card className="shadow">
                  <CardHeader className="border-0">
                    <Row className="align-items-center">
                      <div className="col">
                        <h3 className="mb-0">Recent Orders</h3>
                      </div>
                      <div className="col text-right">
                        <Link to={`/admin/orders`}>
                          <Button color="primary" size="sm">
                            See all
                          </Button>
                        </Link>
                      </div>
                    </Row>
                  </CardHeader>
                  <Table className="align-items-center table-flush" responsive>
                    <thead className="thead-light">
                      <tr>
                        <th scope="col">EMAIL</th>
                        <th scope="col">PHONE NUMBER</th>
                        <th scope="col">DATE OF PURCHASE</th>
                      </tr>
                    </thead>
                    <tbody>
                      {data.orders &&
                      data.orders.map((data, key) => {
                        let d = new Date(data.createdAt);
                        let date = d.getDate();
                        let month = d.getMonth() + 1;
                        let year = d.getFullYear();
                        let dateStr = date + "/" + month + "/" + year;
                        return (
                          <tr>
                            <td>{data.email}</td>
                            <td>{data.mobile}</td>
                            <td>{dateStr}</td>
                          </tr>
                        );
                      })}
                    </tbody>
                  </Table>
                </Card>
              </Col>
            </Row>
          </Container>
        </>
      );
    } else {
      return (
        <>
          <Header2 />
          <Container className="mt-5">
            <Spinner
              color="warning"
              style={{ width: "4rem", height: "4rem", marginLeft: "45%" }}
            />{" "}
          </Container>
        </>
      );
    }
  }
}

export default Index;
