import React, { Component } from "react";
import { Redirect } from "react-router-dom";

import {
  Card,
  CardHeader,
  Container,
  Row,
  Button,
  FormGroup,
  Input,
  Col,
  CardBody,
  CardTitle,
  CardText,
  UncontrolledAlert,
  Modal,
  ModalBody,
} from "reactstrap";

import Cookie from "js-cookie";
import Header2 from "components/Headers/Header2";
const axios = require("axios").default;
const URL = require("../../ApiUrl");
const style = {
  color: "red",
};
export default class CustomerMessage extends Component {
  constructor(props) {
    super(props);
    this.PostSubscription = this.PostSubscription.bind(this);
    // this.GetSubscription = this.GetSubscription.bind(this);
    this.handleChange = this.handleChange.bind(this);
    // this.ShowSubscriptionPlan = this.ShowSubscriptionPlan.bind(this);
    this.SendMessage = this.SendMessage.bind(this);
    this.EditOption = this.EditOption.bind(this);
    this.state = {
      userName: "",
      message : "",
      succsess: false,
      error: false,
      defaultModal: false,
      modalOpen: false,
      id: [],
    };
  }
  resetErrorMsg = () => {
    this.setState({
      userNameError: "",
      messageError: "",
    });
  };
  AddToItems = () => {
    this.resetErrorMsg();

    let isValid = true;

    if (this.state.userName === "") {
      isValid = false;
      this.setState({
        userNameError: "*userName is required",
      });

      console.log(this.state.plan, "userName error");
    }
    if (this.state.message === "") {
      isValid = false;
      this.setState({
        messageError: "*Message is required",
      });

      console.log(this.state.details, "Message error");
    }

    if (!isValid) {
      return;
    } else {
      var obj = {
        userName: this.state.userName,
        message: this.state.message,
      };
      console.log(obj, "🎁🎁🎁🎁🎁🎁");
      this.PostSubscription(obj);
    }
  };

  async PostSubscription(data) {
    const token = Cookie.get("patrickToken")
      ? Cookie.get("patrickToken")
      : null;
    const config = {
      headers: { Authorization: `Bearer ${token}` },
    };
    console.log(data, "🤩🤩🤩🤩🤩");
    //console.log(config);

    let response = await axios
      .post(`${URL.BASE_URL}/admin-accounts/customers/message`, data, config)
      .catch(function (error) {
        console.log(error);
      });

    if (response && response.data.statusCode === 200) {
      this.setState({
        succsess: true,
        isRedirect: true,
      });
    } else if (response && response.data.statusCode !== 200) {
      this.setState({
        error: true,
        errorCode: response.data.msg,
      });
    } else {
      this.setState({
        error: true,
        errorCode: "Oops something went wrong!!",
      });
    }
    this.GetSubscription();
    setTimeout(() => {
      this.setState({
        status: false,
        error: false,
      });
    }, 2000);

    console.log(response);
  }

  async GetSubscription() {
    const token = Cookie.get("patrickToken")
      ? Cookie.get("patrickToken")
      : null;
    const config = {
      headers: { Authorization: `Bearer ${token}` },
    };

    let response = await axios
      .get(
        `${URL.BASE_URL}/admin-products/subscription/list?type=subscription`,
        config
      )
      .catch(function (error) {
        console.log(error);
      });

    console.log("___");
    console.log(response);

    if (response && response.data !== null) {
      this.setState({
        subscriptionPlans: response.data.data,
      });
    } else if (response && response.data.statusCode !== 200) {
      this.setState({
        error: true,
        errorCode: response.data.msg,
      });
    } else {
      this.setState({
        status: false,
      });
    }
  }

  PatchSubcriptionPlanEdit = async (id) => {
    //console.log(key)
    const token = Cookie.get("patrickToken")
      ? Cookie.get("patrickToken")
      : null;
    const config = {
      headers: { Authorization: `Bearer ${token}` },
    };
    console.log(config);
    let data = {
      planId: id,
      name: this.state.name,
      rate: this.state.rate,
      details: this.state.details,
      plan: this.state.plan,
    };

    let response = await axios

      .patch(
        `${URL.BASE_URL}/admin-products/subscription/${id}/update?type=subscription`,
        data,
        config
      )
      .catch(function (error) {
        console.log(error);
      });

    if (response && response.data.statusCode === 201) {
      this.setState({
        succsess: true,
        isRedirect: true,
      });
    } else if (response && response.data.statusCode !== 200) {
      this.setState({
        error: true,
        errorCode: response.data.msg,
      });
    } else {
      this.setState({
        error: true,
      });
    }
    this.GetSubscription();

    console.log(response);
  };

  
  handleChange(e) {
    this.setState({
      [e.target.name]: e.target.value,
    });
  }
  handleChange1 = (e) => {
    [e.target.name] = e.target.value;
  };
  componentDidMount() {
    // this.GetSubscription();
  }

  SendMessage({ id, name, rate, details, isEdit, plan }) {
    console.log(isEdit);
    let btnText = "Add";
    let nameValue;
    let rateValue;
    let detailValue;
    let planValue;

    if (isEdit) {
      btnText = "Save Changes";
      nameValue = name;
      rateValue = rate;
      detailValue = details;
      planValue = plan;
    }
    return (
      <>
        <Row className=" icon-examples">
          <Col lg="4" md="6">
            <FormGroup>
              <Input
                placeholder="Email or Mobile"
                name="userName"
                type="text"
                onChange={this.handleChange}
              />
              <h4 style={style}>{this.state.userNameError}</h4>

            </FormGroup>
          </Col>
       
        </Row>
        <FormGroup>
          <Input
            placeholder="Message"
            rows="3"
            onChange={this.handleChange}
            name="message"
            type="textarea"
          />
              <h4 style={style}>{this.state.messageError}</h4>

        </FormGroup>
        <Row className="justify-content-lg-center">
          <Col lg="0">
     
              <Button
                className="btn-icon btn-2"
                color="info"
                type="button"
                onClick={this.AddToItems}
              >
                <span className="btn-inner--icon">
                  <span className="btn-inner--text">Send</span>
                </span>
              </Button>
          </Col>
        </Row>
      </>
    );
  }
  toggleModal = (state, id) => {
    this.setState({
      [state]: !this.state[state],
      deleteId: id,
    });
  };

  EditOption({ id, name, rate, details, plan }) {
    const [modalOpen, setModalOpen] = React.useState(false);
    return (
      <>
        <Button
          className="btn-tooltip mr-3"
          color="success"
          type="button"
          size="sm"
          onClick={() => setModalOpen(!modalOpen)}
        >
          <span className="btn-inner--icon">
            <i className="ni ni-ruler-pencil" />
          </span>
          <span className="btn-inner--text">Edit</span>
        </Button>
        <Modal
          toggle={() => setModalOpen(!modalOpen)}
          isOpen={modalOpen}
          size="lg"
        >
          <div className=" modal-header">
            <h5 id=" modal-title">Subcription Plan</h5>
            <button
              aria-label="Close"
              className=" close"
              type="button"
              onClick={() => setModalOpen(!modalOpen)}
            >
              <span aria-hidden={true}>×</span>
            </button>
          </div>
          <ModalBody>
            <this.SendMessage
              isEdit={true}
              name={name}
              id={id}
              rate={rate}
              plan={plan}
              details={details}
            />
          </ModalBody>
        </Modal>
      </>
    );
  }

  Alert = () => {
    if (this.state.succsess) {
      return (
        <UncontrolledAlert color="success" fade={false} className="mt-1">
          <span className="alert-inner--icon">
            <i className="ni ni-like-2" />
          </span>{" "}
          <span className="alert-inner--text">
            <strong>Success!</strong> Subcription Plan Added Successfully
          </span>
        </UncontrolledAlert>
      );
    } else if (this.state.error) {
      return (
        <UncontrolledAlert color="danger" fade={false} className="mt-1">
          <span className="alert-inner--icon"></span>{" "}
          <span className="alert-inner--text">
            <strong>{this.state.errorCode}</strong>
          </span>
        </UncontrolledAlert>
      );
    } else {
      return <></>;
    }
  };

  // ShowSubscriptionPlan() {
  //   let plans = [];
  //   if (this.state.subscriptionPlans) {
  //     plans = this.state.subscriptionPlans;
  //   }
  //   return (
  //     <>
  //       <Row>
  //         {plans.map((item, key) => {
  //           // console.log(key)
  //           return (
  //             <Col lg="4">
  //               <Card className=" text-center mt-5 shadow p-3 mb-5 bg-white rounded">
  //                 <CardBody>
  //                   <CardTitle>{item.planName}</CardTitle>
  //                   <CardText>
  //                     ₹ {item.rate} / {item.plan}
  //                   </CardText>
  //                   <CardText>{item.details}</CardText>
  //                   <Row className="justify-content-md-center">
  //                     <Col lg="0">
  //                       <this.EditOption
  //                         id={item.id}
  //                         name={item.planName}
  //                         rate={item.rate}
  //                         plan={item.plan}
  //                         details={item.details}
  //                       />
  //                     </Col>
  //                     <Col lg="0">
  //                       <Button
  //                         className="btn-tooltip mr-3"
  //                         color="danger"
  //                         type="button"
  //                         size="sm"
  //                         onClick={() =>
  //                           this.toggleModal("notificationModal", item.id)
  //                         }
  //                       >
  //                         Delete
  //                       </Button>
  //                     </Col>
  //                   </Row>
  //                 </CardBody>
  //               </Card>
  //             </Col>
  //           );
  //         })}

  //         <Col md="12">
  //           <Modal
  //             className="modal-dialog-centered modal-danger"
  //             contentClassName="bg-gradient-danger"
  //             isOpen={this.state.notificationModal}
  //             toggle={() => this.toggleModal("notificationModal")}
  //           >
  //             <div className="modal-header">
  //               <h6 className="modal-title" id="modal-title-notification">
  //                 Your confirmation is required
  //               </h6>
  //               <button
  //                 aria-label="Close"
  //                 className="close"
  //                 data-dismiss="modal"
  //                 type="button"
  //                 onClick={() => this.toggleModal("notificationModal")}
  //               >
  //                 <span aria-hidden={true}>×</span>
  //               </button>
  //             </div>
  //             <div className="modal-body">
  //               <div className="py-3 text-center">
  //                 <i class="fas fa-trash-alt fa-3x"></i>
  //                 <h4 className="heading mt-4">ATTENTION !!</h4>
  //                 <p>Are you sure want to delete this Subcription Plan ?</p>
  //                 <p>You can't undo this operation!</p>
  //               </div>
  //             </div>
  //             <div className="modal-footer">
  //               <Button
  //                 className="btn-white"
  //                 color="default"
  //                 type="button"
  //                 onClick={() => this.DeleteSubciptionPlan("id")}
  //               >
  //                 Ok, Delete
  //               </Button>
  //               <Button
  //                 className="text-white ml-auto"
  //                 color="link"
  //                 data-dismiss="modal"
  //                 type="button"
  //                 onClick={() => this.toggleModal("notificationModal")}
  //               >
  //                 Close
  //               </Button>
  //             </div>
  //           </Modal>
  //         </Col>
  //       </Row>
  //     </>
  //   );
  // }

  render() {
    // if (this.state.isRedirect) {
    //   return window.location.reload(false);
    // }

    return (
      <>
        <Header2 />
        <Container className=" mt--7" fluid>
          {/* Table*/}
          <Row>
            <div className=" col">
              <Card className=" shadow">
                <CardHeader className=" bg-transparent">
                  <h3 className=" mb-0">Send Message</h3>
                </CardHeader>

                <CardBody>
                  <this.SendMessage />
                </CardBody>
              </Card>
            </div>
          </Row>
          <this.Alert />
        </Container>

      
      </>
    );
  }
}
