import React, { Component } from "react";
import { Link } from "react-router-dom";
import { Spinner } from "reactstrap";
import Select from "react-select";
import {
  Card,
  CardHeader,
  Container,
  Row,
  Col,
  Table,
  Button,
  Pagination,
  PaginationItem,
  PaginationLink,
  CardFooter,
  FormGroup,
  Input,
  InputGroupAddon,
  InputGroupText,
  InputGroup,
} from "reactstrap";

import Cookie from "js-cookie";
import Header2 from "components/Headers/Header2";
const axios = require("axios").default;
const URL = require("../../ApiUrl");

class Orders extends Component {
  state = {
    succsess: false,
    error: false,
    isLoaded: false,
    limit: 20,
    page: 1,
    orders: {},
    hasNextPage: false,
    originalData: [],
    category: [],
    orderStatus: "",
    id : ""
  };
  GetFranchiseList = async () => {
    const token = Cookie.get("patrickToken")
      ? Cookie.get("patrickToken")
      : null;
    const config = {
      headers: { Authorization: `Bearer ${token}` },
    };

    let response = await axios
      .get(
        `${URL.BASE_URL}/admin-franchises/list?page=${1}&limit=${100}`,
        config
      )
      .catch(function (error) {
        console.log(error);
      });
    console.log("👇👇👇👇👇👇👇👇👇👇");
    console.log(response);
    if (response && response.data !== null) {
      this.setState({
        category: response.data.data.items,
      });
    } else if (response && response.data.statusCode !== 200) {
      this.setState({
        error: true,
        errorCode: response.data.msg,
      });
    } else {
      this.setState({
        status: false,
      });
    }
  };

  GetSearchOrders = async (e) => {
    this.setState({ isLoaded: false });
    let dateStr = Date.parse(e.target.value);
    if (e.target.value === "") {
      this.GetOrder();
    } else {
      const token = Cookie.get("patrickToken")
        ? Cookie.get("patrickToken")
        : null;
      const config = {
        headers: { Authorization: `Bearer ${token}` },
      };
      let page = this.state.page;
      let limit = this.state.limit;
      let response = await axios
        // console.log(dateStr,"🎁🎁🎁🎁🎁🎁🎁")
        .get(
          `${URL.BASE_URL}/admin-orders/search?limit=${limit}&page=${page}&dateText=${dateStr}`,
          config
        )
        .catch(function (error) {
          console.log(error);
        });

      console.log("🎉🎉🎉🎉🎉🎉SEARCHING................❤❤💖💖💖");
      console.log(response);

      if (response && response.data !== null) {
        this.setState({
          orders: response.data.data,
          page: 1,
          isLoaded: true,
          page: 1,
        });
      } else if (response && response.data.statusCode !== 200) {
        this.setState({
          error: true,
          errorCode: response.data.msg,
        });
      } else {
        this.setState({
          status: false,
        });
      }
    }
  };

  GetOrder = async (type, p) => {
    this.setState({ isLoaded: false });
    const token = Cookie.get("patrickToken")
      ? Cookie.get("patrickToken")
      : null;
    const config = {
      headers: { Authorization: `Bearer ${token}` },
    };
    let orderStatus = this.state.orderStatus
    let id = this.state.id
    console.log(id,"ivde")
    console.log(orderStatus,"ivde")
    let page = p ? p : this.state.page;
    let limit = this.state.limit;
    let url = `${URL.BASE_URL}/admin-orders/list?orderStatus=${orderStatus}&page=${page}&limit=${limit}&franchiseId=${id}`;

    let response = await axios.get(url, config).catch(function (error) {
      console.log(error);
    });

    console.log("👇👇👇👇👇👇👇👇👇👇");
    console.log(response);

    if (response && response.data !== null) {
      this.setState({
        orders: response.data.data,
        originalData: response.data.data,
        isLoaded: true,
      });
    } else if (response && response.data.statusCode !== 200) {
      this.setState({
        error: true,
        errorCode: response.data.msg,
      });
    } else {
      this.setState({
        status: false,
      });
    }
  };

  handleChange = (e) => {
    this.setState({
      [e.target.data]: e.target.value,
    });
  };

  componentDidMount = () => {
    this.GetFranchiseList();
    this.GetOrder();
  };

  OrderDetails = () => {
    let data = this.state.orders;
    console.log(data, "💕💕💕💕💕💕💕");
    if (this.state.isLoaded) {
      return (
        <>
          <thead className="thead-light">
            <tr>
              <th>#</th>
              <th>First Name</th>
              <th>Last Name</th>
              <th>Email</th>
              <th>Phone Number</th>
              <th>Date of Purchase</th>
              <th>Action</th>
            </tr>
          </thead>
          <tbody>
            {this.state.orders &&
              this.state.orders.items.map((data, key) => {
                console.log(data, "🧨🧨🧨🧨🧨🧨🧨");
                // console.log(data.user.firstName,"🎆🎆🎆🎆🎆🎆")
                let d = new Date(data.createdAt);

                let date = d.getDate();
                let month = d.getMonth() + 1;
                let year = d.getFullYear();

                let dateStr = date + "/" + month + "/" + year;
                return (
                  <tr>
                    <td>
                      {(this.state.page - 1) * this.state.limit + key + 1}
                    </td>
                    <td>{data.user?data.user.firstName:data.customerName}</td>
                    <td>{data.user?data.user.lastName:'--'}</td>
                    <td>{data.user?data.user.email:data.customerEmail}</td>
                    <td>{data.user?data.user.mobile:data.customerPhone}</td>
                    <td>{dateStr}</td>
                    <td>
                      <Link to={`/admin/ordersdetails/${data._id}/detail`}>
                        <Button
                          className=" btn-icon"
                          color="info"
                          size="sm"
                          type="button"
                        >
                          <i className=" ni ni-circle-08 pt-1"></i>
                          <span className="btn-inner--text">View</span>
                        </Button>
                      </Link>
                    </td>
                  </tr>
                );
              })}
          </tbody>
        </>
      );
    } else {
      return (
        <div className=" d-flex justify-content-center mr-mt-md-4">
          <Spinner
            style={{ width: "3rem", height: "3rem" }}
            type="grow"
            color="warning"
          />
        </div>
      );
    }
  };
  Pagination = () => {
    let totalPages = this.state.orders ? this.state.orders.totalPages : 0;
    let currentPage = this.state.page;
    let pageItems = Array.apply(null, Array(totalPages)).map(function (x, i) {
      return i;
    });
    return (
      <>
        <nav aria-label="Page navigation example">
          <Pagination
            className="pagination justify-content-end"
            listClassName="justify-content-end"
          >
            {pageItems.map((item, key) => {
              if (currentPage === key + 1) {
                return (
                  <PaginationItem className="active">
                    <PaginationLink
                      href="#pablo"
                      onClick={(e) => e.preventDefault()}
                    >
                      {key + 1}
                    </PaginationLink>
                  </PaginationItem>
                );
              } else {
                return (
                  <PaginationItem>
                    <PaginationLink
                      href="#pablo"
                      onClick={(e) => {
                        e.preventDefault();
                        this.setState({ page: key + 1 });
                        this.GetOrder(key + 1);
                      }}
                    >
                      {key + 1}
                    </PaginationLink>
                  </PaginationItem>
                );
              }
            })}
          </Pagination>
        </nav>
      </>
    );
  };
  handleChange = async (selectedOption) => {
   await this.setState({
      orderStatus: selectedOption.value,
    });
    this.GetOrder()
    console.log(`Option selected:`, selectedOption);
  };

  OrderStatusType = () => {
    const { selectedOption } = this.state;
    const options = [
      { value: "", label: "All" },
      { value: "Deliverycomplete", label: "Delivery complete" },
      { value: "Processing", label: "Processing" },
      { value: "Pending", label: "Pending" },
    ];
    return (
      <>
        <Select
          value={selectedOption}
          onChange={this.handleChange}
          options={options}
          placeholder="Select Order Status"
        />
      </>
    );
  };

  handleChange2 = async (selectedOptionType) => {
    this.setState({ selectedOptionType });
    console.log(`Option selected:`, selectedOptionType);
    console.log(selectedOptionType.value);
    await this.setState({
      id: selectedOptionType.value,
    });
    this.GetOrder();
  };
  FarnchiseSelect = () => {
    const { selectedOptionType } = this.state;
    let options = [{ value: "", label: "All" }];
    this.state.category.map((item) =>
      options.push({
        value: item.id,
        label: item.franchiseName,
      })
    );
    return (
      <>
        <Select
          value={selectedOptionType}
          onChange={this.handleChange2}
          options={options}
          placeholder="Select Franchise"
        />
      </>
    );
  };
  render() {
    return (
      <>
        <Header2 />
        {/* Page content */}
        <Container className="mt--7" fluid>
          {/* Table */}
          <Row>
            <div className="col">
              <Card className="shadow">
                <CardHeader className="border-0">
                  <Row>
                    <Col>
                      <h2 className="mb-0">Order</h2>
                    </Col>
                    <Col md="3">
                      <this.FarnchiseSelect />
                    </Col>
                    <Col md="3">
                      <this.OrderStatusType />
                    </Col>
                    <Col md="4">
                      <FormGroup>
                        <InputGroup>
                          <InputGroupAddon addonType="prepend">
                            <InputGroupText>
                              <i className="ni ni-calendar-grid-58" />
                            </InputGroupText>
                          </InputGroupAddon>

                          <Input
                            type="date"
                            name="date"
                            onChange={this.GetSearchOrders}
                            placeholder="date placeholder"
                          />
                        </InputGroup>
                      </FormGroup>
                    </Col>
                  </Row>
                </CardHeader>
                <Table className="align-items-center table-flush" responsive>
                  <this.OrderDetails />{" "}
                </Table>
                <CardFooter className="py-4">
                  <this.Pagination />
                </CardFooter>
              </Card>
            </div>
          </Row>
        </Container>
      </>
    );
  }
}

export default Orders;
