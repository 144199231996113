import React from "react";
import { Redirect } from "react-router-dom";

// reactstrap components
import {
  Card,
  CardHeader,
  Container,
  Row,
  Button,
  Col,
  CardBody,
  FormGroup,
  Input,
  UncontrolledAlert,
  ListGroup,
  ListGroupItem,
  Modal,
  Table,
  Spinner,
  ModalBody,
  CardFooter,
} from "reactstrap";
// core components
import Header2 from "../../components/Headers/Header2";

import Cookie from "js-cookie";
import CustomInput from "reactstrap/lib/CustomInput";
import { isThisTypeNode } from "typescript";
const axios = require("axios").default;
const URL = require("../ApiUrl");

class Settings extends React.Component {
  constructor(props) {
    super(props);
   
    this.handleChange = this.handleChange.bind(this);
    
    
  
   
    this.state = {
      name: "",
      
     name1:"",
      succsess: false,
      error: false,
     
       category: [],
     
      id: [],
      
     
    };
  }
 
 

  async GetCategory() {
    const token = Cookie.get("patrickToken")
      ? Cookie.get("patrickToken")
      : null;
    const config = {
      headers: { Authorization: `Bearer ${token}` },
    };

    let response = await axios
      .get(`${URL.BASE_URL}/masters/settings`, config)
      .catch(function (error) {
        console.log(error);
      });
    console.log("👇👇👇👇👇👇👇👇👇👇");
    console.log(response);
    if (response && response.data !== null) {
      this.setState({
        category: response.data.data,
      });
    } else if (response && response.data.statusCode !== 200) {
      this.setState({
        error: true,
        errorCode: response.data.msg,
      });
    } else {
      this.setState({
        status: false,
      });
    }
  }

 
  PatchCategoryEdit = async () => {
    console.log("//////////////////");
    const token = Cookie.get("patrickToken")
      ? Cookie.get("patrickToken")
      : null;
    const config = {
      headers: { Authorization: `Bearer ${token}` },
    };
    console.log(config);
    let data = {
      onesignalAppId: this.state.name,
      onesignalApiKey:this.state.name1,
      onesignalFranchiseAppId: this.state.franchiseAppId,
      onesignalSellerAppId: this.state.sellerAppId,
      onesignalCustomerAppId: this.state.customerAppId,
    };
    console.log(data);

    let response = await axios
      .patch(
        `${URL.BASE_URL}/masters/settings/update`,
        data,
        config
      )
      .catch(function (error) {
        console.log(error);
      });
    if (response && response.data.statusCode === 200) {
      this.setState({
        succsess: true,
        isRedirect: true,
      });
    } else if (response && response.data.statusCode !== 200) {
      this.setState({
        error: true,
        errorCode: response.data.msg,
      });
    } else {
      this.setState({
        error: true,
      });
    }

    console.log(response);
  };

  handleChange(e) {
    this.setState({
      [e.target.name]: e.target.value,
    });
    console.log(e.target.value);
  }
  
 

  componentDidMount() {
    this.GetCategory();
  }
  Alert = () => {
    if (this.state.succsess) {
      return (
        <UncontrolledAlert color="success" fade={false}>
          <span className="alert-inner--icon">
            <i className="ni ni-like-2" />
          </span>{" "}
          <span className="alert-inner--text">
            <strong>Success!</strong>Settings updated Successfully
          </span>
        </UncontrolledAlert>
      );
    } else if (this.state.error) {
      return (
        <UncontrolledAlert color="danger" fade={false}>
          <span className="alert-inner--icon"></span>{" "}
          <span className="alert-inner--text">
            <strong>Opps!</strong> Something Went Wrong !
          </span>
        </UncontrolledAlert>
      );
    } else {
      return <></>;
    }
  };
  
  
 
 
  AddCategory = () => {
     let info;
     info=this.state.category;
    return (
      <>
        <Container className=" mt--7" fluid>
          {/* Table */}
          <Row>
            <div className=" col">
              <Card className=" shadow">
                <CardHeader className=" bg-transparent">
                  <h3 className=" mb-0">Settings</h3>
                </CardHeader>
                <CardBody>
                <Row className=" d-flex justify-content-center mr-mt-md-2">
                  <Col lg="12">
                      <FormGroup>
                      <label htmlFor="#username">One Signal Api Key</label>
                        <Input
                          type="text"
                          name="name1"
                          defaultValue={info.onesignalApiKey}
                          onChange={this.handleChange}
                        />
                      </FormGroup>
                    </Col>
                    </Row>
                  <Row className=" icon-examples">
                    <Col lg="6" md="4">
                      <FormGroup>
                      <label htmlFor="#username">One Signal Delivery Boy App Id</label>
                        <Input
                         
                          type="text"
                          name="name"
                          defaultValue={info.onesignalAppId}
                          onChange={this.handleChange}
                        />
                      </FormGroup>
                    </Col>
                    <Col lg="6" md="4">
                      <FormGroup>
                      <label htmlFor="#username">One Signal Franchise App Id</label>
                        <Input
                         
                          type="text"
                          name="franchiseAppId"
                          defaultValue={info.onesignalFranchiseAppId}
                          onChange={this.handleChange}
                        />
                      </FormGroup>
                    </Col>
                  </Row>
                  <Row className=" icon-examples">
                    <Col lg="6" md="4">
                      <FormGroup>
                      <label htmlFor="#username">One Signal Seller App Id</label>
                        <Input
                         
                          type="text"
                          name="sellerAppId"
                          defaultValue={info.onesignalSellerAppId}
                          onChange={this.handleChange}
                        />
                      </FormGroup>
                    </Col>
                    <Col lg="6" md="4">
                      <FormGroup>
                      <label htmlFor="#username">One Signal Customer App Id</label>
                        <Input
                         
                          type="text"
                          name="customerAppId"
                          defaultValue={info.onesignalCustomerAppId}
                          onChange={this.handleChange}
                        />
                      </FormGroup>
                    </Col>
                  </Row>
                  <Row className=" d-flex justify-content-center mr-mt-md-4">
                    <Col lg="3">
                      <Button
                        className="btn-icon btn-2"
                        color="info"
                        type="button"
                        block
                        onClick={this.PatchCategoryEdit}
                      >
                        <span className="btn-inner--icon">
                        
                        </span>
                        <span className="btn-inner--text">Update</span>
                      </Button>
                    </Col>
                  </Row>
                </CardBody>
              </Card>
            </div>
          </Row>
          
        </Container>
      </>
    );
  };

  
 

 



  
  render() {
   
   
    return (
      <>
       
       <Header2 />
        {/* Page content */}
        <this.AddCategory />
        <Container className="mt-6" fluid>
       
        </Container>
       
        <this.Alert />
       
      
      </>
    );
  }
}

export default Settings;
