import React from "react";
import { Redirect } from "react-router-dom";
// reactstrap components
import {
  Button,
  Card,
  CardHeader,
  CardBody,
  FormGroup,
  Form,
  Input,
  Container,
  Row,
  Col,
  Label,
  CustomInput,
  UncontrolledAlert,
  Progress,
} from "reactstrap";
// core components
import Header2 from "../../../components/Headers/Header2";

import Cookie from "js-cookie";
const axios = require("axios").default;
const URL = require("../../ApiUrl");

const style = {
  color: "red",
};

class AddFranchise extends React.Component {
  state = {
    name: "",
    succsess: false,
    error: false,
    isRedirect: false,
    franchiseName: "",
    email: "",
    countryId: "",
    stateId: "",
    districtId: "",
    cityId: "",
    address: "",
    profileImageId: "",
    gstNo: "",
    panNo: "",
    idProof: "",
    nameInBank: "",
    accountType: "",
    accountNo: "",
    ifsc: "",
    mobile: "",
    commission: "",
    isLoaded: false,
    countryData: [],
    stateData: [],
    districtData: [],
    cityData: [],
    validPincodes: [],
    pincode: "",
    items: [],
    allitems: {},
    inputValue: "",
  };

  resetErrorMsg = () => {
    this.setState({
      franchiseNameError: "",
      emailError: "",
      mobileError: "",
      addressError: "",
      countryIdError: "",
      stateIdError: "",
      districtIdError: "",
      cityIdError: "",
      pincodeError: "",
      accountNoError: "",
      accountTypeError: "",
      nameInBankError: "",
      ifscError: "",
      panNoError: "",
      gstNoError: "",
      commissionError: "",
      idProofError: "",
      profileImageIdError: "",
    });
  };
  AddToItems = (e) => {
    this.resetErrorMsg();
    let allitems = this.state.allitems;
    let isValid = true;
    // console.log(this.state.franchiseName,'🎈🎈🎈🎈🎈')
    if (this.state.franchiseName === "") {
      isValid = false;
      this.setState({
        franchiseNameError: "*Franchise Name is required",
      });

      console.log(this.state.franchiseName, "franchiseName error");
    }
    if (this.state.email === "") {
      isValid = false;
      this.setState({
        emailError: "*Email is required",
      });
      console.log(this.state.email, "email error");
    }
    if (this.state.mobile === "") {
      isValid = false;
      this.setState({
        mobileError: "*Phone Number is required",
      });
      console.log(this.state.mobile, "mobile error");
    }
    if (this.state.address === "") {
      isValid = false;
      this.setState({
        addressError: "*Address is required",
      });
      console.log(this.state.address, "address error");
    }
    if (this.state.countryId === "") {
      isValid = false;
      this.setState({
        countryIdError: "*Country is required",
      });
      console.log(this.state.countryId, "country error");
    }
    if (this.state.stateId === "") {
      isValid = false;
      this.setState({
        stateIdError: "*State is required",
      });
      console.log(this.state.stateId, "state error");
    }
    if (this.state.districtId === "") {
      isValid = false;
      this.setState({
        districtIdError: "*District is required",
      });
      console.log(this.state.districtId, "district error");
    }
    if (this.state.cityId === "") {
      isValid = false;
      this.setState({
        cityIdError: "*City is required",
      });
      console.log(this.state.cityId, "cityId error");
    }
    if (this.state.pincode === "") {
      isValid = false;
      this.setState({
        pincodeError: "*Postal Code is required",
      });
      console.log(this.state.pincode, "pincode error");
    }
    if (this.state.accountNo === "") {
      isValid = false;
      this.setState({
        accountNoError: "*Account Number is required",
      });
      console.log(this.state.accountNo, "pincode error");
    }
    if (this.state.accountType === "") {
      isValid = false;
      this.setState({
        accountTypeError: "*Account Type is required",
      });
      console.log(this.state.accountType, "accountType error");
    }
    if (this.state.nameInBank === "") {
      isValid = false;
      this.setState({
        nameInBankError: "*Name In Bank is required",
      });
      console.log(this.state.nameInBank, "nameInBank error");
    }
    if (this.state.ifsc === "") {
      isValid = false;
      this.setState({
        ifscError: "*IFSC is required",
      });
      console.log(this.state.ifsc, "ifsc error");
    }
    if (this.state.panNo === "") {
      isValid = false;
      this.setState({
        panNoError: "*Pan Number is required",
      });
      console.log(this.state.panNo, "panNo error");
    }
    if (this.state.gstNo === "") {
      isValid = false;
      this.setState({
        gstNoError: "*GST Number is required",
      });
      console.log(this.state.gstNo, "gstNo error");
    }
    if (this.state.commission === "") {
      isValid = false;
      this.setState({
        commissionError: "*Commission is required",
      });
      console.log(this.state.commission, "commission error");
    }
    if (this.state.idProof === "") {
      isValid = false;
      this.setState({
        idProofError: "*Id Proof is required",
      });
      console.log(this.state.idProof, "idProof error");
    }

    if (this.state.profileImageId === "") {
      isValid = false;
      this.setState({
        profileImageIdError: "*Profile Image Id is required",
      });
      console.log(this.state.profileImageId, "profileImageId error");
    }
    if (!isValid) {
      return;
    } else {
      var obj = {
        name: this.state.name,
        franchiseName: this.state.franchiseName,
        email: this.state.email,
        countryId: this.state.countryId,
        stateId: this.state.stateId,
        districtId: this.state.districtId,
        cityId: this.state.cityId,
        address: this.state.address,
        pincode: this.state.pincode,
        profileImageId: this.state.profileImageId,
        gstNo: this.state.gstNo,
        panNo: this.state.panNo,
        idProof: this.state.idProof,
        nameInBank: this.state.nameInBank,
        accountType: this.state.accountType,
        accountNo: this.state.accountNo,
        ifsc: this.state.ifsc,
        mobile: this.state.mobile,
        countryData: this.state.countryData,
        stateData: this.state.stateData,
        districtData: this.state.districtData,
        cityData: this.state.districtData,
        validPincodes: this.state.validPincodes,
        commission: this.state.commission,
      };
      console.log(obj,"🎁🎁🎁🎁🎁🎁")
      this.PostFranchise(obj);
    } 
  };

  PostFranchise = async (data) => {
    const token = Cookie.get("patrickToken")
      ? Cookie.get("patrickToken")
      : null;
    const config = {
      headers: { Authorization: `Bearer ${token}` },
    };
    // console.log(config);
    console.log(data, "🧧🧧🧧🧧🧧");
    // return;
    let response = await axios
      .post(`${URL.BASE_URL}/admin-franchises/create`, data, config)
      .catch(function (error) {
        console.log(error);
      });
    console.log(data);
    if (response && response.data.statusCode === 201) {
      this.setState({
        succsess: true,
        isRedirect: true,
      });
    } else if (response && response.data.statusCode !== 201) {
      this.setState({
        error: true,
        errorCode: response.data.msg,
      });
    } else {
      this.setState({
        error: true,
      });
    }
    console.log(response);
  };
  PostState = async (e) => {
    e.preventDefault();
    const token = Cookie.get("patrickToken")
      ? Cookie.get("patrickToken")
      : null;
    const config = {
      headers: { Authorization: `Bearer ${token}` },
    };
    console.log(config);

    let data = {
      name: this.state.name,
      countryId: "60266020794814098914c874",
    };

    let response = await axios
      .post(`${URL.BASE_URL}/masters/countries/addstate`, data, config)
      .catch(function (error) {
        console.log(error);
      });

    if (response && response.data.statusCode === 200) {
      this.setState({
        succsess: true,
      });
    } else if (response && response.data.statusCode !== 200) {
      this.setState({
        error: true,
        errorCode: response.data.msg,
      });
    } else {
      this.setState({
        error: true,
        errorCode: "Oops something went wrong!!",
      });
    }
    this.GetLocation();
    setTimeout(() => {
      this.setState({
        status: false,
        error: false,
      });
    }, 2000);

    console.log(response);
  };
  PostDistrict = async () => {
    const token = Cookie.get("patrickToken")
      ? Cookie.get("patrickToken")
      : null;
    const config = {
      headers: { Authorization: `Bearer ${token}` },
    };
    console.log(config);

    let data = {
      name: this.state.name,
      countryId: "60266020794814098914c874",
      stateId: this.state.stateId,
    };

    let response = await axios
      .post(`${URL.BASE_URL}/masters/countries/adddist`, data, config)
      .catch(function (error) {
        console.log(error);
      });

    if (response && response.data.statusCode === 200) {
      this.setState({
        succsess: true,
      });
    } else if (response && response.data.statusCode !== 200) {
      this.setState({
        error: true,
        errorCode: response.data.msg,
      });
    } else {
      this.setState({
        error: true,
        errorCode: "Oops something went wrong!!",
      });
    }
    this.GetLocation();
    setTimeout(() => {
      this.setState({
        status: false,
        error: false,
      });
    }, 2000);

    console.log(response);
  };
  PostCity = async (e) => {
    e.preventDefault();
    const token = Cookie.get("patrickToken")
      ? Cookie.get("patrickToken")
      : null;
    const config = {
      headers: { Authorization: `Bearer ${token}` },
    };
    console.log(config);

    let data = {
      name: this.state.name,

      countryId: "60266020794814098914c874",
      stateId: this.state.stateId,
      districtId: this.state.districtId,
    };

    let response = await axios
      .post(`${URL.BASE_URL}/masters/countries/addcity`, data, config)
      .catch(function (error) {
        console.log(error);
      });

    if (response && response.data.statusCode === 200) {
      this.setState({
        succsess: true,
      });
    } else if (response && response.data.statusCode !== 200) {
      this.setState({
        error: true,
        errorCode: response.data.msg,
      });
    } else {
      this.setState({
        error: true,
        errorCode: "Oops something went wrong!!",
      });
    }
    this.GetLocation();
    setTimeout(() => {
      this.setState({
        status: false,
        error: false,
      });
    }, 2000);

    console.log(response);
  };
  GetLocation = async (name, val) => {
    console.log(name, val);
    let countryResponse, stateResponse, districtResponse, cityResponse;

    countryResponse = await axios
      .get(`${URL.BASE_URL}/masters/countries/list`)
      .catch(function (error) {
        console.log(error);
      });

    if (countryResponse && countryResponse.data !== null) {
      this.setState({
        countryData: countryResponse.data.data,
      });
    } else if (countryResponse && countryResponse.data.statusCode !== 200) {
      this.setState({
        error: true,
        errorCode: countryResponse.data.msg,
      });
    }

    if (name === "countryId") {
      stateResponse = await axios
        .get(`${URL.BASE_URL}/masters/states/list?countryId=${val}`)
        .catch(function (error) {
          console.log(error);
        });

      if (stateResponse && stateResponse.data !== null) {
        this.setState({
          stateData: stateResponse.data.data,
        });
      } else if (stateResponse && stateResponse.data.statusCode !== 200) {
        this.setState({
          error: true,
          errorCode: stateResponse.data.msg,
        });
      }
      console.log("stat++++++ ", stateResponse);
    }

    if (name === "stateId") {
      districtResponse = await axios
        .get(
          `${URL.BASE_URL}/masters/districts/list?countryId=${this.state.countryId}&stateId=${val}`
        )
        .catch(function (error) {
          console.log(error);
        });

      if (districtResponse && districtResponse.data !== null) {
        this.setState({
          districtData: districtResponse.data.data,
        });
      } else if (districtResponse && districtResponse.data.statusCode !== 200) {
        this.setState({
          error: true,
          errorCode: districtResponse.data.msg,
        });
      }
      console.log(districtResponse);
    }

    if (name === "districtId") {
      cityResponse = await axios
        .get(
          `${URL.BASE_URL}/masters/cities/list?countryId=${val}&stateId=${val}&districtId=${val}`
        )
        .catch(function (error) {
          console.log(error);
        });

      if (cityResponse && cityResponse.data !== null) {
        this.setState({
          cityData: cityResponse.data.data,
        });
      } else if (cityResponse && cityResponse.data.statusCode !== 200) {
        this.setState({
          error: true,
          errorCode: cityResponse.data.msg,
        });
      } else {
        this.setState({
          status: false,
        });
      }
      console.log(cityResponse);
    }
  };

  onFileChangeProfile = async (e) => {
    this.setState({ isLoaded: true });

    const formData = new FormData();

    const token = Cookie.get("patrickToken")
      ? Cookie.get("patrickToken")
      : null;
    const config = {
      headers: { Authorization: `Bearer ${token}` },
    };

    formData.append("file", e.target.files[0]);
    formData.append("type", "franchise_profile_image");
    axios.post(`${URL.BASE_URL}/mediaupload`, formData, config).then((res) => {
      console.log(res);
      this.setState({ profileImageId: res.data.data._id });
      console.log(res.data.data);
      if (res && res.data.statusCode === 200) {
        this.setState({
          isLoaded: false,
        });
      } else if (res && res.data.statusCode !== 200) {
        this.setState({
          error: true,
          errorCode: res.data.msg,
        });
      } else {
        this.setState({
          error: true,
        });
      }
    });
  };

  onFileChangeId = async (e) => {
    const formData = new FormData();
    this.setState({ isLoaded: true });

    const token = Cookie.get("patrickToken")
      ? Cookie.get("patrickToken")
      : null;
    const config = {
      headers: { Authorization: `Bearer ${token}` },
    };
    formData.append("file", e.target.files[0]);
    formData.append("type", "id_proof");
    axios.post(`${URL.BASE_URL}/mediaupload`, formData, config).then((res) => {
      console.log(res);
      this.setState({ idProof: res.data.data._id });
      if (res && res.data.statusCode === 200) {
        this.setState({
          isLoaded: false,
        });
      } else if (res && res.data.statusCode !== 200) {
        this.setState({
          error: true,
          errorCode: res.data.msg,
        });
      } else {
        this.setState({
          error: true,
        });
      }
    });
  };

  handleChange = (e) => {
    if (
      e.target.name === "countryId" ||
      "stateId" ||
      "districtId" ||
      "cityId"
    ) {
      this.GetLocation(e.target.name, e.target.value);
    }

    this.setState({
      [e.target.name]: e.target.value,
    });
  };
  componentDidMount = () => {
    this.GetLocation();
  };
  Proof = () => {
    if (this.state.isLoaded) {
      return (
        <>
          <Progress animated color="success" value="100" className="mt-2" />
        </>
      );
    } else {
      return <></>;
    }
  };
  CheckPincode = async () => {
    const token = Cookie.get("patrickToken")
      ? Cookie.get("patrickToken")
      : null;
    const config = {
      headers: { Authorization: `Bearer ${token}` },
    };
    let pincode = this.state.inputValue;
    let response = await axios
      .get(
        `${URL.BASE_URL}/admin-franchises/pincode/check?pincode=${pincode}`,
        config
      )
      .catch(function (error) {
        console.log(error);
      });
    console.log("👇👇👇👇👇👇👇👇👇👇");
    console.log(response);
    if (response && response.data !== null) {
      this.setState({
        pincode: response.data,
      });
    } else if (response && response.data.statusCode !== 200) {
      this.setState({
        error: true,
        errorCode: response.data.msg,
      });
    } else {
      this.setState({
        status: false,
      });
    }
  };

  maxLengthCheck = (object) => {
    if (object.target.value.length > object.target.maxLength) {
      object.target.value = object.target.value.slice(
        0,
        object.target.maxLength
      );
    }
  };

  onInputChange(e) {
    this.setState({
      inputValue: e.target.value,
    });
  }

  addNewItem = () => {
    let items = this.state.items;
    items.push(this.state.inputValue);
    this.setState({
      items,
    });
    items.map((val, index) => {
      console.log(val);
    });
  };

  listItems() {
    let items = this.state.items;
    return (
      <tbody>
        {items.map((val, index) => {
          return <td key={index}>{val},</td>;
        })}
      </tbody>
    );
  }

  Form = () => {
    return (
      <>
        <Form>
          <h6 className="heading-small text-muted mb-4">Seller information</h6>
          <div className="pl-lg-4">
            <Row>
              <Col lg="6">
                <FormGroup>
                  <label
                    className="form-control-label"
                    htmlFor="input-username"
                  >
                    Franchise name
                  </label>
                  <Input
                    className="form-control-alternative"
                    id="input-username"
                    placeholder="Name"
                    type="text"
                    name="franchiseName"
                    onChange={this.handleChange}
                  />
                  <h4 style={style}>{this.state.franchiseNameError}</h4>
                </FormGroup>
              </Col>

              <Col lg="6">
                <FormGroup>
                  <label className="form-control-label" htmlFor="input-email">
                    Phone Number
                  </label>
                  <Input
                    className="form-control-alternative"
                    id="input-email"
                    placeholder="Number"
                    type="number"
                    name="mobile"
                    maxLength="10"
                    onInput={this.maxLengthCheck}
                    onChange={this.handleChange}
                  />
                  <h4 style={style}>{this.state.mobileError}</h4>
                </FormGroup>
              </Col>
              <Col lg="12">
                <FormGroup>
                  <label className="form-control-label" htmlFor="input-email">
                    Email address
                  </label>
                  <Input
                    className="form-control-alternative"
                    id="input-email"
                    placeholder="abcd@example.com"
                    type="email"
                    name="email"
                    onChange={this.handleChange}
                  />
                  <h4 style={style}>{this.state.emailError}</h4>
                </FormGroup>
              </Col>
              {/* <Col lg="6">
                <FormGroup>
                  <label className="form-control-label">
                    Delivery Area Pincode
                  </label>
                  <Input
                    className="form-control-alternative"
                    placeholder="Number"
                    type="number"
                    name="validPincodes"
                    maxLength="6"
                    onInput={this.maxLengthCheck}
                    onChange={this.handleChange}
                    onChange={(e) => this.onInputChange(e)}
                  />
                </FormGroup>
              </Col> */}
            </Row>
          </div>
          <hr className="my-4" />
          {/* Address */}
          <h6 className="heading-small text-muted mb-4">Contact information</h6>
          <div className="pl-lg-4">
            <Row>
              <Col md="12">
                <FormGroup>
                  <label className="form-control-label" htmlFor="input-address">
                    Address
                  </label>
                  <Input
                    className="form-control-alternative"
                    id="input-address"
                    placeholder="Home Address"
                    type="text"
                    name="address"
                    onChange={this.handleChange}
                  />
                  <h4 style={style}>{this.state.addressError}</h4>
                  
                </FormGroup>
              </Col>
            </Row>
            <Row>
              <Col lg="4">
                <FormGroup>
                  <Label>Country</Label>
                  <CustomInput
                    type="select"
                    name="countryId"
                    onChange={this.handleChange}
                  >
                    <option value="">Select</option>;
                    {this.state.countryData.map((data, key) => {
                      return <option value={data.id}>{data.name}</option>;
                    })}
                  </CustomInput>
                  <h4 style={style}>{this.state.countryIdError}</h4>
                </FormGroup>
              </Col>

              <Col lg="4">
                <FormGroup>
                  <Label>State</Label>
                  <CustomInput
                    type="select"
                    name="stateId"
                    onChange={this.handleChange}
                  >
                    <option value="">Select</option>;
                    {this.state.stateData &&
                      this.state.stateData.map((data, key) => {
                        return <option value={data.id}>{data.name}</option>;
                      })}
                  </CustomInput>
                  <h4 style={style}>{this.state.stateIdError}</h4>
                </FormGroup>
              </Col>
              <Col lg="4">
                <FormGroup>
                  <Label>District</Label>
                  <CustomInput
                    type="select"
                    name="districtId"
                    onChange={this.handleChange}
                  >
                    <option value="">Select</option>;
                    {this.state.districtData &&
                      this.state.districtData.map((data, key) => {
                        return <option value={data.id}>{data.name}</option>;
                      })}
                  </CustomInput>
                  <h4 style={style}>{this.state.districtIdError}</h4>
                </FormGroup>
              </Col>
            </Row>
            <Row>
              <Col lg="6">
                <FormGroup>
                  <Label>City</Label>
                  <CustomInput
                    type="select"
                    name="cityId"
                    onChange={this.handleChange}
                    // onClick={this.PostCit}
                  >
                    <option value="">Select</option>;
                    {this.state.cityData &&
                      this.state.cityData.map((data, key) => {
                        return <option value={data.id}>{data.name}</option>;
                      })}
                  </CustomInput>
                  <h4 style={style}>{this.state.cityIdError}</h4>
                </FormGroup>
              </Col>
              <Col lg="6">
                <FormGroup>
                  <label className="form-control-label">Postal code</label>
                  <Input
                    className="form-control-alternative"
                    placeholder="Number"
                    type="number"
                    name="pincode"
                    maxLength="6"
                    onInput={this.maxLengthCheck}
                    onChange={this.handleChange}
                  />
                  <h4 style={style}>{this.state.pincodeError}</h4>
                </FormGroup>
              </Col>
            </Row>
            <hr className="my-4" />
          </div>
          <h6 className="heading-small text-muted mb-4">
            Bank and ID proof Details
          </h6>
          <div className="pl-lg-4">
            <Row>
              <Col md="6">
                <FormGroup>
                  <label className="form-control-label" htmlFor="input-address">
                    Account number
                  </label>
                  <Input
                    className="form-control-alternative"
                    placeholder="Account Number"
                    type="number"
                    name="accountNo"
                    onChange={this.handleChange}
                  />
                  <h4 style={style}>{this.state.accountNoError}</h4>

                </FormGroup>
              </Col>
              <Col md="6">
                <FormGroup>
                  <label className="form-control-label" htmlFor="input-address">
                    Account type
                  </label>
                  <CustomInput
                    type="select"
                    name="accountType"
                    onChange={this.handleChange}
                  >
                    <option value="">Select Type</option>
                    <option>Current account</option>
                    <option>Savings account</option>
                    <option>Salary account</option>
                    <option>Fixed deposit account</option>
                    <option>Recurring deposit account</option>
                    <option>NRI accounts</option>
                  </CustomInput>
                  <h4 style={style}>{this.state.accountTypeError}</h4>
                </FormGroup>
              </Col>
            </Row>
            <Row>
              <Col lg="6">
                <FormGroup>
                  <label className="form-control-label" htmlFor="input-country">
                    Name in bank
                  </label>
                  <Input
                    className="form-control-alternative"
                    placeholder="Name"
                    type="text"
                    name="nameInBank"
                    onChange={this.handleChange}
                  />
                  <h4 style={style}>{this.state.nameInBankError}</h4>

                </FormGroup>
              </Col>
              <Col lg="6">
                <FormGroup>
                  <label className="form-control-label" htmlFor="input-country">
                    IFSC code
                  </label>
                  <Input
                    className="form-control-alternative"
                    placeholder="Code"
                    type="text"
                    name="ifsc"
                    onChange={this.handleChange}
                  />
                  <h4 style={style}>{this.state.ifscError}</h4>

                </FormGroup>
              </Col>
            </Row>
            <Row>
              <Col lg="4">
                <FormGroup>
                  <label className="form-control-label" htmlFor="input-city">
                    PAN number
                  </label>
                  <Input
                    className="form-control-alternative"
                    placeholder="Number"
                    type="text"
                    name="panNo"
                    onChange={this.handleChange}
                  />
                  <h4 style={style}>{this.state.panNoError}</h4>

                </FormGroup>
              </Col>
              <Col lg="4">
                <FormGroup>
                  <label className="form-control-label" htmlFor="input-country">
                    GST number
                  </label>
                  <Input
                    className="form-control-alternative"
                    placeholder="Number"
                    type="text"
                    name="gstNo"
                    onChange={this.handleChange}
                  />
                  <h4 style={style}>{this.state.gstNoError}</h4>

                </FormGroup>
              </Col>
              <Col lg="4">
                <FormGroup>
                  <label className="form-control-label" htmlFor="input-country">
                    Commission
                  </label>
                  <Input
                    className="form-control-alternative"
                    placeholder="Number"
                    type="number"
                    name="commission"
                    onChange={this.handleChange}
                  />
                  <h4 style={style}>{this.state.commissionError}</h4>

                </FormGroup>
              </Col>
            </Row>
            <Row>
              <Col lg="6">
                <FormGroup>
                  <Label for="exampleCustomFileBrowser">Id Proof</Label>
                  <CustomInput
                    type="file"
                    name="idProof"
                    onChange={this.onFileChangeId}
                  />
                  <h4 style={style}>{this.state.idProofError}</h4>

                </FormGroup>
              </Col>
              <Col lg="6">
                <FormGroup>
                  <Label for="exampleCustomFileBrowser">Profile Image</Label>
                  <CustomInput
                    type="file"
                    name="profileImageId"
                    onChange={this.onFileChangeProfile}
                  />
                  <h4 style={style}>{this.state.profileImageIdError}</h4>

                </FormGroup>
              </Col>
            </Row>
          </div>
          <hr className="my-4" />
          <Row className="justify-content-lg-center">
            <Col lg="0">
              <Button
                className=" btn-icon"
                color="info"
                size="md"
                block
                type="button"
                onClick={this.AddToItems}
                // onClick={this.PostFranchise}
              >
                <i class="fas fa-user-plus"></i>
                <span className="btn-inner--text">Add Franchise</span>
              </Button>
            </Col>
          </Row>
        </Form>
      </>
    );
  };
  Alert = () => {
    if (this.state.succsess) {
      return (
        <UncontrolledAlert color="success" fade={false} className="mt-1">
          <span className="alert-inner--icon">
            <i className="ni ni-like-2" />
          </span>{" "}
          <span className="alert-inner--text">
            <strong>Success!</strong> Franchise Added Successfully
          </span>
        </UncontrolledAlert>
      );
    } else if (this.state.error) {
      return (
        <UncontrolledAlert color="danger" fade={false} className="mt-1">
          <span className="alert-inner--icon"></span>{" "}
          <span className="alert-inner--text">
            <strong>{this.state.errorCode}</strong>
          </span>
        </UncontrolledAlert>
      );
    } else {
      return <></>;
    }
  };
  render() {
    if (this.state.isRedirect) {
      return <Redirect to="/admin/franchise-list" />;
    }
    return (
      <>
        <Header2 />
        {/* Page content */}
        <Container className="mt--7" fluid>
          <Row>
            <Col className="order-xl-1" xl="12">
              <Card className="bg-secondary shadow">
                <CardHeader className="bg-white border-0">
                  <Row className="align-items-center">
                    <Col xs="8">
                      <h3 className="mb-0">Franchise</h3>
                    </Col>
                  </Row>
                </CardHeader>
                <CardBody>
                  <this.Form />
                </CardBody>
              </Card>
              <this.Proof />
            </Col>
          </Row>
          <this.Alert />
        </Container>
      </>
    );
  }
}

export default AddFranchise;
