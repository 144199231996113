import React from "react";
import { Spinner } from "reactstrap";

// reactstrap components
import {
  Card,
  CardHeader,
  FormGroup,
  Container,
  Row,
  Col,
  Table,
  Input,
  InputGroup,
  InputGroupAddon,
  InputGroupText,
} from "reactstrap";
// core components
import Cookie from "js-cookie";
import Header2 from "components/Headers/Header2";

const axios = require("axios").default;
const URL = require("../../ApiUrl");

class OrderDetails extends React.Component {
  constructor() {
    super();
    this.state = { checked: false, isLoaded: false };
    this.handleChange = this.handleChange.bind(this);
    this.handleChangeSwitch = this.handleChangeSwitch.bind(this);
    this.GetOrderDetails = this.GetOrderDetails.bind(this);
    this.CustomerOrderInformation = this.CustomerOrderInformation.bind(this);
    this.CustemerOrderDetails = this.CustemerOrderDetails.bind(this);
    this.OrderDetails = this.OrderDetails.bind(this);
  }

  async GetOrderDetails() {
    const token = Cookie.get("patrickToken")
      ? Cookie.get("patrickToken")
      : null;
    const config = {
      headers: { Authorization: `Bearer ${token}` },
    };
    let id = this.props.match.params.id;
    let response = await axios
      .get(`${URL.BASE_URL}/admin-orders/${id}/detail`, config)
      .catch(function (error) {
        console.log(error);
      });

    console.log("👇👇👇👇👇👇👇👇👇👇");
    console.log(response);

    if (response && response.data !== null) {
      this.setState({
        orderdetails: response.data.data,
        isLoaded: true,
      });
    } else if (response && response.data.statusCode !== 200) {
      this.setState({
        error: true,
        errorCode: response.data.msg,
      });
    } else {
      this.setState({
        status: false,
      });
    }
  }

  handleChange(e) {
    this.setState({
      [e.target.data]: e.target.value,
    });
  }

  componentDidMount() {
    this.GetOrderDetails();
  }

  handleChangeSwitch(checked) {
    this.setState({ checked });
  }

  CustomerOrderInformation() {
    let information;
    if (this.state.orderdetails) {
      information = this.state.orderdetails;
    }

    let d = new Date(information.createdAt);

    let date = d.getDate();
    let month = d.getMonth() + 1;
    let year = d.getFullYear();

    let dateStr = date + "/" + month + "/" + year;

    return (
      <>
        <h6 className="heading-small text-muted mb-4">Customer information</h6>
        <div className="pl-lg-">
          <Row>
            <Col lg="6">
              <FormGroup>
                <label className="form-control-label">First name</label>
                <Input
                  className="form-control-alternative"
                  type="text"
                  disabled
                  value={information.user?information.user.firstName:information.user.customerName}
                />
              </FormGroup>
            </Col>
            <Col lg="6">
              <FormGroup>
                <label className="form-control-label">Last name</label>
                <Input
                  className="form-control-alternative"
                  type="text"
                  disabled
                  value={information.user?information.user.lastName:'--'}
                />
              </FormGroup>
            </Col>
          </Row>
          <Row>
            <Col lg="6">
              <FormGroup>
                <label className="form-control-label">Email address</label>
                <Input
                  className="form-control-alternative"
                  type="email"
                  disabled
                  value={information.user?information.user.email:information.customerEmail}
                />
              </FormGroup>
            </Col>
            <Col lg="6">
              <FormGroup>
                <label className="form-control-label">Mobile Number</label>
                <Input
                  className="form-control-alternative"
                  type="text"
                  disabled
                  value={information.user?information.user.mobile:information.customerPhone}
                />
              </FormGroup>
            </Col>
          </Row>
          <Row>
            <Col lg="6">
              <FormGroup>
                <label className="form-control-label">Date of Purchase</label>
                <Input
                  className="form-control-alternative"
                  type="text"
                  disabled
                  value={dateStr}
                />
              </FormGroup>
            </Col>
            {/* <Col lg="6">
              <FormGroup>
                <label className="form-control-label">Date of Purchase</label>
                <Input
                  className="form-control-alternative"
                  type="text"
                  disabled
                  value={dateStr}
                />
              </FormGroup>
            </Col> */}
          </Row>
        </div>
      </>
    );
  }
  CustemerOrderDetails() {
    if (this.state.isLoaded) {
      return (
        <>
          <thead className="thead-light">
            <tr>
              <th scope="col">#</th>
              <th scope="col">Product Name</th>
              <th scope="col">Quantity</th>
              <th scope="col">Seller Name</th>
              {/* <th scope="col">Admin Commission</th>
              <th scope="col">Franchise Commission</th> */}
              <th scope="col">Price</th>
              <th scope="col">Order status</th>
            </tr>
          </thead>
          <tbody>
            {this.state.orderdetails.cartItems.map((data, key) => {
              return (
                <tr>
                  <td>{key + 1}</td>
                  <td> {data.product.name}</td>

                  <td>{data.quantity}</td>
                  <td>{data.sellerId.sellerName}</td>
                  {/* <td>{data.adminCommission}</td>
                  <td>{data.franchiseCommission}</td> */}
                  <td>₹{data.price}</td>
                  <td>{data.orderStatus}</td>
                </tr>
              );
            })}
          </tbody>
        </>
      );
    } else {
      return (
        <div className=" d-flex justify-content-center ">
          <Spinner
            style={{ width: "3rem", height: "3rem" }}
            type="grow"
            color="warning"
          />
        </div>
      );
    }
  }

  OrderDetails() {
    if (this.state.isLoaded) {
      let total = this.state.orderdetails;
      console.log(total.itemTotal, "🤩🤩🤩🤩🤩🤩🤩");
      console.log(total.grandTotal, "🤩🤩🤩🤩🤩🤩🤩");
      return (
        <>
          <form>
            <this.CustomerOrderInformation />

            <h6 className="heading-small text-muted mb-4">
              Customer Cart Details
            </h6>
            <div className="pl-lg-">
              <Row>
                <Table className="align-items-center table-flush" responsive>
                  {" "}
                  <this.CustemerOrderDetails />
                </Table>
              </Row>
              <Row className="align-items-end">
                <Col>
                  <h3 className="mb-0"></h3>
                </Col>
                <Col>
                  <h3 className="mb-0"></h3>
                </Col>
                <Col>
                  <h3 className="mt-3 mb-3 ml-8">
                    Items Total : ₹ {total.itemTotal}
                  </h3>
                  <h3 className="mt-3 mb-3 ml-8">
                    Grand Total : ₹ {total.grandTotal}
                  </h3>
                </Col>
              </Row>
            </div>
          </form>
        </>
      );
    } else {
      return (
        <>
          <Spinner
            color="warning"
            style={{ width: "4rem", height: "4rem", marginLeft: "45%" }}
          />{" "}
        </>
      );
    }
  }

  render() {
    return (
      <>
        <Header2 />
        <Container className=" mt--7" fluid>
          {/* Table */}
          <Row>
            <div className="col">
              <Card className="bg-secondary shadow">
                <CardHeader className=" border-0">
                  <Row>
                    <Col>
                      <this.OrderDetails />
                    </Col>
                  </Row>
                </CardHeader>
              </Card>
            </div>
          </Row>
        </Container>
      </>
    );
  }
}

export default OrderDetails;
