import React, { Component } from "react";
import { Link } from "react-router-dom";
import {
  Card,
  CardHeader,
  Container,
  Row,
  Col,
  CardBody,
  FormGroup,
  Input,
  Button,
  CustomInput,
  UncontrolledAlert,
  Table,
  Spinner,
  CardFooter,
} from "reactstrap";
import Cookie from "js-cookie";
import Header2 from "components/Headers/Header2";

const axios = require("axios").default;
const URL = require("../../ApiUrl");
const style = {
  color: "red",
};
export default class Silders extends Component {
  constructor(props) {
    super(props);
    this.PostSlider = this.PostSlider.bind(this);

    this.state = {
      title: "",
      type: "",
      mainImage: "",
      succsess: false,
      error: false,
      productdetails: [],
      sellerdetails: [],
      categories: [],
      category: "",
      productId: "",
      sellerId: "",
      isLoaded: false,
      selectedOption: null,
      seller: {},
      orginalData: [],
      isRedirect : false
    };
  }

  GetLocation = async (name, value) => {
    console.log(name, value, "///////////////////////////");
    const token = Cookie.get("patrickToken")
      ? Cookie.get("patrickToken")
      : null;
    const config = {
      headers: { Authorization: `Bearer ${token}` },
    };

    let response;
    response = await axios
      .get(`${URL.BASE_URL}/admin-products/seller/list/names`, config)
      .catch(function (error) {
        console.log(error);
      });

    console.log("👇👇👇👇👇👇👇👇👇👇");
    console.log(response);

    if (response && response.data !== null) {
      this.setState({
        sellerdetails: response.data.data,
      });
    } else if (response && response.data.statusCode !== 200) {
      this.setState({
        error: true,
        errorCode: response.data.msg,
      });
    }

    if (name === "sellerId") {
      let response1 = await axios
        .get(
          `${URL.BASE_URL}/admin-products/list/names?sellerId=${value}`,
          config
        )
        .catch(function (error) {
          console.log(error);
        });

      console.log("👇👇👇👇👇👇👇👇👇👇");
      console.log(response1);

      if (response1 && response1.data !== null) {
        this.setState({
          productdetails: response1.data.data,
        });
      } else if (response1 && response1.data.statusCode !== 200) {
        this.setState({
          error: true,
          errorCode: response1.data.msg,
        });
      }
    }
  };
  GetProducts = async () => {
    const token = Cookie.get("patrickToken")
      ? Cookie.get("patrickToken")
      : null;
    const config = {
      headers: { Authorization: `Bearer ${token}` },
    };

    let response;
    response = await axios
      .get(
        `${URL.BASE_URL}/admin-products/sliders/list?page=1&limit=20`,
        config
      )
      .catch(function (error) {
        console.log(error);
      });

    console.log("👇👇👇👇👇👇👇👇👇👇");
    console.log(response);

    if (response && response.data !== null) {
      this.setState({
        seller: response.data.data,
        orginalData: response.data.data,
        isLoaded: true,
      });
    } else if (response && response.data.statusCode !== 200) {
      this.setState({
        error: true,
        errorCode: response.data.msg,
      });
    }
  };

  resetErrorMsg = () => {
    this.setState({
      titleError: "",
      typeError: "",
      categoryError: "",
      mainImageError: "",
      sellerIdError: "",
      productIdError: "",
      sellerId2Error: "",
    });
  };
  AddToItems = (e) => {
    this.resetErrorMsg();
    let isValid = true;
    if (this.state.title === "") {
      isValid = false;
      this.setState({
        titleError: "*Title is required",
      });

      console.log(this.state.title, "title error");
    }
    if (this.state.type === "") {
      isValid = false;
      this.setState({
        typeError: "*Type is required",
      });

      console.log(this.state.type, "type error");
    }
    if (this.state.category === "") {
      isValid = false;
      this.setState({
        categoryError: "*Category is required",
      });

      console.log(this.state.category, "category error");
    }
    if (this.state.mainImage === "") {
      isValid = false;
      this.setState({
        mainImageError: "*Main Image is required",
      });

      console.log(this.state.mainImage, "mainImage error");
    }
    if (this.state.sellerId === "" && this.state.category === "seller") {
      isValid = false;
      this.setState({
        sellerIdError: "*Seller is required",
      });

      console.log(this.state.sellerId, "sellerId error");
    }
    if (this.state.productId === "" && this.state.category === "product") {
      isValid = false;
      this.setState({
        productIdError: "*Product is required",
      });

      console.log(this.state.productId, "productId error");
    }
    if (this.state.sellerId === "" && this.state.category === "product") {
      isValid = false;
      this.setState({
        sellerI2dError: "*Seller is required",
      });

      console.log(this.state.sellerId, "sellerId 2 error");
    }

    if (!isValid) {
      return;
    } else {
      var obj = {
        title: this.state.title,
        // product: [this.state.productId],
        mainImage: this.state.mainImage,
        type: this.state.type,
        sellerId: this.state.sellerId,
        category: this.state.category,
      };
      if(this.state.productId){
        obj.product = [this.state.productId];
      }
      if(this.state.categoryId){
        obj.categoryId = this.state.categoryId;
      }
      console.log(obj, "🎁🎁🎁🎁🎁🎁");
      this.PostSlider(obj);
    }
  };

  PostSlider = async (data) => {
    const token = Cookie.get("patrickToken")
      ? Cookie.get("patrickToken")
      : null;
    const config = {
      headers: { Authorization: `Bearer ${token}` },
    };
    //console.log(config);
    console.log(data, "//////////////////////");
    this.setState({
      isLoaded: true,
    });
    let response = await axios
      .post(`${URL.BASE_URL}/admin-products/sliders/add`, data, config)
      .catch(function (error) {
        console.log(error);
      });

    if (response && response.data.statusCode === 200) {
    this.resetErrorMsg();
      this.setState({
        succsess: true,
        isLoaded: false,
        isRedirect : true
      });
    } else if (response && response.data.statusCode !== 200) {
      this.setState({
        error: true,
        errorCode: response.data.msg,
        isLoaded: false,
      });
    } else {
      this.setState({
        error: true,
        errorCode: "Oops something went wrong!!",
        isLoaded: false,
      });
    }
    this.GetLocation();
    this.GetProducts();

    setTimeout(() => {
      this.setState({
        status: false,
        error: false,
      });
    }, 2000);

    console.log(response);
  }
  async clearForm(){
    this.setState({
      title: "",
      type: "",
      mainImage: "",
      succsess: false,
      error: false,
      productdetails: [],
      sellerdetails: [],
      categories: [],
      category: "",
      productId: "",
      sellerId: "",
      isLoaded: false,
      selectedOption: null,
      seller: {},
      orginalData: [],
    });
  }
  async GetCategory() {
    const token = Cookie.get("patrickToken")
      ? Cookie.get("patrickToken")
      : null;
    const config = {
      headers: { Authorization: `Bearer ${token}` },
    };

    let response = await axios
      .get(`${URL.BASE_URL}/admin-products/category/get`, config)
      .catch(function (error) {
        console.log(error);
      });
    console.log("👇👇👇👇👇👇👇👇👇👇");
    console.log(response);
    if (response && response.data !== null) {
      this.setState({
        categories: response.data,
      });
    } else if (response && response.data.statusCode !== 200) {
      this.setState({
        error: true,
        errorCode: response.data.msg,
      });
    } else {
      this.setState({
        status: false,
      });
    }
  }

  onFileChangeProfile = async (e) => {
    this.setState({ isLoaded: true });

    const formData = new FormData();

    const token = Cookie.get("patrickToken")
      ? Cookie.get("patrickToken")
      : null;
    const config = {
      headers: { Authorization: `Bearer ${token}` },
    };

    formData.append("file", e.target.files[0]);
    formData.append("type", this.state.type);
    axios.post(`${URL.BASE_URL}/mediaupload`, formData, config).then((res) => {
      console.log(res);
      this.setState({ mainImage: res.data.data._id });
      console.log(res.data.data);
      if (res && res.data.statusCode === 200) {
        this.setState({
          isLoaded: false,
        });
      } else if (res && res.data.statusCode !== 200) {
        this.setState({
          error: true,
          errorCode: res.data.msg,
          isLoaded: false,

        });
      } else {
        this.setState({
          error: true,
          isLoaded: false,
        });
      }
    });
  };

  handleChange = (e) => {
    

    if (e.target.name === "sellerId") {
      this.GetLocation(e.target.name, e.target.value);
    }
    if (e.target.name === "category") {
      this.GetCategory();
    }


    this.setState({
      [e.target.name]: e.target.value,
    });

    console.log(e.target.value, "/////////");
  };

  componentDidMount() {
    this.GetLocation();
    this.GetProducts();
  }

  ProductList = () => {
    if (this.state.isLoaded) {
      if (this.state.isRedirect) {
        return window.location.reload(false);
      }
      return (
        <>
          <thead>
            <tr>
              <th className=" text-center">#</th>
              <th>Main Image</th>

              <th>Title</th>

              <th>Seller Name</th>
              <th>Product Name</th>
              <th>Category Name</th>

              <th>Actions</th>
            </tr>
          </thead>
          <tbody>
            {this.state.seller.items.map((data, key) => {
              return (
                <tr>
                  <td className=" text-center">{key + 1}</td>
                  <td>
                    <div className=" img-container">
                      <a href="#pablo">
                        {data.mainImage ? (
                          <img
                            alt="Loading..."
                            src={data.mainImage.publicUrl}
                            width="150px"
                          />
                        ) : (
                          <img
                            alt="..."
                            width="150px"
                            src={require("assets/img/theme/no.png")}
                          />
                        )}
                      </a>
                    </div>
                  </td>

                  <td>{data.title}</td>
                  <td>{data.sellerId?data.sellerId.sellerName : <>Nil</>}</td>
                  <td>{data.product[0] ? data.product[0].name : <>Nil</>}</td>
                  <td>{data.categoryId ? data.categoryId.name : <>Nil</>}</td>
                  <td>
                    <Link to={`/admin/sliderdetails/${data.id}`}>
                      <Button
                        className=" btn-icon"
                        color="info"
                        size="sm"
                        type="button"
                      >
                        <i className=" ni ni-circle-08 pt-1"></i>
                        <span className="btn-inner--text">View</span>
                      </Button>
                    </Link>
                  </td>
                </tr>
              );
            })}
          </tbody>
        </>
      );
    } else {
      return (
        <div className=" d-flex justify-content-center ">
          <Spinner
            style={{ width: "3rem", height: "3rem" }}
            type="grow"
            color="warning"
          />
        </div>
      );
    }
  };

  Alert = () => {
    if (this.state.succsess) {
      return (
        <UncontrolledAlert color="success" fade={false}>
          <span className="alert-inner--icon">
            <i className="ni ni-like-2" />
          </span>{" "}
          <span className="alert-inner--text">
            <strong>Success!</strong>slider Added Successfully
          </span>
        </UncontrolledAlert>
      );
    } else if (this.state.error) {
      return (
        <UncontrolledAlert color="danger" fade={false}>
          <span className="alert-inner--icon"></span>{" "}
          <span className="alert-inner--text">
            <strong>Opps!</strong> Something Went Wrong !
          </span>
        </UncontrolledAlert>
      );
    } else {
      return <></>;
    }
  };
  AddSlider = () => {
    const { selectedOption } = this.state;
    console.log(selectedOption, "////////////////////////////////");
    return (
      <>
        <Row className=" icon-examples">
          <Col md="6">
            <FormGroup>
              <Input
                id=""
                placeholder="Title"
                type="text"
                name="title"
                onChange={this.handleChange}
              />
              <h4 style={style}>{this.state.titleError}</h4>
            </FormGroup>
          </Col>
          <Col md="6">
            <FormGroup>
              <CustomInput
                type="select"
                name="type"
                onChange={this.handleChange}
              >
                <option value="">Type</option>
                <option value="adds">Adds</option>
                <option value="slider">Sliders</option>
                <option value="banner">Banner</option>
                <option value="festivals">Festival</option>
              </CustomInput>
              <h4 style={style}>{this.state.typeError}</h4>
            </FormGroup>
          </Col>
        </Row>
        <Row>
          <Col md="6">
            <FormGroup>
              <CustomInput
                type="select"
                name="category"
                onChange={this.handleChange}
                className="mt-2"
              >
                <option value="">Select Category</option>
                <option value="seller">Seller</option>
                <option value="product">Product</option>
              {this.state.type === "festivals" ? (

                <option value="category">Category</option>
                ):<></>}
              
              </CustomInput>
              <h4 style={style}>{this.state.categoryError}</h4>
            </FormGroup>
          </Col>

          <Col md="6">
            <FormGroup>
              {this.state.category === "seller" ? (
                <>
                <CustomInput
                  type="select"
                  name="sellerId"
                  onChange={this.handleChange}
                >
                  <option value="">Seller</option>
                  {this.state.sellerdetails.map((data, key) => {
                    return <option value={data.id}>{data.sellerName}</option>;
                  })}
                </CustomInput>
                <h4 style={style}>{this.state.sellerIdError}</h4>
                </>
                
              ) : (
                <></>
              )}

              {this.state.category === "product" ? (
                <>
                  <CustomInput
                    type="select"
                    name="sellerId"
                    onChange={this.handleChange}
                  >
                    <option value="">Seller</option>;
                    {this.state.sellerdetails.map((data, key) => {
                      return <option value={data.id}>{data.sellerName}</option>;
                    })}
                  </CustomInput>
                <h4 style={style}>{this.state.sellerI2dError}</h4>
                
                  <CustomInput
                    className="mt-2"
                    type="select"
                    name="productId"
                    onChange={this.handleChange}
                    placeholder="select product"
                  >
                    <option value="">Product</option>;
                    {this.state.productdetails.map((data, key) => {
                      return <option value={data.id}>{data.name}</option>;
                    })}
                  </CustomInput>
                <h4 style={style}>{this.state.productIdError}</h4>
                </>
              ) : (
                <></>
              )}
      {this.state.category === "category" ? (
                <>

                  <CustomInput
                    className="mt-2"
                    type="select"
                    name="categoryId"
                    onChange={this.handleChange}
                    placeholder="select category"
                  >
                    <option value="">Category</option>;
                    {this.state.categories.map((data, key) => {
                      return <option value={data._id}>{data.name}</option>;
                    })}
                  </CustomInput>
                </>
              ) : (
                <></>
              )}

              <CustomInput
                className="mt-2"
                type="file"
                id="exampleCustomFileBrowser"
                name="mainImage"
                onChange={this.onFileChangeProfile}
              />
              <h4 style={style}>{this.state.mainImageError}</h4>
            </FormGroup>
          </Col>
        </Row>
        <Row className="justify-content-lg-center">
          <Button
            className="btn-icon btn-2"
            color="info"
            type="button"
            onClick={this.AddToItems}
          >
            <span className="btn-inner--icon">
              <span className="btn-inner--text">Add</span>
            </span>
          </Button>
        </Row>
      </>
    );
  };

  render() {
    return (
      <>
        <Header2 />
        <Container className=" mt--7" fluid>
          <Row>
            <div className=" col">
              <Card className=" shadow">
                <CardHeader className=" bg-transparent">
                  <h3 className=" mb-0">Add Slider</h3>
                </CardHeader>
                <CardBody>
                  <this.AddSlider />
                </CardBody>
              </Card>
              <this.Alert />
              <br />
            </div>
          </Row>
        </Container>

        <div>
          <Container className=" mt--10" fluid>
            {/* Table */}
            <Row>
              <div className=" col">
                <Card className=" shadow">
                  <CardHeader className=" border-0"></CardHeader>

                  <Table className="align-items-center table-flush" responsive>
                    <this.ProductList />
                  </Table>

                  <CardFooter className="py-4"></CardFooter>
                </Card>
              </div>
            </Row>
          </Container>
        </div>
      </>
    );
  }
}
