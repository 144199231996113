import React from "react";
// import Switch from "react-switch";
import { Spinner } from "reactstrap";
import { Link } from "react-router-dom";
// reactstrap components
import {
  Card,
  CardHeader,
  CardBody,
  FormGroup,
  Form,
  Input,
  Container,
  Row,
  Col,
  Badge,
} from "reactstrap";
// core components
import Cookie from "js-cookie";
import Header2 from "components/Headers/Header2";
import { createInterfaceDeclaration } from "typescript";
const axios = require("axios").default;
const URL = require("../../ApiUrl");

export default class Deliverydetails extends React.Component {
state = {
  checked: false,
  isLoaded: false,
  isVerified: false,
  isprofileImageId: false,

};

Getdeliveryboydetails = async () => {
  const token = Cookie.get("patrickToken")
    ? Cookie.get("patrickToken")
    : null;
  const config = {
    headers: { Authorization: `Bearer ${token}` },
  };
  let id = this.props.match.params.id;
  let response = await axios
    .get(`${URL.BASE_URL}/admin-delivery-boys/${id}/detail`, config)
    .catch(function (error) {
      console.log(error);
    });

  console.log("👇👇👇👇👇👇👇👇👇👇");
  console.log(response);

  if (response && response.data !== null) {
    this.setState({
      customerdetail: response.data.data,
      isLoaded: true,
      
    });
  } else if (response && response.data.statusCode !== 200) {
    this.setState({
      error: true,
      errorCode: response.data.msg,
    });
  } else {
    this.setState({
      status: false,
    });
  }
};
componentDidMount = () => {
  this.Getdeliveryboydetails();
};

handleChange = (e) => {
  this.setState({
    [e.target.data]: e.target.value,
  });
};


CustomerCard = () => {
  let card = {
   
    
   
    profileImageId: {},
  };
  if (this.state.customerdetail) {
    card = this.state.customerdetail;
  }

  return (
    <>
      <Card className="card-profile shadow">
        <Row className="justify-content-center">
          <Col className="order-lg-2" lg="3">
            <div className="card-profile-image">
              <a href="#pablo" onClick={(e) => e.preventDefault()}>
                {card.profileImageId ? (
                  <img
                    alt="Loading..."
                    src={card.profileImageId.publicUrl}
                    width="150px"
                  />
                ) : (
                  <img
                    alt="..."
                    className="rounded-circle"
                    src={require("assets/img/theme/team-4-800x800.jpg")}
                  />
                )}
              </a>
            </div>
          </Col>
        </Row>
        <CardHeader className="text-center border-0 pt-8 pt-md-4 pb-0 pb-md-4">
          <div className="d-flex justify-content-between"></div>
        </CardHeader>
        <CardBody className="pt-0 pt-md-4">
          <Row>
            <div className="col">
              <div className="card-profile-stats d-flex justify-content-center mt-md-5"></div>
            </div>
          </Row>
          <div className="text-center">
            <h3>{card.firstName}</h3>

            {/*<hr className="my-4" />
            <Row className="justify-content-lg-center">
              <span>Verified &nbsp;</span>
              <Switch
                onChange={this.handleChangeSwitchVerified}
                checked={this.state.isVerified}
              />
            </Row>
            <hr className="my-4" />*/}
          </div>
          <Row>
            <div className="col">
              <div className="card-profile-stats d-flex justify-content-center mt-md-5">
                <div>
                <Row className="justify-content-lg-center">
                <span>Available &nbsp;</span></Row>
                  <span className="description">Total Rewards</span>
                </div>
              </div>
            </div>
          </Row>
        </CardBody>
      </Card>
      <br />
    </>
  );
};

CustomerDetails = () => {
  let information,d,d1,date1,month1,year1,hour1,minute1,dateStr1,date,month,year,hour,minute,dateStr;
  if (this.props.match.params.id) {
    information = this.state.customerdetail;
  
  }
 
  
  if (this.state.isLoaded) {
  
  
   d = new Date(information.shiftId.startTime);
   d1 = new Date(information.shiftId.endTime);
   date1 = d1.getDate();
   month1 = d1.getMonth() + 1; // Since getMonth() returns month from 0-11 not 1-12
   year1 = d1.getFullYear();
   hour1=d1.getHours();
   minute1=d1.getMinutes();
   dateStr1 = date1 + "/" + month1 + "/" + year1+":::"+hour1+"h"+":"+minute1+"m";
   date = d.getDate();
   month = d.getMonth() + 1; // Since getMonth() returns month from 0-11 not 1-12
   year = d.getFullYear();
   hour=d.getHours();
  minute=d.getMinutes();
   dateStr = date + "/" + month + "/" + year +":::"+hour+"h"+":"+minute+"m";
  
   
    return (
      <>
        <h6 className="heading-small text-muted mb-4">
          DeliveryBoy information
        </h6>
        <div className="pl-lg-4">
          <Row>
            <Col lg="6">
              <FormGroup>
                <label
                  className="form-control-label"
                  htmlFor="input-username"
                >
                  Name
                </label>
                <Input
                  className="form-control-alternative"
                  type="text"
                  value={information.deliveryBoyName}
                  disabled
                />
              </FormGroup>
            </Col>
            <Col lg="6">
            <FormGroup>
        <label className="form-control-label">Address</label>
                <Input
                  className="form-control-alternative"
                  
                  type="text"
                  value={information.address}
                  disabled
                />
       
       </FormGroup></Col>
       <Col lg="6">
            <FormGroup>
        <label className="form-control-label">Pin code</label>
                <Input
                  className="form-control-alternative"
                  
                  type="text"
                  value={information.pincode}
                  disabled
                />
       
       </FormGroup></Col>
            <Col lg="6">
              <FormGroup>
                <label
                  className="form-control-label"
                  htmlFor="input-first-name"
                >
                  Franchise Name
                </label>
                <Input
                  className="form-control-alternative"
                  type="text"
                   value={information.franchiseId.franchiseName}
                  disabled
                />
              </FormGroup>
            </Col>
          </Row>
          <Row>
            <Col lg="6">
              <FormGroup>
                <label className="form-control-label" htmlFor="input-email">
                  Email address
                </label>
                <Input
                  className="form-control-alternative"
                  type="email"
                 value={information.email}
                  disabled
                />
              </FormGroup>
            </Col>
            <Col lg="6">
              <FormGroup>
                <label className="form-control-label" htmlFor="input-email">
                  Mobile number
                </label>
                <Input
                  className="form-control-alternative"
                  type="text"
                  value={information.mobile}
                  disabled
                />
              </FormGroup>
            </Col>
            <Col lg="6">
              <FormGroup>
                <label className="form-control-label" htmlFor="input-email">
                  Shift Name
                </label>
                <Input
                  className="form-control-alternative"
                  type="text"
                  value={information.shiftId.name}
                  disabled
                />

              </FormGroup>
            </Col>
            <Col lg="6">
              <FormGroup>
                <label className="form-control-label">Start Time</label>
                <Input
                  className="form-control-alternative"
                  type="text"
                  value={dateStr}
                  disabled
                />
              </FormGroup>
            </Col>
            <Col lg="6">
              <FormGroup>
                <label className="form-control-label">End Time</label>
                <Input
                  className="form-control-alternative"
                  type="text"
                 value={dateStr1}
                  disabled
                />
              </FormGroup>
            </Col>
          </Row>
        </div>
      
       
        
        
      </>
    );
  } else {
    return (
      <>
        <Spinner
          color="warning"
          style={{ width: "4rem", height: "4rem", marginLeft: "45%" }}
        />{" "}
      </>
    );
  }
};

render() {
  return (
    <>
      <Header2 />
      {/* Page content */}
      <Container className="mt--7" fluid>
        <Row>
          <Col className="order-xl-2 mb-5 mb-xl-0" xl="4">
            <this.CustomerCard />
          </Col>
          <Col className="order-xl-1" xl="8">
            <Card className="bg-secondary shadow">
              <CardHeader className="bg-white border-0">
                <Row className="align-items-center">
                  <Col xs="8">
                    <h3 className="mb-0">Delivery Boy Information</h3>
                  </Col>
                </Row>
              </CardHeader>
              <CardBody>
                <Form>
                  <this.CustomerDetails />
                </Form>
              </CardBody>
            </Card>
          </Col>
        </Row>
      </Container>
    </>
  );
}
}