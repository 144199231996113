import React, { Component } from "react";
import Header2 from "../../../components/Headers/Header2";
import {
  Card,
  CardHeader,
  Container,
  CardFooter,
  Row,
  Col,
  CardBody,
  Input,
  FormGroup,
  Form,
  Label,
  Button,
  CustomInput,
  Table,
  FormText,
  UncontrolledAlert,
  Spinner,
  Modal,
} from "reactstrap";
import Cookie from "js-cookie";
const axios = require("axios").default;
const URL = require("../../ApiUrl");
export default class CustomerDashboardSettings extends Component {
  state = {
    dashboardItems: [],
    error: false,
    errorCode: "",
    elementType: "",
    elementSubtype: "",
    count: "",
    position: "",
    title: "",
    isLoaded: false,
  };
  PostSettings = async (e) => {
    let max = Math.max.apply(
      Math,
      this.state.dashboardItems.map(function (o) {
        return o.position;
      })
    );
    console.log(max, "maximum value");
    const arrlength = this.state.dashboardItems.length;
    console.log(arrlength, "Array Length");
    if (arrlength !== max) {
      this.setState({
        error: true,
        errorCode: "Recheck the Position",
      });
      return;
    }
    e.preventDefault();
    const token = Cookie.get("patrickToken")
      ? Cookie.get("patrickToken")
      : null;
    const config = {
      headers: { Authorization: `Bearer ${token}` },
    };
    console.log(config);

    let data = {
      dashboard: this.state.dashboardItems,
    };

    // console.log(JSON.stringify(data),"datttta");
    let response = await axios
      .post(`${URL.BASE_URL}/masters/dashboard/add`, data, config)
      .catch(function (error) {
        console.log(error);
      });
    console.log(data);
    if (response && response.data.statusCode === 200) {
      this.setState({
        succsess: true,
        isRedirect: true,
      });
    } else if (response && response.data.statusCode !== 200) {
      this.setState({
        error: true,
        errorCode: response.data.msg,
      });
    } else {
      this.setState({
        error: true,
      });
    }
    this.GetSettings();
    document.getElementById("myForm").reset();
    console.log(response);
  };
  GetCustomer = async () => {
    this.setState({ isLoaded: false });
    const token = Cookie.get("patrickToken")
      ? Cookie.get("patrickToken")
      : null;
    const config = {
      headers: { Authorization: `Bearer ${token}` },
    };
    let response = await axios
      .get(`${URL.BASE_URL}/admin-products/category/list/names`, config)
      .catch(function (error) {
        console.log(error);
      });

    console.log("👇👇👇👇👇👇👇👇👇👇");
    console.log(response);

    if (response && response.data !== null) {
      this.setState({
        customer: response.data.data,
        isLoaded: true,
      });
    } else if (response && response.data.statusCode !== 200) {
      this.setState({
        error: true,
        errorCode: response.data.msg,
      });
    } else {
      this.setState({
        status: false,
      });
    }
  };

  GetSettings = async () => {
    this.setState({ isLoaded: false });
    const token = Cookie.get("patrickToken")
      ? Cookie.get("patrickToken")
      : null;
    const config = {
      headers: { Authorization: `Bearer ${token}` },
    };
    let response;
    response = await axios
      .get(`${URL.BASE_URL}/masters/dashboard/list`, config)
      .catch(function (error) {
        console.log(error);
      });
    console.log("👇👇👇👇👇👇👇👇👇👇");
    console.log(response);

    if (response && response.data !== null) {
      this.setState({
        settings: response.data.data,
        isLoaded: true,
      });
    } else if (response && response.data.statusCode !== 200) {
      this.setState({
        error: true,
        errorCode: response.data.msg,
      });
    } else {
      this.setState({
        status: false,
      });
    }
  };
  componentDidMount() {
    this.GetSettings();
    this.GetCustomer();
  }
  handleChange = (e) => {
    this.setState({
      [e.target.name]: e.target.value,
    });
    console.log(this.state);
  };
  onHandleSubmit() {
    console.log("state reseted");
    this.setState({
      elementType: "",
      elementSubtype: "",
      count: this.state.count,
      position: this.state.position,
      title: "",
      elementId: undefined,
    });
  }

  // Validation = () =>{
  //   this.setState({
  //     errormsgelementType: "",
  //     errormsgelementSubtype: "",
  //     errormsgCount: "",
  //     errormsgPosition: "",
  //     errormsgPosition1: "",
  //     errormsgId: "",
  //   });
  //   // console.log(this.state.elementType, " elementType");
  //   // console.log(this.state.count === "" && this.state.elementType !== "Banner", " this.state.count === "" && this.state.elementType !== Banner");
  //   console.log(this.state.elementType === "", "this.state.elementType");
  //   console.log(this.state.elementSubtype === "", "this.state.elementSubtype");
  //   console.log(
  //     this.state.count === "" && this.state.elementType !== "Banner",
  //     "this.statecount"
  //   );
  //   console.log(this.state.position === "", "this.state.position");

  //   if (
  //     this.state.elementType === "" ||
  //     this.state.elementSubtype === "" ||
  //     (this.state.count === "" && this.state.elementType !== "banner") ||
  //     this.state.position === "" ||
  //     (this.state.elementId === "" &&
  //       this.state.elementType === "productCategory")
  //   ) {
  //     console.log("inside validation");

  //     if (
  //       this.state.elementType === "" ||
  //       this.state.elementType === "Select"
  //     ) {
  //       this.setState({
  //         errormsgelementType: "*Type required",
  //       });

  //       console.log(
  //         this.state.elementType === "" || this.state.elementType === "Select",
  //         "Validation"
  //       );
  //       console.log(this.state.elementType, "Validation elementType");
  //     }
  //     if (
  //       this.state.elementSubtype === "" ||
  //       this.state.elementSubtype === "Select"
  //     ) {
  //       this.setState({
  //         errormsgelementSubtype: "*Layout required",
  //       });
  //       console.log(
  //         this.state.elementSubtype === "" ||
  //           this.state.elementSubtype === "Select",
  //         "Validation"
  //       );
  //       console.log(this.state.elementSubtype, "Validation Layout");
  //     }
  //     if (this.state.count === "" && this.state.elementType !== "banner") {
  //       this.setState({
  //         errormsgCount: "*Count required",
  //       });
  //       console.log(
  //         this.state.count === "" && this.state.elementType !== "banner",
  //         "Validation"
  //       );
  //       console.log(this.state.count, "Validation Count");
  //     }
  //     if (this.state.position === "") {
  //       this.setState({
  //         errormsgPosition: "*Position required",
  //       });
  //       console.log(this.state.position === "", "Position Validation");
  //       console.log(this.state.position, "Validation Position");
  //     }
  //     if (
  //       this.state.elementId === undefined ||
  //       (this.state.elementId === "Select Category" &&
  //         this.state.elementType === "productCategory")
  //     ) {
  //       this.setState({
  //         errormsgId: "*Category required",
  //       });
  //       console.log(this.state.elementId, "Validation select category");
  //     }
  //     return;
  //   }
  //   console.log("outside validation");
  // }

  AddToDashbord = (e) => {
    let dashboardItems = this.state.dashboardItems;
    this.setState({
      errormsgelementType: "",
      errormsgelementSubtype: "",
      errormsgCount: "",
      errormsgPosition: "",
      errormsgPosition1: "",
      errormsgId: "",
    });
    // console.log(this.state.elementType, " elementType");
    // console.log(this.state.count === "" && this.state.elementType !== "Banner", " this.state.count === "" && this.state.elementType !== Banner");
    console.log(this.state.elementType === "", "this.state.elementType");
    console.log(this.state.elementSubtype === "", "this.state.elementSubtype");
    console.log(
      this.state.count === "" && this.state.elementType !== "Banner",
      "this.statecount"
    );
    console.log(this.state.position === "", "this.state.position");

    if (
      this.state.elementType === "" ||
      this.state.elementSubtype === "" ||
      (this.state.count === "" && this.state.elementType !== "banner") ||
      this.state.position === "" ||
      (this.state.elementId === "" &&
        this.state.elementType === "productCategory")
    ) {
      console.log("inside validation");

      if (
        this.state.elementType === "" ||
        this.state.elementType === "Select"
      ) {
        this.setState({
          errormsgelementType: "*Type required",
        });

        console.log(
          this.state.elementType === "" || this.state.elementType === "Select",
          "Validation"
        );
        console.log(this.state.elementType, "Validation elementType");
      }
      if (
        this.state.elementSubtype === "" ||
        this.state.elementSubtype === "Select"
      ) {
        this.setState({
          errormsgelementSubtype: "*Layout required",
        });
        console.log(
          this.state.elementSubtype === "" ||
            this.state.elementSubtype === "Select",
          "Validation"
        );
        console.log(this.state.elementSubtype, "Validation Layout");
      }
      if (this.state.count === "" && this.state.elementType !== "banner") {
        this.setState({
          errormsgCount: "*Count required",
        });
        console.log(
          this.state.count === "" && this.state.elementType !== "banner",
          "Validation"
        );
        console.log(this.state.count, "Validation Count");
      }
      if (this.state.position === "") {
        this.setState({
          errormsgPosition: "*Position required",
        });
        console.log(this.state.position === "", "Position Validation");
        console.log(this.state.position, "Validation Position");
      }
      if (
        this.state.elementId === undefined ||
        (this.state.elementId === "Select Category" &&
          this.state.elementType === "productCategory")
      ) {
        this.setState({
          errormsgId: "*Category required",
        });
        console.log(this.state.elementId, "Validation select category");
      }
      return;
    }
    console.log("outside validation");
    let index = dashboardItems.findIndex(
      (x) => x.position === parseInt(this.state.position)
    );
    console.log(index, "index");
    if (index < 0) {
      console.log(index, "index value");
      var obj = {
        elementType: this.state.elementType,
        elementSubtype: this.state.elementSubtype,
        elementId: this.state.elementId,
        title: this.state.title,
        position: parseInt(this.state.position),
        status: 1,
      };
      console.log(obj, "object");

      if (this.state.elementType !== "banner") {
        obj.count = parseInt(this.state.count);
      }
      dashboardItems.push(obj);
      console.log(dashboardItems, "all items");
    } else {
      this.setState({
        errormsgPosition1: "*Position Already taken",
      });
      return;
    }

    console.log(dashboardItems, "else all items");

    this.setState({ dashboardItems: dashboardItems });
    document.getElementById("myForm").reset();
    this.onHandleSubmit();
    console.log(dashboardItems);
  };

  Alert = () => {
    if (this.state.succsess) {
      return (
        <UncontrolledAlert color="success" fade={false} className="mt-1">
          <span className="alert-inner--icon">
            <i className="ni ni-like-2" />
          </span>{" "}
          <span className="alert-inner--text">
            <strong>Success!</strong> Dashboard Settings Updated Successfully
          </span>
        </UncontrolledAlert>
      );
    } else if (this.state.error) {
      return (
        <UncontrolledAlert color="danger" fade={false} className="mt-1">
          <span className="alert-inner--icon"></span>{" "}
          <span className="alert-inner--text">
            <strong>{this.state.errorCode}</strong>
          </span>
        </UncontrolledAlert>
      );
    } else {
      return <></>;
    }
  };
  handleEditClick = (id) => {
    this.setState({ isEdit: !this.state.isEdit, update: id });
  };

  toggleModal = (state, id) => {
    this.setState({
      [state]: !this.state[state],
      deleteId: id,
    });
  };

  AddField = () => {
    return (
      <Form id="myForm">
        <Row className=" icon-examples">
          <Col lg="3">
            <FormGroup>
              <Label>Type</Label>

              <CustomInput
                id="mainInput"
                type="select"
                onChange={this.handleChange}
                name="elementType"
              >
                <option>Select</option>
                <option value="banner">Banner</option>
                <option value="slider">Slider</option>
                <option value="shop">Shop</option>
                <option value="adds">Adds</option>
                <option value="productCategory">Product Category</option>
              </CustomInput>
              {this.state.elementType === "productCategory" ? (
                <>
                  <Label className="mt-2">Product Category</Label>
                  <CustomInput
                    type="select"
                    name="elementId"
                    onChange={this.handleChange}
                  >
                    <option>Select Category</option>;
                    {this.state.customer.map((data, key) => {
                      return <option value={data._id}>{data.name}</option>;
                    })}
                  </CustomInput>
                  <div style={{ color: "red", paddingBottom: 10 }}>
                    {" "}
                    {this.state.errormsgId}
                  </div>
                </>
              ) : (
                <></>
              )}
              <div style={{ color: "red", paddingBottom: 10 }}>
                {" "}
                {this.state.errormsgelementType}
              </div>
            </FormGroup>
          </Col>
          <Col lg="2">
            <FormGroup>
              <Label>Lay out</Label>
              <CustomInput
                type="select"
                onChange={this.handleChange}
                name="elementSubtype"
              >
                <option>Select</option>
                <option value="horizontal">Horizontal</option>
                <option value="grid">Grid</option>
              </CustomInput>
              <div style={{ color: "red", paddingBottom: 10 }}>
                {this.state.errormsgelementSubtype}
              </div>
            </FormGroup>
          </Col>
          <Col lg="2">
            <FormGroup>
              <Label>Count</Label>
              {this.state.elementType === "banner" ? (
                <Input
                  id="input-username"
                  placeholder="Number"
                  type="number"
                  maxLength="2"
                  onInput={this.maxLengthCheck}
                  onChange={this.handleChange}
                  disabled
                />
              ) : (
                <Input
                  id="input-username"
                  placeholder="Number"
                  name="count"
                  type="number"
                  maxLength="2"
                  onInput={this.maxLengthCheck}
                  onChange={this.handleChange}
                />
              )}

              <div style={{ color: "red", paddingBottom: 10 }}>
                {this.state.errormsgCount}
              </div>
            </FormGroup>
          </Col>
          <Col lg="2">
            <FormGroup>
              <Label>Position</Label>
              <Input
                id="input-username"
                placeholder="Number"
                type="number"
                name="position"
                maxLength="1"
                onInput={this.maxLengthCheck}
                onChange={this.handleChange}
              />
              <div style={{ color: "red", paddingBottom: 10 }}>
                {this.state.errormsgPosition}
                {this.state.errormsgPosition1}
              </div>
            </FormGroup>
          </Col>
          <Col lg="3">
            <FormGroup>
              <Label>Title</Label>
              <Input
                id="input-username"
                placeholder="Title"
                type="text"
                name="title"
                onChange={this.handleChange}
              />
            </FormGroup>
          </Col>
        </Row>
        <div className="text-center">
          <Button
            color="primary"
            type="button"
            size="lg"
            onClick={this.AddToDashbord}
          >
            Add
          </Button>
        </div>
      </Form>
    );
  };

  deleteItem = (key) => {
    console.log(key);
    const allItems = this.state.dashboardItems;
    allItems.splice(key, 1);
    this.setState({
      dashboardItems: allItems,
    });
  };

  maxLengthCheck = (object) => {
    if (object.target.value.length > object.target.maxLength) {
      object.target.value = object.target.value.slice(
        0,
        object.target.maxLength
      );
    }
  };

  onInputChange(e) {
    this.setState({
      inputValue: e.target.value,
    });
  }

  Table = () => {
    function capitalizeFirstLetter(string) {
      return string.charAt(0).toUpperCase() + string.slice(1);
    }
    return (
      <>
        <h6 className="heading-small text-muted">Added Items</h6>
        <div className="">
          <Row>
            <Table className="align-items-center table-flush" responsive>
              <thead>
                <tr>
                  <th scope="col">#</th>
                  <th scope="col">Type</th>
                  <th scope="col">Lay Out</th>
                  <th scope="col">Count</th>
                  <th scope="col">Position</th>
                  <th scope="col">Title</th>
                  <th scope="col">Action</th>
                </tr>
              </thead>
              <tbody>
                {this.state.dashboardItems.map((item, key) => {
                  return (
                    <tr>
                      <td>{key + 1}</td>
                      <td>{capitalizeFirstLetter(item.elementType)}</td>
                      <td>{capitalizeFirstLetter(item.elementSubtype)}</td>
                      <td>{item.count}</td>
                      <td>{item.position}</td>
                      <td>{item.title}</td>
                      <td>
                        {/* <Button
                      className="btn-tooltip mr-3"
                      color="success"
                      size="sm"
                      type="button"
                    >
                      <span className="btn-inner--icon">
                        <i className="ni ni-ruler-pencil" />
                      </span>
                      <span className="btn-inner--text">Edit</span>
                    </Button> */}
                        <Button
                          className="btn-tooltip mr-3"
                          color="danger"
                          size="sm"
                          type="button"
                          onClick={() => this.deleteItem(key)}
                        >
                          <span className="btn-inner--text">Delete</span>
                        </Button>
                      </td>
                    </tr>
                  );
                })}
              </tbody>
            </Table>
          </Row>
        </div>
      </>
    );
  };
  DeleteSettings = async (key) => {
    // let id = this.state.deleteId;
    const token = Cookie.get("patrickToken")
      ? Cookie.get("patrickToken")
      : null;
    const config = {
      headers: { Authorization: `Bearer ${token}` },
    };
    let response = await axios
      .delete(`${URL.BASE_URL}/masters/dashboard/delete`, config)
      .catch(function (error) {
        console.log(error);
      });
    if (response.data.statusCode === 200) {
      this.setState({
        isRedirect: true,
      });
    }
    this.GetSettings();
    this.toggleModal("notificationModal");
  };
  Delete = () => {
    return (
      <>
        <Col md="12">
          <Modal
            className="modal-dialog-centered modal-danger"
            contentClassName="bg-gradient-danger"
            isOpen={this.state.notificationModal}
            toggle={() => this.toggleModal("notificationModal")}
          >
            <div className="modal-header">
              <h6 className="modal-title" id="modal-title-notification">
                Your confirmation is required
              </h6>
              <button
                aria-label="Close"
                className="close"
                data-dismiss="modal"
                type="button"
                onClick={() => this.toggleModal("notificationModal")}
              >
                <span aria-hidden={true}>×</span>
              </button>
            </div>
            <div className="modal-body">
              <div className="py-3 text-center">
                <i class="fas fa-trash-alt fa-3x"></i>
                <h4 className="heading mt-4">ATTENTION !!</h4>
                <p>Are you sure want to delete this Settings ?</p>
                <p>You can't undo this operation!</p>
              </div>
            </div>
            <div className="modal-footer">
              <Button
                className="btn-white"
                color="default"
                type="button"
                onClick={() => this.DeleteSettings()}
              >
                Ok, Delete
              </Button>
              <Button
                className="text-white ml-auto"
                color="link"
                data-dismiss="modal"
                type="button"
                onClick={() => this.toggleModal("notificationModal")}
              >
                Close
              </Button>
            </div>
          </Modal>
        </Col>
      </>
    );
  };

  SettingsList = () => {
    let isEdit = this.state.isEdit;
    let settings = this.state.settings;
    // console.log(settings, "🎈🎈🎈🎈🎈🎈🎈");
    if (this.state.isLoaded) {
      return (
        <>
          <thead className="thead-light">
            <tr>
              <th scope="col">#</th>
              <th scope="col">Type</th>
              <th scope="col">Layout</th>
              <th scope="col">Count</th>
              <th scope="col">Position</th>
              <th scope="col">Title</th>
              {/* <th scope="text-end">Actions</th> */}
            </tr>
          </thead>
          <tbody>
            {this.state.settings &&
              this.state.settings.map((data, key) => {
                return (
                  <tr>
                    <td>{key + 1}</td>
                    <td>
                      {isEdit && data._id === this.state.update ? (
                        <Input
                          id="input-username"
                          placeholder="Name"
                          type="text"
                          name="name"
                          defaultValue={data.name}
                          onChange={this.handleChange}
                        />
                      ) : (
                        data.elementType
                      )}
                    </td>
                    <td>{data.elementSubtype}</td>
                    <td>{data.count}</td>
                    <td>{data.position}</td>
                    <td>{data.title}</td>
                    {/* <td scope="text-center">
                    {isEdit && data._id === this.state.update ? (
                      <Button
                        className="btn-tooltip mr-3"
                        color="success"
                        size="sm"
                        type="button"
                        onClick={() => this.PatchSellerCategory(data._id)}
                      >
                        <span className="btn-inner--icon">
                          <i className="ni ni-check-bold" />
                        </span>
                        <span className="btn-inner--text"> Save</span>
                      </Button>
                    ) : (
                      <Button
                        className="btn-tooltip mr-3"
                        color="success"
                        size="sm"
                        type="button"
                        onClick={() => this.handleEditClick(data._id)}
                      >
                        <span className="btn-inner--icon">
                          <i className="ni ni-ruler-pencil" />
                        </span>
                        <span className="btn-inner--text">Edit</span>
                      </Button>
                    )}

                    {isEdit ? (
                      <Button
                        className="btn-tooltip mr-3"
                        color="danger"
                        size="sm"
                        type="button"
                        onClick={this.handleEditClick}
                      >
                        <span className="btn-inner--icon">
                          <i className="ni ni-fat-remove" />
                        </span>
                        <span className="btn-inner--text">Close</span>
                      </Button>
                    ) : (
                      <></>
                    )}
                  </td> */}
                  </tr>
                );
              })}
          </tbody>
        </>
      );
    } else {
      return (
        <div className=" d-flex justify-content-center ">
          <Spinner
            style={{ width: "3rem", height: "3rem" }}
            type="grow"
            color="warning"
          />
        </div>
        // <></>
      );
    }
  };
  render() {
    return (
      <>
        <Header2 />
        <Container className=" mt--7" fluid>
          {/* Table */}
          <Row>
            <div className=" col">
              <Card className=" shadow">
                <CardHeader className=" bg-transparent">
                  <h3 className=" mb-0">Customer Dashboard Settings</h3>
                </CardHeader>
                <CardBody>
                  <this.AddField />
                  <this.Table />
                </CardBody>
                <CardFooter>
                  <div className="text-center">
                    <Button
                      color="success"
                      type="button"
                      onClick={this.PostSettings}
                    >
                      Submit
                    </Button>
                  </div>
                </CardFooter>
              </Card>
              <this.Alert />
            </div>
          </Row>
        </Container>
        <br />
        <Container fluid>
          <Card className=" shadow">
            <CardHeader className=" bg-transparent">
              <h3 className=" mb-0">Dashboard Settings</h3>
            </CardHeader>
            <Table className="align-items-center table-flush" responsive>
              <this.SettingsList />
            </Table>

            <CardFooter className="py-4">
              {/* <div className="text-center">

              <Button
                className="btn-tooltip mr-3"
                color="danger"
                size="lg"
                type="button"
                onClick={() =>
                  this.toggleModal("notificationModal")
                }
              >
                <span className="btn-inner--icon">
                  <i className="fas fa-trash-alt" />
                </span>
                <span className="btn-inner--text"> Delete</span>
              </Button>
              </div> */}
            </CardFooter>
          </Card>
          <this.Delete />
        </Container>
      </>
    );
  }
}
