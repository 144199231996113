import React from "react";
import { Redirect } from "react-router-dom";

// reactstrap components
import {
  Card,
  CardHeader,
  Container,
  Row,
  Button,
  Col,
  CardBody,
  FormGroup,
  Input,
  UncontrolledAlert,
  ListGroup,
  ListGroupItem,
  Modal,
  Table,
  Spinner,
  ModalBody,
  CardFooter,
} from "reactstrap";
// core components
import Header2 from "../../components/Headers/Header2";

import Cookie from "js-cookie";
import CustomInput from "reactstrap/lib/CustomInput";
import { isThisTypeNode } from "typescript";
const axios = require("axios").default;
const URL = require("../ApiUrl");

class Master extends React.Component {
  state = {
    noOfDayReturnProductExpiry: "",
    appLinkSharePoint: "",
    rewardPointPercentage:"",
    sellerServiceCharge: "",
    totalPurchaseAmount: "",
    cashBackPercentage: "",
    maximumCashBackUsed: "",
    maximumRewardPointEarned: "",
    orderTaxPercentage: "",
    nearestFranchiseLimit : "",
    succsess: false,
    error: false,

    category: [],

    id: [],
  };

  GetCategory = async () => {
    const token = Cookie.get("patrickToken")
      ? Cookie.get("patrickToken")
      : null;
    const config = {
      headers: { Authorization: `Bearer ${token}` },
    };

    let response = await axios
      .get(`${URL.BASE_URL}/masters/config/detail`, config)
      .catch(function (error) {
        console.log(error);
      });
    console.log("👇👇👇👇👇👇👇👇👇👇");
    console.log(response);
    if (response && response.data !== null) {
      this.setState({
        category: response.data.data,
      });
    } else if (response && response.data.statusCode !== 200) {
      this.setState({
        error: true,
        errorCode: response.data.msg,
      });
    } else {
      this.setState({
        status: false,
      });
    }
  };

  componentDidMount() {
    this.GetCategory();
  }

  PatchCategoryEdit = async () => {
    console.log("//////////////////");
    const token = Cookie.get("patrickToken")
      ? Cookie.get("patrickToken")
      : null;
    const config = {
      headers: { Authorization: `Bearer ${token}` },
    };
    console.log(config);
    let data = {
      noOfDayReturnProductExpiry: this.state.noOfDayReturnProductExpiry,
      appLinkSharePoint: this.state.appLinkSharePoint,
      rewardPointPercentage: this.state.rewardPointPercentage,
      sellerServiceCharge: this.state.sellerServiceCharge,
      totalPurchaseAmount: this.state.totalPurchaseAmount,
      cashBackPercentage: this.state.cashBackPercentage,
      maximumCashBackUsed: this.state.maximumCashBackUsed,
      maximumRewardPointEarned: this.state.maximumRewardPointEarned,
      orderTaxPercentage: this.state.orderTaxPercentage,
      nearestFranchiseLimit : this.state.nearestFranchiseLimit
    };

    let response = await axios
      .patch(`${URL.BASE_URL}/masters/config/update`, data, config)
      .catch(function (error) {
        console.log(error);
      });
    if (response && response.data.statusCode === 200) {
      this.setState({
        succsess: true,
        isRedirect: true,
      });
    } else if (response && response.data.statusCode !== 200) {
      this.setState({
        error: true,
        errorCode: response.data.msg,
      });
    } else {
      this.setState({
        error: true,
      });
    }

    console.log(response);
  };

  handleChange = (e) => {
    this.setState({
      [e.target.name]: e.target.value,
    });
    console.log(e.target.value);
  };

  Alert = () => {
    if (this.state.succsess) {
      return (
        <UncontrolledAlert color="success" fade={false}>
          <span className="alert-inner--icon">
            <i className="ni ni-like-2" />
          </span>{" "}
          <span className="alert-inner--text">
            <strong>Success!</strong>Master updated Successfully
          </span>
        </UncontrolledAlert>
      );
    } else if (this.state.error) {
      return (
        <UncontrolledAlert color="danger" fade={false}>
          <span className="alert-inner--icon"></span>{" "}
          <span className="alert-inner--text">
            <strong>Opps!</strong> Something Went Wrong !
          </span>
        </UncontrolledAlert>
      );
    } else {
      return <></>;
    }
  };

  AddCategory = () => {
    let info;
    info = this.state.category;
    return (
      <>
        <Container className=" mt--7" fluid>
          {/* Table */}
          <Row>
            <div className=" col">
              <Card className=" shadow">
                <CardHeader className=" bg-transparent">
                  <h3 className=" mb-0">Master</h3>
                </CardHeader>
                <CardBody>
                  <Row>
                    <Col lg="6" md="4">
                      <FormGroup>
                        <label htmlFor="#username">Order Tax (%)</label>
                        <Input
                          name="orderTaxPercentage"
                          defaultValue={info.orderTaxPercentage}
                          type="Number"
                          onChange={this.handleChange}
                        />
                      </FormGroup>
                    </Col>
                    <Col lg="6" md="4">
                      <FormGroup>
                        <label htmlFor="#username">Return Product Expiry (days)</label>
                        <Input
                          name="noOfDayReturnProductExpiry"
                          defaultValue={info.noOfDayReturnProductExpiry}
                          type="Number"
                          onChange={this.handleChange}
                        />
                      </FormGroup>
                    </Col>
                    <Col lg="6" md="4">
                      <FormGroup>
                        <label htmlFor="#username">App link share point</label>
                        <Input
                          name="appLinkSharePoint"
                          defaultValue={info.appLinkSharePoint}
                          type="Number"
                          onChange={this.handleChange}
                        />
                      </FormGroup>
                    </Col>
                    <Col lg="6" md="4">
                      <FormGroup>
                        <label htmlFor="#username">Reward Point (%)</label>
                        <Input
                          name="rewardPointPercentage"
                          defaultValue={info.rewardPointPercentage}
                          type="Number"
                          onChange={this.handleChange}
                        />
                      </FormGroup>
                    </Col>
                    <Col lg="6" md="4">
                      <FormGroup>
                        <label htmlFor="#username">Seller Service Charge</label>
                        <Input
                          name="Sell"
                          defaultValue={info.sellerServiceCharge}
                          type="Number"
                          onChange={this.handleChange}
                        />
                      </FormGroup>
                    </Col>
                    <Col lg="6" md="4">
                      <FormGroup>
                        <label htmlFor="#username">Total Purchase Charge</label>
                        <Input
                          name="totalPurchaseAmount"
                          defaultValue={info.totalPurchaseAmount}
                          type="Number"
                          onChange={this.handleChange}
                        />
                      </FormGroup>
                    </Col>
                    <Col lg="6" md="4">
                      <FormGroup>
                        <label htmlFor="#username">CashBack (%)</label>
                        <Input
                          name="cashBackPercentage"
                          defaultValue={info.cashBackPercentage}
                          type="Number"
                          onChange={this.handleChange}
                        />
                      </FormGroup>
                    </Col>
                    <Col lg="6" md="4">
                      <FormGroup>
                        <label htmlFor="#username"> Maximum CashBack Used</label>
                        <Input
                          name="maximumCashBackUsed"
                          defaultValue={info.maximumCashBackUsed}
                          type="Number"
                          onChange={this.handleChange}
                        />
                      </FormGroup>
                    </Col>
                    <Col lg="6" md="4">
                      <FormGroup>
                        <label htmlFor="#username"> Maximum CashBack Used(%)</label>
                        <Input
                          name="maximumCashBackUsed"
                          defaultValue={info.maximumCashBackUsed}
                          type="Number"
                          onChange={this.handleChange}
                        />
                      </FormGroup>
                    </Col>
                    <Col lg="6" md="4">
                      <FormGroup>
                        <label htmlFor="#username"> Maximum Reward Point Earned</label>
                        <Input
                          name="maximumRewardPointEarned"
                          defaultValue={info.maximumRewardPointEarned}
                          type="Number"
                          onChange={this.handleChange}
                        />
                      </FormGroup>
                    </Col>
                    <Col lg="6" md="4">
                      <FormGroup>
                        <label htmlFor="#username"> Nearest Franchise Limit</label>
                        <Input
                          name="nearestFranchiseLimit"
                          defaultValue={info.nearestFranchiseLimit}
                          type="Number"
                          onChange={this.handleChange}
                        />
                      </FormGroup>
                    </Col>
                    
                  </Row>
                  <Row className=" d-flex justify-content-center mr-mt-md-4">
                    <Col lg="3">
                      <Button
                        className="btn-icon btn-2"
                        color="info"
                        type="button"
                        block
                        onClick={this.PatchCategoryEdit}
                      >
                        <span className="btn-inner--icon"></span>
                        <span className="btn-inner--text">Update</span>
                      </Button>
                    </Col>
                  </Row>
                </CardBody>
              </Card>
            </div>
          </Row>
        </Container>
      </>
    );
  };

  render() {
    return (
      <>
        <Header2 />
        {/* Page content */}
        <this.AddCategory />
        <Container className="mt-6" fluid></Container>

        <this.Alert />
      </>
    );
  }
}
export default Master;
