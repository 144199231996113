import React, { Component } from "react";
import Header2 from "../../../components/Headers/Header2";
import {
  Card,
  CardHeader,
  Container,
  Row,
  Col,
  CardBody,
  FormGroup,
  Label,
  Form,
  Button,
  Input,
  UncontrolledAlert,
  FormText,
  Spinner,
  Table,
  Modal,
  Alert,
  CardFooter,
  FormFeedback,
} from "reactstrap";
import Cookie from "js-cookie";
const axios = require("axios").default;
const URL = require("../../ApiUrl");

export default class ShopType extends Component {
  state = {
    name: "",
    shopTypeId: "",
    isLoaded: false,
    isEdit: false,
    visible:false,
    deliveryBoyFixedCharge: null,
    deliveryBoyBonusCharge: "",
    deliveryBoyKm: "",
    deliveryBoyPerKmCharge: "",
    sellerDeliveryBoyDistanceInKm: "",
    customerSellerDistanceInKm: "",
    constantDeliveryCharge: "",
    deliverySurgeCharge: "",
    marginDeliveryChargeDistanceInKm: "",
    variableDeliveryCharge: "",
    variableDeliveryChargeDistanceInKm: "",
    packingCharge: "",
    shopType: "",
    succsess: false,
    error: false,
    category: [],
    shopList: [],
    updateShopType: {},
    showField: false,
    succsess: false,
    allEditItems: [],
    deliveryBoyFixedChargeError: "",
    bonusError: "",
    kmError: "",
    kmchargeError: "",
    distanceError: "",
    distance2Error: "",
    constantDeliveryChargeError: "",
    deliverySurgeChargeError: "",
    marginDeliveryChargeDistanceInKmError: "",
    variableDeliveryChargeError: "",
    variableDeliveryChargeDistanceInKmError: "",
    packingChargeError: "",
  };
  resetErrorMsg = () => {
    this.setState({
      deliveryBoyFixedChargeError: "",
      bonusError: "",
      kmError: "",
      kmchargeError: "",
      distanceError: "",
      distance2Error: "",
      constantDeliveryChargeError: "",
      deliverySurgeChargeError: "",
      marginDeliveryChargeDistanceInKmError: "",
      variableDeliveryChargeError: "",
      variableDeliveryChargeDistanceInKmError: "",
      packingChargeError: "",
      // deliveryBoyBonusCharge: "",
    });
  };
  AddToItems = (e) => {
    
    if (Object.keys(this.state.updateShopType).length == 0) {
      alert("Nothing to update");
      return;
    } else {
      this.resetErrorMsg();
      let isValid = true;
      if (this.state.updateShopType.deliveryBoyFixedCharge === "") {
        isValid = false;
        this.setState({
          deliveryBoyFixedChargeError: "*Fixed Charge required",
        });
        console.log(this.state.deliveryBoyFixedCharge, "fixed error");
      }
      if (this.state.updateShopType.deliveryBoyBonusCharge === "") {
        isValid = false;
        this.setState({
          bonusError: "*Bonus required",
        });
        console.log(this.state.deliveryBoyBonusCharge, "bonus error");
      }
      if (this.state.updateShopType.deliveryBoyKm === "") {
        isValid = false;
        this.setState({
          kmError: "*Kilometer required",
        });
        console.log(this.state.deliveryBoyKm, "bonus error");
      }
      if (this.state.updateShopType.deliveryBoyPerKmCharge === "") {
        isValid = false;
        this.setState({
          kmchargeError: "*Kilometer Charge required",
        });
        console.log(
          this.state.updateShopType.deliveryBoyPerKmCharge,
          "Kilometer Charge error"
        );
      }
      if (this.state.updateShopType.sellerDeliveryBoyDistanceInKm === "") {
        isValid = false;
        this.setState({
          distanceError: "*Kilometer Charge required",
        });
        console.log(this.state.sellerDeliveryBoyDistanceInKm, "Distance error");
      }
      if (this.state.updateShopType.customerSellerDistanceInKm === "") {
        isValid = false;
        this.setState({
          distance2Error: "*Kilometer Charge required",
        });
        console.log(this.state.customerSellerDistanceInKm, "Distance error");
      }
      if (this.state.updateShopType.constantDeliveryCharge === "") {
        isValid = false;
        this.setState({
          constantDeliveryChargeError: "*Constant Delivery Charge required",
        });
        console.log(this.state.constantDeliveryCharge, "Distance error");
      }
      if (this.state.updateShopType.deliverySurgeCharge === "") {
        isValid = false;
        this.setState({
          deliverySurgeChargeError: "*Delivery Surge Charge required",
        });
        console.log(this.state.deliverySurgeCharge, "Distance error");
      }
      if (this.state.updateShopType.marginDeliveryChargeDistanceInKm === "") {
        isValid = false;
        this.setState({
          marginDeliveryChargeDistanceInKmError:
            "*Margin Delivery Charge Distance In Km required",
        });
        console.log(
          this.state.marginDeliveryChargeDistanceInKm,
          "Distance error"
        );
      }
      if (this.state.updateShopType.variableDeliveryCharge === "") {
        isValid = false;
        this.setState({
          variableDeliveryChargeError: "*Variable Delivery Charge required",
        });
        console.log(
          this.state.variableDeliveryCharge,
          "Variable Delivery Charge error"
        );
      }
      if (this.state.updateShopType.variableDeliveryChargeDistanceInKm === "") {
        isValid = false;
        this.setState({
          variableDeliveryChargeDistanceInKmError:
            "*Variable Delivery Charge Distance required",
        });
        console.log(
          this.state.variableDeliveryChargeDistanceInKm,
          "Variable Delivery Charge error"
        );
      }
      if (this.state.updateShopType.packingCharge === "") {
        isValid = false;

        this.setState({
          packingChargeError: "*Packing Charge Error required",
        });
        console.log(this.state.packingCharge, "packing Charge error");
      }
      if (!isValid) {
        return;
      } else {
        // document.getElementById("myForm").reset();
        this.PatchShopType();
      }
    }
  };

  PatchShopType = async () => {
    let id = this.state.shopTypeId;
    console.log(id, "🎎🎎🎎🎎🎎🎎🎎");
    const token = Cookie.get("patrickToken")
      ? Cookie.get("patrickToken")
      : null;
    const config = {
      headers: { Authorization: `Bearer ${token}` },
    };

    let data = this.state.updateShopType;
    console.log(data, "🎁🎁🎁🎁🎁🎁🎁🎁");
    // return;
    let response = await axios

      .patch(`${URL.BASE_URL}/masters/shop-type/${id}/update`, data, config)
      .catch(function (error) {
        console.log(error);
      });

    if (response && response.data.statusCode === 200) {
      this.setState({
        succsess: true,
        isRedirect: true
      });
    } else if (response && response.data.statusCode !== 200) {
      this.setState({
        error: true,
        errorCode: response.data.msg,
      });
    } else {
      this.setState({
        error: true,
      });
    }
    this.GetShopList();
    this.resetErrorMsg();
    console.log(response);
  };

  GetShoptype = async () => {
    const token = Cookie.get("patrickToken")
      ? Cookie.get("patrickToken")
      : null;
    const config = {
      headers: { Authorization: `Bearer ${token}` },
    };

    let response = await axios
      .get(`${URL.BASE_URL}/masters/shop-type/list/name`, config)
      .catch(function (error) {
        console.log(error);
      });
    // console.log("👇👇👇👇👇👇👇👇👇👇");
    // console.log(response);
    if (response && response.data !== null) {
      this.setState({
        category: response.data.data,
      });

      // console.log(this.state.category, "🎈🎈🎈🎈🎈🎈🎈");
    } else if (response && response.data.statusCode !== 200) {
      this.setState({
        error: true,
        errorCode: response.data.msg,
      });
    } else {
      this.setState({
        status: false,
      });
    }
  };
  GetShopList = async () => {
    let id = this.state.shopTypeId;
    // console.log(id, "❤️❤️❤️❤️❤️❤️❤️");
    const token = Cookie.get("patrickToken")
      ? Cookie.get("patrickToken")
      : null;
    const config = {
      headers: { Authorization: `Bearer ${token}` },
    };
    if (this.state.shopTypeId === "") {
      console.log("Shop Type Not Selected ");
      return;
    }
    let response = await axios
      .get(`${URL.BASE_URL}/masters/shop-type/${id}/details`, config)
      .catch(function (error) {
        console.log(error);
      });
    // console.log("🧧🧧🧧🧧🧧🧧🧧");
    // console.log(response);
    if (response && response.data !== null) {
      document.getElementById("myForm").reset();
      this.setState({
        shopList: response.data.data,
      });

      // console.log(this.state.shopList, "🎈🎈🎈🎈🎈🎈🎈");
    } else if (response && response.data.statusCode !== 200) {
      this.setState({
        error: true,
        errorCode: response.data.msg,
      });
    } else {
      this.setState({
        status: false,
      });
    }
  };
  componentDidMount() {
    this.GetShoptype();
  }
  handleSelect = async (e) => {
    // alert("clicked")
    e.preventDefault();
    await this.setState({
      [e.target.name]: e.target.value,
    });
    // console.log(this.state);
    this.resetErrorMsg();
    this.GetShopList();
  };

  // handleChange = async (e) => {
  //   { ...updateShopType, productName: evt.target.value }
  //   await this.setState({
  //     [e.target.name]: e.target.value,
  //     updateShopType :{
  //       [e.target.name]: e.target.value,
  //     }
  //   });

  handleChange =  (e) => {
    console.log(e.target.value, "swicth njekiiii...");
     this.setState({
      updateShopType: {
        ...this.state.updateShopType,
        [e.target.name]: e.target.value,
      },
    });

    // const newShopType = this.state.updateShopType.map((key, index) => {
    //   if (e.target.name !== key) return  ;
    //   return { ...shopType, [e.target.name]: e.target.value };
    // });
    // let updateShopType = this.state.updateShopType;
    // let shopTypeList = this.state.updateShopType;
    // console.log("-----------------------------------")
    // console.log("e.target.name : " + e.target.name)
    //  let check = false;
    //   Object.keys(shopTypeList).map(async function(key, index) {
    //     console.log("key : " + key)
    //     if (e.target.name === key){
    //       check = true
    //       updateShopType[key] =  e.target.value
    //     }
    //   });
    // console.log("-----------------------------------")

    // if(!check){
    //   updateShopType[e.target.name] =  e.target.value
    // }
    //  this.setState({ updateShopType });
  };
  // this.resetErrorMsg()

  // console.log(e.target.value, "🎈🎈🎈🎈🎈🎈🎈🎈🎈");
  // console.log(this.state, "💕💕💕💕💕💕");
  //   handleChange = (e) => {
  //     let name = e.target.name
  //     let value = e.target.value
  //    const temp = name  => {
  //        this.setState((currentState => ({
  //       shopList: { ...currentState.shopList, [name]: value },
  //     })));
  // }
  //     console.log(this.state, "💕💕💕💕💕💕");
  //   };

  Alert = () => {
    if (this.state.succsess) {
      return (
        <UncontrolledAlert color="success" fade={false}   className="mt-1">
          <span className="alert-inner--icon">
            <i className="ni ni-like-2" />
          </span>{" "}
          <span className="alert-inner--text">
            <strong>Success!</strong> Shop Type Added Successfully
          </span>
        </UncontrolledAlert>
      );
    } else if (this.state.error) {
      return (
        <UncontrolledAlert color="danger" fade={false} className="mt-1">
          <span className="alert-inner--icon"></span>{" "}
          <span className="alert-inner--text">
            <strong>{this.state.errorCode}</strong>
          </span>
        </UncontrolledAlert>
      );
    } else {
      return <></>;
    }
  };
  AddFeilds = () => {
    const style = {
      color: "red",
    };
    if (this.state.shopTypeId != "") {
      let data;
      data = this.state.shopList;
      // console.log(data, "🎗️🎗️🎗️🎗️🎗️🎗️");
      return (
        <>
          <Form id="myForm">
            <Row className=" icon-examples">
              <Col lg="6" md="4">
                <FormGroup>
                  <label htmlFor="#username">Fixed Charge</label>
                  <Input
                    name="deliveryBoyFixedCharge"
                    type="Number"
                    onChange={this.handleChange}
                    defaultValue={data.deliveryBoyFixedCharge}
                  />
                  <h4 style={style}>
                    {this.state.deliveryBoyFixedChargeError}
                  </h4>
                </FormGroup>
              </Col>
              <Col lg="6" md="4">
                <FormGroup>
                  <label htmlFor="#username">Bonus charge</label>
                  <Input
                    name="deliveryBoyBonusCharge"
                    type="Number"
                    onChange={this.handleChange}
                    defaultValue={data.deliveryBoyBonusCharge}
                  />
                  <h4 style={style}>{this.state.bonusError}</h4>
                </FormGroup>
              </Col>
              <Col lg="6" md="4">
                <FormGroup>
                  <label htmlFor="#username">Kilometer</label>
                  <Input
                    name="deliveryBoyKm"
                    type="Number"
                    onChange={this.handleChange}
                    defaultValue={data.deliveryBoyKm}
                  />

                  <h4 style={style}>{this.state.kmError}</h4>
                </FormGroup>
              </Col>
              <Col lg="6" md="4">
                <FormGroup>
                  <label htmlFor="#username">Kilometer Charge</label>
                  <Input
                    name="deliveryBoyPerKmCharge"
                    type="Number"
                    onChange={this.handleChange}
                    defaultValue={data.deliveryBoyPerKmCharge}
                  />

                  <h4 style={style}>{this.state.kmchargeError}</h4>
                </FormGroup>
              </Col>
            </Row>

            <Row>
              <Col lg="6" md="4">
                <FormGroup>
                  <label htmlFor="#username">
                    {" "}
                    Minimum distance between seller and deliveryboy
                  </label>
                  <Input
                    name="sellerDeliveryBoyDistanceInKm"
                    type="Number"
                    onChange={this.handleChange}
                    defaultValue={data.sellerDeliveryBoyDistanceInKm}
                  />

                  <h4 style={style}>{this.state.distanceError}</h4>
                </FormGroup>
              </Col>
              <Col lg="6" md="4">
                <FormGroup>
                  <label htmlFor="#username">
                    Minimum distance between customer and Seller
                  </label>
                  <Input
                    name="customerSellerDistanceInKm"
                    type="Number"
                    onChange={this.handleChange}
                    defaultValue={data.customerSellerDistanceInKm}
                  />

                  <h4 style={style}>{this.state.distance2Error}</h4>
                </FormGroup>
              </Col>
            </Row>

            <Row>
              <Col lg="6">
                <FormGroup>
                  <label htmlFor="#username"> Constant Delivery Charge</label>
                  <Input
                    name="constantDeliveryCharge"
                    type="Number"
                    onChange={this.handleChange}
                    defaultValue={data.constantDeliveryCharge}
                  />

                  <h4 style={style}>
                    {this.state.constantDeliveryChargeError}
                  </h4>
                </FormGroup>
              </Col>
              <Col lg="6" md="4">
                <FormGroup>
                  <label htmlFor="#username">Delivery Surge Charge</label>
                  <Input
                    name="deliverySurgeCharge"
                    type="Number"
                    onChange={this.handleChange}
                    defaultValue={data.deliverySurgeCharge}
                  />

                  <h4 style={style}>{this.state.deliverySurgeChargeError}</h4>
                </FormGroup>
              </Col>
            </Row>

            <Row>
              <Col lg="6" md="4">
                <FormGroup>
                  <label htmlFor="#username">
                    {" "}
                    Margin Delivery Charge Distance
                  </label>
                  <Input
                    name="marginDeliveryChargeDistanceInKm"
                    type="Number"
                    onChange={this.handleChange}
                    defaultValue={data.marginDeliveryChargeDistanceInKm}
                  />

                  <h4 style={style}>
                    {this.state.marginDeliveryChargeDistanceInKmError}
                  </h4>
                </FormGroup>
              </Col>
              <Col lg="6" md="4">
                <FormGroup>
                  <label htmlFor="#username"> Packing Charge</label>
                  <Input
                    name="packingCharge"
                    type="Number"
                    onChange={this.handleChange}
                    defaultValue={data.packingCharge}
                  />
                  <h4 style={style}>{this.state.packingChargeError}</h4>
                </FormGroup>
              </Col>
            </Row>

            <Row>
              <Col lg="6" md="4">
                <FormGroup>
                  <label htmlFor="#username"> Variable Delivery Charge</label>
                  <Input
                    name="variableDeliveryCharge"
                    type="Number"
                    onChange={this.handleChange}
                    defaultValue={data.variableDeliveryCharge}
                  />

                  <h4 style={style}>
                    {this.state.variableDeliveryChargeError}
                  </h4>
                </FormGroup>
              </Col>
              <Col lg="6" md="4">
                <FormGroup>
                  <label htmlFor="#username">
                    Variable Delivery Charge Distance
                  </label>
                  <Input
                    name="variableDeliveryChargeDistanceInKm"
                    type="Number"
                    onChange={this.handleChange}
                    defaultValue={data.variableDeliveryChargeDistanceInKm}
                  />

                  <h4 style={style}>
                    {this.state.variableDeliveryChargeDistanceInKmError}
                  </h4>
                </FormGroup>
              </Col>
            </Row>
            <Row className=" d-flex justify-content-center mr-mt-md-4">
              <Col lg="3">
                <Button
                  className="btn-icon btn-2"
                  color="info"
                  type="button"
                  block
                  onClick={this.AddToItems}
                >
                  <span className="btn-inner--icon"></span>
                  <span className="btn-inner--text">Update</span>
                </Button>
              </Col>
            </Row>
          </Form>
        </>
      );
    } else {
      return (
        <div className=" d-flex justify-content-center ">
          <Spinner
            style={{ width: "3rem", height: "3rem" }}
            type="grow"
            color="warning"
          />
        </div>
      );
    }
  };
  render() {
    // if (this.state.isRedirect) {
    //   return window.location.reload(false);
    // }
    let show = null;
    if (this.state.shopTypeId != "") {
      show = (
        <>
          <this.AddFeilds />
        </>
      );
    }
    return (
      <>
        <Header2 />

        <Container className=" mt--7" fluid>
          {/* Table */}
          <Row>
            <div className=" col">
              <Card className=" shadow">
                <CardHeader className=" bg-transparent">
                  <Row>
                    <Col>
                      <h3 className=" mb-0">Shop Type</h3>
                    </Col>

                    <Col lg="6" className="">
                      <FormGroup>
                        <Input
                          type="select"
                          name="shopTypeId"
                          onChange={this.handleSelect}
                        >
                          <option value="">Select Type</option>
                          {this.state.category.map((data, key) => {
                            return (
                              <option value={data._id}>{data.name}</option>
                            );
                          })}
                        </Input>
                      </FormGroup>
                    </Col>
                  </Row>
                </CardHeader>
                <CardBody>
                  {this.state.isRedirect === true ?   window.location.reload(false) : show }
                  
                  {/* {show} */}
                  
                  </CardBody>
              </Card>
              <this.Alert />
            </div>
          </Row>
        </Container>
      </>
    );
  }
}
