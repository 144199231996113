import React, { Component } from "react";
import { Link } from "react-router-dom";
import Select from "react-select";
import {
  Card,
  CardHeader,
  Container,
  Row,
  Col,
  Button,
  Table,
  Pagination,
  PaginationItem,
  PaginationLink,
  CardFooter,
  FormGroup,
  Input,
  InputGroupAddon,
  InputGroupText,
  InputGroup,
  Spinner,
  CustomInput,
} from "reactstrap";
import Cookie from "js-cookie";
import Header2 from "components/Headers/Header2";
const axios = require("axios").default;
const URL = require("../../ApiUrl");

export default class Seller extends Component {
  constructor(props) {
    super(props);

    this.GetSellers = this.GetSellers.bind(this);
    this.SellerDetails = this.SellerDetails.bind(this);
    this.GetSearchSellers = this.GetSearchSellers.bind(this);

    this.state = {
      succsess: false,
      error: false,
      isLoaded: false,
      limit: 20,
      page: 1,
      hasNextPage: false,
      sellers: {},
      originalData: [],
      category: [],
      id: "",
    };
  }

  GetFranchiseList = async () => {
    const token = Cookie.get("patrickToken")
      ? Cookie.get("patrickToken")
      : null;
    const config = {
      headers: { Authorization: `Bearer ${token}` },
    };

    let response = await axios
      .get(
        `${URL.BASE_URL}/admin-franchises/list?page=${1}&limit=${100}`,
        config
      )
      .catch(function (error) {
        console.log(error);
      });
    console.log("👇👇👇👇👇👇👇👇👇👇");
    console.log(response);
    if (response && response.data !== null) {
      this.setState({
        category: response.data.data.items,
      });
    } else if (response && response.data.statusCode !== 200) {
      this.setState({
        error: true,
        errorCode: response.data.msg,
      });
    } else {
      this.setState({
        status: false,
      });
    }
  };

  async GetSellers(p) {
    this.setState({ isLoaded: false });
    const token = Cookie.get("patrickToken")
      ? Cookie.get("patrickToken")
      : null;
    const config = {
      headers: { Authorization: `Bearer ${token}` },
    };
    let page = p ? p : this.state.page;
    let limit = this.state.limit;
    let id2 = id2 ? this.props.match.params.id : "";
    let response;
    if (this.state.id) {
      let id =  this.state.id
      console.log("ith sdhtym")
      response = await axios
        .get(
          `${URL.BASE_URL}/admin-accounts/all-sellers?page=${page}&limit=${limit}&franchiseId=${id}`,
          config
        )

        .catch(function (error) {
          console.log(error);
        });
    } else if (id2) {
      console.log("ith ivde")
      response = await axios
        .get(
          `${URL.BASE_URL}/admin-accounts/all-sellers?page=${page}&limit=${limit}&franchiseId=${id2}`,
          config
        )

        .catch(function (error) {
          console.log(error);
        });
    } else  {
      console.log("heree")
      response = await axios
        .get(
          `${URL.BASE_URL}/admin-accounts/all-sellers?page=${page}&limit=${limit}`,
          config
        )
        .catch(function (error) {
          console.log(error);
        });
    }

    console.log("👇👇👇👇👇👇👇👇👇👇");
    console.log(response);

    if (response && response.data !== null) {
      this.setState({
        sellers: response.data.data,
        orginalData: response.data.data,
        isLoaded: true,
      });
    } else if (response && response.data.statusCode !== 200) {
      this.setState({
        error: true,
        errorCode: response.data.msg,
      });
    } else {
      this.setState({
        status: false,
      });
    }
  }
  async GetSearchSellers(e) {
    this.setState({ isLoaded: false });
    let searchText = e.target.value;
    if (searchText === "") {
      this.GetSellers();
    } else {
      const token = Cookie.get("patrickToken")
        ? Cookie.get("patrickToken")
        : null;
      const config = {
        headers: { Authorization: `Bearer ${token}` },
      };
      let page = this.state.page;
      let limit = this.state.limit;
      let response = await axios
        .get(
          `${URL.BASE_URL}/admin-accounts/search-sellers?limit=${limit}&page=${page}&searchText=${searchText}`,
          config
        )
        .catch(function (error) {
          console.log(error);
        });

      console.log("🎉🎉🎉🎉🎉🎉SEARCHing");
      console.log(response);

      if (response && response.data !== null) {
        this.setState({
          sellers: response.data.data,
          page: 1,
          isLoaded: true,
        });
      } else if (response && response.data.statusCode !== 200) {
        this.setState({
          error: true,
          errorCode: response.data.msg,
        });
      } else {
        this.setState({
          status: false,
        });
      }
    }
  }

  componentDidMount() {
    this.GetFranchiseList();
    this.GetSellers();
  }

  SelectState = () => {
    return (
      <>
        <Col lg="14">
          <FormGroup>
            <CustomInput
              type="select"
              name="stateId"
              onChange={this.handleChange}
            >
              <option value="">Select State</option>;
              {/* {this.state.stateData.map((data, key) => {
                return <option value={data.id}>{data.name}</option>;
              })} */}
            </CustomInput>
          </FormGroup>
        </Col>
      </>
    );
  };

  SelectDistrict = () => {
    return (
      <>
        <Col lg="16">
          <FormGroup>
            <CustomInput
              type="select"
              name="districtId"
              onChange={this.handleChange}
            >
              <option value="">Select District</option>;
              {/* {this.state.districtData.map((data, key) => {
                return <option value={data.id}>{data.name}</option>;
              })} */}
            </CustomInput>
          </FormGroup>
        </Col>
      </>
    );
  };

  SelectFranchise = () => {
    return (
      <>
        <Col lg="16">
          <FormGroup>
            <CustomInput
              type="select"
              name="districtId"
              onChange={this.handleChange}
            >
              <option value="">Select Franchise</option>;
              {/* {this.state.districtData.map((data, key) => {
                return <option value={data.id}>{data.name}</option>;
              })} */}
            </CustomInput>
          </FormGroup>
        </Col>
      </>
    );
  };

  handleChange2 = async (selectedOptionType) => {
    this.setState({ selectedOptionType });
    console.log(`Option selected:`, selectedOptionType);
    console.log(selectedOptionType.value);
    await this.setState({
      id: selectedOptionType.value,
    });
    this.GetSellers();
  };
  FarnchiseSelect = () => {
    const { selectedOptionType } = this.state;
    let options = [{ value: "", label: "All" }];
    this.state.category.map((item) =>
      options.push({
        value: item.id,
        label: item.franchiseName,
      })
    );
    return (
      <>
        <Select
          value={selectedOptionType}
          onChange={this.handleChange2}
          options={options}
          placeholder="Select Franchise"
        />
      </>
    );
  };

  SellerDetails() {
    if (this.state.isLoaded) {
      return (
        <>
          <thead className="thead-light">
            <tr>
              <th scope="col">#</th>
              <th scope="col">Store Name</th>
              <th scope="col">Name</th>
              <th scope="col">Phone Number</th>
              <th scope="col">Email</th>
              <th scope="col">Actions</th>
            </tr>
          </thead>
          <tbody>
            {this.state.sellers &&
              this.state.sellers.items.map((data, key) => {
                return (
                  <tr>
                    <td>
                      {(this.state.page - 1) * this.state.limit + key + 1}
                    </td>
                    <td>{data.storeName}</td>
                    <td>{data.sellerName}</td>
                    <td>{data.mobile}</td>
                    <td>{data.email}</td>
                    <td>
                      <Link to={`/admin/sellerdetails/${data.id}`}>
                        <Button
                          className=" btn-icon"
                          color="info"
                          size="sm"
                          type="button"
                        >
                          <i className=" ni ni-circle-08 pt-1"></i>
                          <span className="btn-inner--text">View</span>
                        </Button>
                      </Link>
                    </td>
                  </tr>
                );
              })}
          </tbody>
        </>
      );
    } else {
      return (
        <div className=" d-flex justify-content-center ">
          <Spinner
            style={{ width: "3rem", height: "3rem" }}
            type="grow"
            color="warning"
          />
        </div>
      );
    }
  }
  Pagination = () => {
    let totalPages = this.state.sellers ? this.state.sellers.totalPages : 0;
    let currentPage = this.state.page;
    let pageItems = Array.apply(null, Array(totalPages)).map(function (x, i) {
      return i;
    });
    return (
      <>
        <nav aria-label="Page navigation example">
          <Pagination
            className="pagination justify-content-end"
            listClassName="justify-content-end"
          >
            {pageItems.map((item, key) => {
              if (currentPage === key + 1) {
                return (
                  <PaginationItem className="active">
                    <PaginationLink
                      href="#pablo"
                      onClick={(e) => e.preventDefault()}
                    >
                      {key + 1}
                    </PaginationLink>
                  </PaginationItem>
                );
              } else {
                return (
                  <PaginationItem>
                    <PaginationLink
                      href="#pablo"
                      onClick={(e) => {
                        e.preventDefault();
                        this.setState({ page: key + 1 });
                        this.GetSellers(key + 1);
                      }}
                    >
                      {key + 1}
                    </PaginationLink>
                  </PaginationItem>
                );
              }
            })}
          </Pagination>
        </nav>
      </>
    );
  };

  render() {
    return (
      <>
        <Header2 />
        <Container className=" mt--7" fluid>
          {/* Table */}
          <Row>
            <div className=" col">
              <Card className=" shadow">
                <CardHeader className=" border-0">
                  <Row>
                    <Col>
                      <h2 className="mb-0">Seller</h2>
                    </Col>
                    {/* <Col>
                    <this.SelectState/>
                    </Col> */}
                    {/* <Col>
                    <this.SelectDistrict/>
                    </Col> */}
                    <Col md="3">
                      <this.FarnchiseSelect />
                    </Col>
                    <Col md="4">
                      <FormGroup>
                        <InputGroup className="mb-2">
                          <InputGroupAddon addonType="prepend">
                            <InputGroupText>
                              <i className="ni ni-zoom-split-in" />
                            </InputGroupText>
                          </InputGroupAddon>
                          <Input
                            type="text"
                            id="search-input"
                            placeholder="Search for..."
                            onChange={this.GetSearchSellers}
                          />
                        </InputGroup>
                      </FormGroup>
                    </Col>
                  </Row>
                </CardHeader>

                <Table className="align-items-center table-flush" responsive>
                  <this.SellerDetails />
                </Table>

                <CardFooter className="py-4">
                  <this.Pagination />
                </CardFooter>
              </Card>
            </div>
          </Row>
        </Container>
      </>
    );
  }
}
