import React from "react";
import Switch from "react-switch";
import { Link } from "react-router-dom";
import { Spinner } from "reactstrap";

// reactstrap components
import {
  Button,
  Card,
  CardHeader,
  CardBody,
  FormGroup,
  Form,
  Input,
  Container,
  Row,
  Col,
  Table,
  Badge,
} from "reactstrap";
// core components
import Cookie from "js-cookie";
import Header2 from "components/Headers/Header2";
const axios = require("axios").default;
const URL = require("../../ApiUrl");

class SellerDetails extends React.Component {
  constructor() {
    super();
    this.state = {
      checked: false,
      isLoaded: false,
      isVerified: false,
      isAproved: false,
      isPaymentDone: false,
    };
    this.handleChange = this.handleChange.bind(this);
    this.handleChangeSwitchAproved = this.handleChangeSwitchAproved.bind(this);
    this.handleChangeSwitchPayment = this.handleChangeSwitchPayment.bind(this);
    this.handleChangeSwitchVerified = this.handleChangeSwitchVerified.bind(
      this
    );
    this.GetSellersDetails = this.GetSellersDetails.bind(this);
    this.SellerInformation = this.SellerInformation.bind(this);
    this.SellerContact = this.SellerContact.bind(this);
    this.SellerStore = this.SellerStore.bind(this);
    this.SellerBankDetails = this.SellerBankDetails.bind(this);
    this.SellerReview = this.SellerReview.bind(this);
    this.SellerCard = this.SellerCard.bind(this);
 
    this.SellerDetails = this.SellerDetails.bind(this);
    this.PatchStatus = this.PatchStatus.bind(this);
    this.SellingList = this.SellingList.bind(this);
  }

  async GetSellersDetails() {
    const token = Cookie.get("patrickToken")
      ? Cookie.get("patrickToken")
      : null;
    const config = {
      headers: { Authorization: `Bearer ${token}` },
    };
    let id = this.props.match.params.id;
    let response = await axios
      .get(`${URL.BASE_URL}/admin-accounts/sellers?id=${id}`, config)
      .catch(function (error) {
        console.log(error);
      });

    console.log("👇👇👇👇👇👇👇👇👇👇");
    console.log(response);

    if (response && response.data !== null) {
      this.setState({
        sellersdetail: response.data.data,
        isLoaded: true,
        isVerified: response.data.data.isVerified,
        isPaymentDone: response.data.data.isPaymentDone,
        isAproved: response.data.data.isAproved,
      });
    } else if (response && response.data.statusCode !== 200) {
      this.setState({
        error: true,
        errorCode: response.data.msg,
      });
    } else {
      this.setState({
        status: false,
      });
    }
  }

  async PatchStatus(e) {
    const token = Cookie.get("patrickToken")
      ? Cookie.get("patrickToken")
      : null;
    const config = {
      headers: { Authorization: `Bearer ${token}` },
    };
    console.log(config);
    let data = {
      id: this.props.match.params.id,
      isVerified: this.state.isVerified.toString(),
      isPaymentDone: this.state.isPaymentDone.toString(),
      isAproved: this.state.isAproved.toString(),
    };
    let response = await axios
      .patch(`${URL.BASE_URL}/admin-accounts/edit-sellers`, data, config)
      .catch(function (error) {
        console.log(error);
      });
    console.log(data);
    if (response && response.data.statusCode === 200) {
      this.setState({
        succsess: true,
      });
    } else if (response && response.data.statusCode !== 200) {
      this.setState({
        error: true,
        errorCode: response.data.msg,
      });
    } else {
      this.setState({
        error: true,
      });
    }

    console.log(response);
  }

  handleChange(e) {
    this.setState({
      [e.target.data]: e.target.value,
    });
  }

  componentDidMount() {
    this.GetSellersDetails();
  }

 async handleChangeSwitchVerified(checked) {
   await this.setState({ isVerified: checked });
    this.PatchStatus();
  }
  async handleChangeSwitchPayment(checked) {
   await this.setState({ isPaymentDone: checked });
    this.PatchStatus();
  }
 async handleChangeSwitchAproved(checked) {
   await this.setState({ isAproved: checked });
    this.PatchStatus();
  }

  SellerInformation() {
    let information;
    if (this.state.sellersdetail) {
      information = this.state.sellersdetail;
    }
    return (
      <>
        <h6 className="heading-small text-muted mb-4">Seller information</h6>
        <div className="pl-lg-4">
          <Row>
            <Col lg="6">
              <FormGroup>
                <label className="form-control-label" htmlFor="input-username">
                  Seller name
                </label>
                <Input
                  className="form-control-alternative"
                  placeholder="Username"
                  type="text"
                  disabled
                  value={information.sellerName}
                />
              </FormGroup>
            </Col>
            <Col lg="6">
              <FormGroup>
                <label
                  className="form-control-label"
                  htmlFor="input-first-name"
                >
                  Business type
                </label>
                <Input
                  className="form-control-alternative"
                  placeholder="First name"
                  type="text"
                  value={information.businessType}
                  disabled
                />
              </FormGroup>
            </Col>
          </Row>
          <Row>
            <Col lg="12">
              <FormGroup>
                <label className="form-control-label" htmlFor="input-email">
                  Email address
                </label>
                <Input
                  className="form-control-alternative"
                  placeholder="jesse@example.com"
                  type="email"
                  value={information.email}
                  disabled
                />
              </FormGroup>
            </Col>
          </Row>
        </div>
        <hr className="my-4" />
      </>
    );
  }
  SellerContact() {
    let contact;
    if (this.state.sellersdetail) {
      contact = this.state.sellersdetail;
    }
    return (
      <>
        <h6 className="heading-small text-muted mb-4">Contact information</h6>
        <div className="pl-lg-4">
          <Row>
            <Col md="12">
              <FormGroup>
                <label className="form-control-label" htmlFor="input-address">
                  Address
                </label>
                <Input
                  className="form-control-alternative"
                  placeholder="Home Address"
                  type="text"
                  value={contact.addressDetails.address}
                  disabled
                />
              </FormGroup>
            </Col>
          </Row>
          <Row>
            <Col lg="4">
              <FormGroup>
                <label className="form-control-label" htmlFor="input-city">
                  District
                </label>
                <Input
                  className="form-control-alternative"
                  placeholder="City"
                  type="text"
                  value={contact.addressDetails.district}
                  disabled
                />
              </FormGroup>
            </Col>
            <Col lg="4">
              <FormGroup>
                <label className="form-control-label" htmlFor="input-country">
                  State
                </label>
                <Input
                  className="form-control-alternative"
                  placeholder="Country"
                  type="text"
                  value={contact.addressDetails.state}
                  disabled
                />
              </FormGroup>
            </Col>
            <Col lg="4">
              <FormGroup>
                <label className="form-control-label" htmlFor="input-country">
                  Postal code
                </label>
                <Input
                  className="form-control-alternative"
                  placeholder="Postal code"
                  type="text"
                  value={contact.addressDetails.pincode}
                  disabled
                />
              </FormGroup>
            </Col>
          </Row>
          <Row>
            <Col lg="6">
              <FormGroup>
                <label className="form-control-label" htmlFor="input-city">
                  Location
                </label>
                <Input
                  className="form-control-alternative"
                  placeholder="City"
                  type="text"
                  value={contact.addressDetails.location}
                  disabled
                />
              </FormGroup>
            </Col>
            <Col lg="6">
              <FormGroup>
                <label className="form-control-label" htmlFor="input-country">
                  Phone number
                </label>
                <Input
                  className="form-control-alternative"
                  placeholder="1234"
                  type="text"
                  value={contact.mobile}
                  disabled
                />
              </FormGroup>
            </Col>
          </Row>
        </div>
        <hr className="my-4" />
      </>
    );
  }
  SellerStore() {
    let store;
    if (this.state.sellersdetail) {
      store = this.state.sellersdetail;
    }
    return (
      <>
        <h6 className="heading-small text-muted mb-4">Store Details</h6>
        <div className="pl-lg-4">
          <Row>
            <Col md="6">
              <FormGroup>
                <label className="form-control-label" htmlFor="input-address">
                  Store name
                </label>
                <Input
                  className="form-control-alternative"
                  placeholder="Home Address"
                  type="text"
                  value={store.storeName}
                  disabled
                />
              </FormGroup>
            </Col>
            <Col md="6">
              <FormGroup>
                <label className="form-control-label" htmlFor="input-address">
                  Store status
                </label>
                <Input
                  className="form-control-alternative"
                  type="text"
                  value={store.storeStatus}
                  disabled
                />
              </FormGroup>
            </Col>
          </Row>

          <Row>
            <Col lg="6">
              <FormGroup>
                <label className="form-control-label" htmlFor="input-city">
                  Start time
                </label>
                <Input
                  className="form-control-alternative"
                  placeholder="Time"
                  type="text"
                  value={store.startTime}
                  disabled
                />
              </FormGroup>
            </Col>
            <Col lg="6">
              <FormGroup>
                <label className="form-control-label" htmlFor="input-country">
                  End Time
                </label>
                <Input
                  className="form-control-alternative"
                  placeholder="Time"
                  type="text"
                  value={store.endTime}
                  disabled
                />
              </FormGroup>
            </Col>
          </Row>
        </div>
        <hr className="my-4" />
      </>
    );
  }
  SellerBankDetails() {
    let bank;

    if (this.state.sellersdetail) {
      bank = this.state.sellersdetail;
    }
    const isIdproof = this.state.isIdproof;
    return (
      <>
        <h6 className="heading-small text-muted mb-4">
          Bank and ID proof Details
        </h6>
        <div className="pl-lg-4">
          <Row>
            <Col md="6">
              <FormGroup>
                <label className="form-control-label" htmlFor="input-address">
                  Account number
                </label>
                <Input
                  className="form-control-alternative"
                  placeholder="Account Number"
                  type="number"
                  value={bank.accountNo}
                  disabled
                />
              </FormGroup>
            </Col>
            <Col md="6">
              <FormGroup>
                <label className="form-control-label" htmlFor="input-address">
                  Account type
                </label>
                <Input
                  className="form-control-alternative"
                  placeholder="Type"
                  type="text"
                  value={bank.accountType}
                  disabled
                />
              </FormGroup>
            </Col>
          </Row>
          <Row>
            <Col lg="6">
              <FormGroup>
                <label className="form-control-label" htmlFor="input-country">
                  Name in bank
                </label>
                <Input
                  className="form-control-alternative"
                  placeholder="Name"
                  type="text"
                  value={bank.nameInBank}
                  disabled
                />
              </FormGroup>
            </Col>
            <Col lg="6">
              <FormGroup>
                <label className="form-control-label" htmlFor="input-country">
                  IFSC code
                </label>
                <Input
                  className="form-control-alternative"
                  placeholder=""
                  type="text"
                  value={bank.ifsc}
                  disabled
                />
              </FormGroup>
            </Col>
          </Row>
          <Row>
            <Col lg="6">
              <FormGroup>
                <label className="form-control-label" htmlFor="input-city">
                  PAN number
                </label>
                <Input
                  className="form-control-alternative"
                  placeholder="Number"
                  type="number"
                  value={bank.panNo}
                  disabled
                />
              </FormGroup>
            </Col>
            <Col lg="6">
              <FormGroup>
                <label className="form-control-label" htmlFor="input-country">
                  GST number
                </label>
                <Input
                  className="form-control-alternative"
                  placeholder="Number"
                  type="number"
                  value={bank.gstNo}
                  disabled
                />
              </FormGroup>
            </Col>
          </Row>

          <Row>
            <Col lg="6">
              <FormGroup>
                <label className="form-control-label" htmlFor="input-city">
                  FSSAI number
                </label>
                <Input
                  className="form-control-alternative"
                  placeholder="Number"
                  type="number"
                  value={bank.fssai}
                  disabled
                />
              </FormGroup>
            </Col>
            <Col lg="6">
              <FormGroup>
                <label className="form-control-label" htmlFor="input-city">
                  ID Proof
                </label>
                <br />
                {bank.idProof ? <Button
                  color="primary"
                  type="button"
                  href={bank.idProof.publicUrl}
                  target="_blank"
                >
                  Show
                </Button> : <Button
                color="primary"
                type="button"
                target="#"
              >
                Show
              </Button>}
                
              </FormGroup>
            </Col>
          </Row>
        </div>
        <hr className="my-4" />
      </>
    );
  }
  ProductList = () => {
    let review = [];
    if (this.state.sellersdetail) {
      review = this.state.sellersdetail.productIds;
    }
    return (
      <>
        {/* PRODUCTS contenTS */}
        <Card className="card-profile shadow">
          <Row className="justify-content-center">
            <Col className="order-lg-2" lg="3"></Col>
          </Row>

          <CardBody className="pt-0 pt-md-4">
            <Table responsive>
              <thead>
                <tr>
                  <th className=" text-center">#</th>
                  <th>Products</th>
                  <th>Price</th>
                </tr>
              </thead>
              <tbody>
                {review.map((data, key) => {
                  return (
                    <tr>
                      <td className=" text-center">{key + 1}</td>
                      <td>{data.name}</td>
                      <td>₹{data.actualPrice}</td>
                    </tr>
                  );
                })}
              </tbody>
            </Table>
          </CardBody>
        </Card>
        <br />
      </>
    );
  }
  SellingList() {
    let review = [];
    if (this.state.sellersdetail) {
      review = this.state.sellersdetail.sellingCategory;
    }
    return (
      <>
        {/* Selling category */}
        <Card className="card-profile shadow">
          <Row className="justify-content-center">
            <Col className="order-lg-2" lg="3"></Col>
          </Row>

          <CardBody className="pt-0 pt-md-4">
            <Table>
              <thead>
                <tr>
                  <th className=" text-center">#</th>
                  <th>Selling category</th>
                </tr>
              </thead>
              <tbody>
                {review.map((data, key) => {
                  return (
                    <tr>
                      <td className=" text-center">{key + 1}</td>
                      <td>{data.name}</td>
                    </tr>
                  );
                })}
              </tbody>
            </Table>
          </CardBody>
        </Card>
      </>
    );
  }
  SellerReview = () => {
    let review;
    if (this.state.sellersdetail) {
      review = this.state.sellersdetail.reviews;
      return (
        <>
          <h6 className="heading-small text-muted mb-4">Reviews</h6>
          <div className="pl-lg-4">
            <FormGroup>
              <label>Seller Reviews</label>
              {review.map((data, key) => {
                return (
                  <>
                    <br />
                    Customer : &nbsp;
                    <Link to={`/admin/sellerdetails/${data.sellerId.id}`}>
                      {data.sellerId.sellerName}
                    </Link>
                    <br />
                    <Badge className="badge-info">
                      Rating: {data.rating}/5
                    </Badge>
                    &nbsp;{data.title}
                    <br />
                    &nbsp;
                    <Form>
                      <Input
                        className="form-control-alternative"
                        rows="3"
                        type="textarea"
                        value={data.review}
                        disabled
                      />
                    </Form>
                  </>
                );
              })}
            </FormGroup>
          </div>
        </>
      );
    } else {
      return <></>;
    }
  };
  SellerCard() {
    let card = {
      sellerName: " ",
      isVerified: false,
      isPaymentDone: false,
      isAproved: false,
      profileImageId: {},
    };
    if (this.state.sellersdetail) {
      card = this.state.sellersdetail;
    }
    
    return (
      <>
        <Card className="card-profile shadow">
          <Row className="justify-content-center">
            <Col className="order-lg-2" lg="3">
              <div className="card-profile-image">
                <a href="#pablo" onClick={(e) => e.preventDefault()}></a>
                <a href="#pablo" onClick={(e) => e.preventDefault()}>
                  {card.profileImageId ? (
                    <img
                      alt="Loading..."
                      src={card.profileImageId.publicUrl}
                      width="150px"
                    />
                  ) : (
                    <img
                      alt="..."
                      className="rounded-circle"
                      src={require("assets/img/theme/team-4-800x800.jpg")}
                    />
                  )}
                </a>
              </div>
            </Col>
          </Row>
          <CardHeader className="text-center border-0 pt-8 pt-md-4 pb-0 pb-md-4">
            <div className="d-flex justify-content-between"></div>
          </CardHeader>
          <CardBody className="pt-0 pt-md-4">
            <Row>
              <div className="col">
                <div className="card-profile-stats d-flex justify-content-center mt-md-5"></div>
              </div>
            </Row>
            <div className="text-center">
              <h3>{card.sellerName}</h3>

              <hr className="my-4" />
              <Row className="justify-content-lg-center">
                <span>Verified &nbsp;</span>
                <Switch
                  onChange={this.handleChangeSwitchVerified}
                  checked={this.state.isVerified}
                />
              </Row>
              <hr className="my-4" />
              <Row className="justify-content-lg-center">
                <span>Payment Done &nbsp;</span>
                <Switch
                  onChange={this.handleChangeSwitchPayment}
                  checked={this.state.isPaymentDone}
                />
              </Row>
              <hr className="my-4" />
              <Row className="justify-content-lg-center">
                <span>Aproved &nbsp; </span>
                <Switch
                  onChange={this.handleChangeSwitchAproved}
                  checked={this.state.isAproved}
                />
              </Row>
              <hr className="my-4" />
            </div>
          </CardBody>
        </Card>
        <br />
      </>
    );
  }

  SellerDetails() {
    if (this.state.isLoaded) {
      return (
        <>
          <Form>
            <this.SellerInformation />
            {/* Address */}
            <this.SellerContact />
            {/* Store Details */}
            <this.SellerStore />
            {/* Bank and ID Details */}
            <this.SellerBankDetails />
            {/* Reviews */}
            <this.SellerReview />
          </Form>
        </>
      );
    } else {
      return (
        <>
          <Spinner
            color="warning"
            style={{ width: "4rem", height: "4rem", marginLeft: "45%" }}
          />{" "}
        </>
      );
    }
  }

  render() {
    return (
      <>
        <Header2 />
        {/* Page content */}
        <Container className="mt--7" fluid>
          <Row>
            <Col className="order-xl-2 mb-5 mb-xl-0" xl="4">
              <this.SellerCard />
              <this.ProductList />
              <this.SellingList />
            </Col>
            <Col className="order-xl-1" xl="8">
              <Card className="bg-secondary shadow">
                <CardHeader className="bg-white border-0">
                  <Row className="align-items-center">
                    <Col xs="8">
                      <h3 className="mb-0">Seller Details</h3>
                    </Col>
                  </Row>
                </CardHeader>
                <CardBody>
                  <this.SellerDetails />
                </CardBody>
              </Card>
            </Col>
          </Row>
        </Container>
      </>
    );
  }
}

export default SellerDetails;
