import React from "react";
// import Switch from "react-switch";
import { Spinner } from "reactstrap";
import { Link } from "react-router-dom";
// reactstrap components
import {
  Card,
  CardHeader,
  CardBody,
  FormGroup,
  Form,
  Input,
  Container,
  Row,
  Col,
  Badge,
} from "reactstrap";
// core components
import Cookie from "js-cookie";
import Header2 from "components/Headers/Header2";
const axios = require("axios").default;
const URL = require("../../ApiUrl");

class CustomerDetails extends React.Component {
  state = {
    checked: false,
    isLoaded: false,
    isVerified: false,
    isprofileImageId: false,
  };

  Getcustomerdetails = async () => {
    const token = Cookie.get("patrickToken")
      ? Cookie.get("patrickToken")
      : null;
    const config = {
      headers: { Authorization: `Bearer ${token}` },
    };
    let id = this.props.match.params.id;
    let response = await axios
      .get(`${URL.BASE_URL}/admin-accounts/customers?id=${id}`, config)
      .catch(function (error) {
        console.log(error);
      });

    console.log("👇👇👇👇👇👇👇👇👇👇");
    console.log(response);

    if (response && response.data !== null) {
      this.setState({
        customerdetail: response.data.data,
        isLoaded: true,
        isVerified: response.data.data.isVerified,
      });
    } else if (response && response.data.statusCode !== 200) {
      this.setState({
        error: true,
        errorCode: response.data.msg,
      });
    } else {
      this.setState({
        status: false,
      });
    }
  };
  PatchStatus = async () => {
    const token = Cookie.get("patrickToken")
      ? Cookie.get("patrickToken")
      : null;
    const config = {
      headers: { Authorization: `Bearer ${token}` },
    };
    console.log(config);
    let data = {
      id: this.props.match.params.id,
      isVerified: this.state.isVerified.toString(),
    };
    let response = await axios
      .patch(`${URL.BASE_URL}/admin-accounts/edit-customers`, data, config)
      .catch(function (error) {
        console.log(error);
      });
    console.log(data);
    if (response && response.data.statusCode === 200) {
      this.setState({
        succsess: true,
      });
    } else if (response && response.data.statusCode !== 200) {
      this.setState({
        error: true,
        errorCode: response.data.msg,
      });
    } else {
      this.setState({
        error: true,
      });
    }
    console.log(response);
  };

  handleChange = (e) => {
    this.setState({
      [e.target.data]: e.target.value,
    });
  };

  componentDidMount = () => {
    this.Getcustomerdetails();
  };
  handleChangeSwitchVerified = (checked) => {
    this.setState({ isVerified: checked });
    this.PatchStatus();
  };

  handleChangeSwitch = (checked) => {
    this.setState({ checked });
  };

  CustomerCard = () => {
    let card = {
      firstName: " ",
      isVerified: false,
      rewardPoint: "",
      profileImageId: {},
    };
    if (this.state.customerdetail) {
      card = this.state.customerdetail;
    }

    return (
      <>
        <Card className="card-profile shadow">
          <Row className="justify-content-center">
            <Col className="order-lg-2" lg="3">
              <div className="card-profile-image">
                <a href="#pablo" onClick={(e) => e.preventDefault()}>
                  {card.profileImageId ? (
                    <img
                      alt="Loading..."
                      src={card.profileImageId.publicUrl}
                      width="150px"
                    />
                  ) : (
                    <img
                      alt="..."
                      className="rounded-circle"
                      src={require("assets/img/theme/team-4-800x800.jpg")}
                    />
                  )}
                </a>
              </div>
            </Col>
          </Row>
          <CardHeader className="text-center border-0 pt-8 pt-md-4 pb-0 pb-md-4">
            <div className="d-flex justify-content-between"></div>
          </CardHeader>
          <CardBody className="pt-0 pt-md-4">
            <Row>
              <div className="col">
                <div className="card-profile-stats d-flex justify-content-center mt-md-5"></div>
              </div>
            </Row>
            <div className="text-center">
              <h3>{card.firstName}</h3>

              {/*<hr className="my-4" />
              <Row className="justify-content-lg-center">
                <span>Verified &nbsp;</span>
                <Switch
                  onChange={this.handleChangeSwitchVerified}
                  checked={this.state.isVerified}
                />
              </Row>
              <hr className="my-4" />*/}
            </div>
            <Row>
              <div className="col">
                <div className="card-profile-stats d-flex justify-content-center mt-md-5">
                  <div>
                    <span className="heading">{card.rewardPoint}</span>
                    <span className="description">Total Rewards</span>
                  </div>
                </div>
              </div>
            </Row>
          </CardBody>
        </Card>
        <br />
      </>
    );
  };

  CustomerDetails = () => {
    let information;
    let contact = [];
    let review = [];
    let Creview;
    if (this.state.customerdetail) {
      information = this.state.customerdetail;
      contact = this.state.customerdetail.addressIds;
      review = this.state.customerdetail.productReviewIds;
      Creview = this.state.customerdetail.sellerReviewIds;
    }
    if (this.state.isLoaded) {
      return (
        <>
          <h6 className="heading-small text-muted mb-4">
            Customer information
          </h6>
          <div className="pl-lg-4">
            <Row>
              <Col lg="6">
                <FormGroup>
                  <label
                    className="form-control-label"
                    htmlFor="input-username"
                  >
                    First name
                  </label>
                  <Input
                    className="form-control-alternative"
                    type="text"
                    value={information.firstName}
                    disabled
                  />
                </FormGroup>
              </Col>
              <Col lg="6">
                <FormGroup>
                  <label
                    className="form-control-label"
                    htmlFor="input-first-name"
                  >
                    Last name
                  </label>
                  <Input
                    className="form-control-alternative"
                    type="text"
                    value={information.lastName}
                    disabled
                  />
                </FormGroup>
              </Col>
            </Row>
            <Row>
              <Col lg="6">
                <FormGroup>
                  <label className="form-control-label" htmlFor="input-email">
                    Email address
                  </label>
                  <Input
                    className="form-control-alternative"
                    type="email"
                    value={information.email}
                    disabled
                  />
                </FormGroup>
              </Col>
              <Col lg="6">
                <FormGroup>
                  <label className="form-control-label" htmlFor="input-email">
                    Mobile number
                  </label>
                  <Input
                    className="form-control-alternative"
                    type="text"
                    value={information.mobile}
                    disabled
                  />
                </FormGroup>
              </Col>
            </Row>
          </div>
          <hr className="my-4" />
          <h6 className="heading-small text-muted mb-4">Contact information</h6>
          <div className="pl-lg-4">
            <Row>
              {contact.map((data, key) => {
                return (
                  <>
                    <Col md="6">
                      <FormGroup>
                        <label
                          className="form-control-label"
                          htmlFor="input-address"
                        >
                          Address {key + 1}
                        </label>
                        <p>
                          {data.name}
                          <br />
                          {data.address1}&nbsp;
                          {data.city},&nbsp;
                          <br />
                          {data.state},&nbsp;
                          {data.country},&nbsp;{data.pincode}
                          <br />
                          {data.landMark}&nbsp;
                          <br />
                          {data.contactNumber}
                        </p>
                      </FormGroup>
                    </Col>
                  </>
                );
              })}
            </Row>
          </div>
          <hr className="my-4" />
          <h6 className="heading-small text-muted mb-4">Reviews</h6>
          <div className="pl-lg-4">
            <FormGroup>
              <h3>Product Reviews</h3>
              {review.map((data, key) => {
                return (
                  <>
                    <br />
                    Product : &nbsp;
                    <Link
                      to={`/admin/productsdetails/${data.productId.id}/detail`}
                    >
                      {data.productId.name}
                    </Link>
                    <br />
                    Seller : &nbsp;
                    <Link
                      to={`/admin/sellerdetails/${data.productId.sellerId.id}`}
                    >
                      {data.productId.sellerId.sellerName}
                    </Link>
                    <br />
                    <Badge href="#" color="info">
                      Rating: {data.rating}/5
                    </Badge>
                    &nbsp;{data.reviewTitle}
                    <br />
                    &nbsp;
                    <Form>
                      <Input
                        className="form-control-alternative"
                        rows="3"
                        type="textarea"
                        value={data.review}
                        disabled
                      />
                    </Form>
                  </>
                );
              })}
            </FormGroup>
          </div>
          <hr className="my-4" />
          <div className="pl-lg-4">
            <FormGroup>
              <h3>Seller Reviews</h3>
              {Creview.map((data, key) => {
                return (
                  <>
                    <br />
                    Seller : &nbsp;
                    <Link to={`/admin/customerdetails/${data.sellerId.id}`}>
                      {data.sellerId.sellerName}
                    </Link>
                    <br />
                    <Badge className="badge-info">
                      Rating: {data.rating}/5
                    </Badge>
                    &nbsp;{data.title}
                    <br />
                    &nbsp;
                    <Form>
                      <Input
                        className="form-control-alternative"
                        rows="3"
                        type="textarea"
                        value={data.review}
                        disabled
                      />
                    </Form>
                  </>
                );
              })}
            </FormGroup>
          </div>
        </>
      );
    } else {
      return (
        <>
          <Spinner
            color="warning"
            style={{ width: "4rem", height: "4rem", marginLeft: "45%" }}
          />{" "}
        </>
      );
    }
  };

  render() {
    return (
      <>
        <Header2 />
        {/* Page content */}
        <Container className="mt--7" fluid>
          <Row>
            <Col className="order-xl-2 mb-5 mb-xl-0" xl="4">
              <this.CustomerCard />
            </Col>
            <Col className="order-xl-1" xl="8">
              <Card className="bg-secondary shadow">
                <CardHeader className="bg-white border-0">
                  <Row className="align-items-center">
                    <Col xs="8">
                      <h3 className="mb-0">Customer Details</h3>
                    </Col>
                  </Row>
                </CardHeader>
                <CardBody>
                  <Form>
                    <this.CustomerDetails />
                  </Form>
                </CardBody>
              </Card>
            </Col>
          </Row>
        </Container>
      </>
    );
  }
}

export default CustomerDetails;
