import React, { Component } from "react";
import Header2 from "../../components/Headers/Header2";
import {
  Card,
  CardHeader,
  Container,
  Row,
  Col,
  CardBody,
  FormGroup,
  Label,
  Button,
  Input,
  UncontrolledAlert,
  FormText,
  Spinner,
  Table,
  Modal,
  CardFooter,
} from "reactstrap";
import Cookie from "js-cookie";
const axios = require("axios").default;
const URL = require("../ApiUrl");
const style = {
  color: "red",
};
export default class MainSerivceCategory extends Component {
  state = {
    name: "",
    isLoaded: false,
    isEdit: false,
  };
  PostSellerCategory = async (e) => {
    e.preventDefault();
    const token = Cookie.get("patrickToken")
      ? Cookie.get("patrickToken")
      : null;
    const config = {
      headers: { Authorization: `Bearer ${token}` },
    };
    this.setState({
      sellerCategoryError: "",
    });
    let data = {
      name: this.state.name,
    };
    if (this.state.name === "") {
      this.setState({
        sellerCategoryError: "*Name cannot be empty",
      });
      return;
    }
    console.log(data);
    let response = await axios
      .post(`${URL.BASE_URL}/masters/service-main-category/add`, data, config)
      .catch(function (error) {
        console.log(error);
      });
    console.log(data);
    if (response && response.data.statusCode === 200) {
      this.setState({
        succsess: true,
        isRedirect: true,
      });
    } else if (response && response.data.statusCode !== 200) {
      this.setState({
        error: true,
        errorCode: response.data.msg,
      });
    } else {
      this.setState({
        error: true,
      });
    }
    this.GetSellers();
    console.log(response);
  };
  GetSellers = async () => {
    this.setState({ isLoaded: false });
    const token = Cookie.get("patrickToken")
      ? Cookie.get("patrickToken")
      : null;
    const config = {
      headers: { Authorization: `Bearer ${token}` },
    };
    let response;
    response = await axios
      .get(
        `${URL.BASE_URL}/masters/service-main-category/list?page=1&perPage=30`,
        config
      )
      .catch(function (error) {
        console.log(error);
      });
    console.log("👇👇👇👇👇👇👇👇👇👇");
    console.log(response);

    if (response && response.data !== null) {
      this.setState({
        sellers: response.data.data,
        isLoaded: true,
      });
    } else if (response && response.data.statusCode !== 200) {
      this.setState({
        error: true,
        errorCode: response.data.msg,
      });
    } else {
      this.setState({
        status: false,
      });
    }
  };
  componentDidMount() {
    this.GetSellers();
  }
  handleChange = (e) => {
    this.setState({
      [e.target.name]: e.target.value,
    });
    console.log(this.state);
  };
  Alert = () => {
    if (this.state.succsess) {
      return (
        <UncontrolledAlert color="success" fade={false} className="mt-1">
          <span className="alert-inner--icon">
            <i className="ni ni-like-2" />
          </span>{" "}
          <span className="alert-inner--text">
            <strong>Success!</strong> Shop Category Added Successfully
          </span>
        </UncontrolledAlert>
      );
    } else if (this.state.error) {
      return (
        <UncontrolledAlert color="danger" fade={false} className="mt-1">
          <span className="alert-inner--icon"></span>{" "}
          <span className="alert-inner--text">
            <strong>{this.state.errorCode}</strong>
          </span>
        </UncontrolledAlert>
      );
    } else {
      return <></>;
    }
  };
  AddField = () => {
    return (
      <>
        <Row className="justify-content-center">
          <Col lg="4">
            <FormGroup>
              <Input
                id="input-username"
                placeholder="Name"
                type="text"
                name="name"
                onChange={this.handleChange}
              />
              <h4 style={style}>{this.state.sellerCategoryError}</h4>
            </FormGroup>
            <div className="text-center">
              <Button
                color="primary"
                type="button"
                size="lg"
                onClick={this.PostSellerCategory}
              >
                Add
              </Button>
            </div>
          </Col>
        </Row>
      </>
    );
  };
  toggleModal = (state, id) => {
    this.setState({
      [state]: !this.state[state],
      deleteId: id,
    });
  };
  handleEditClick = (id) => {
    this.setState({ isEdit: !this.state.isEdit, update: id });
  };
  PatchSellerCategory = async () => {
    let id = this.state.update;
    console.log(id,"🎈🎈🎈🎈🎈🎈🎈")
    const token = Cookie.get("patrickToken")
      ? Cookie.get("patrickToken")
      : null;
    const config = {
      headers: { Authorization: `Bearer ${token}` },
    };
    console.log(config);
    let data = {
      name: this.state.name,
    };
    console.log(data, "🎄🎄🎄🎄🎄🎄");

    let response = await axios

      .patch(
        `${URL.BASE_URL}/masters/service-main-category/${id}/update`,
        data,
        config
      )
      .catch(function (error) {
        console.log(error);
      });

    if (response && response.data.statusCode === 200) {
      this.setState({});
    } else if (response && response.data.statusCode !== 200) {
      this.setState({
        error: true,
        errorCode: response.data.msg,
      });
    } else {
      this.setState({
        error: true,
      });
    }
    this.GetSellers();
    this.handleEditClick();
    console.log(response);
  };
  DeleteShift = async (key) => {
    let id = this.state.deleteId;
    console.log(id,"RAMRAMRAMRAMRAM")
    const token = Cookie.get("patrickToken")
      ? Cookie.get("patrickToken")
      : null;
    const config = {
      headers: { Authorization: `Bearer ${token}` },
    };
    let response = await axios
      .delete(`${URL.BASE_URL}/masters/shop-category/${id}/delete`, config)
      .catch(function (error) {
        console.log(error);
      });
    if (response.data.statusCode === 200) {
      this.setState({
        isRedirect: true,
      });
    }
    this.GetSellers();
    this.toggleModal("notificationModal");
  };
  Delete = () => {
    return (
      <>
        <Col md="12">
          <Modal
            className="modal-dialog-centered modal-danger"
            contentClassName="bg-gradient-danger"
            isOpen={this.state.notificationModal}
            toggle={() => this.toggleModal("notificationModal")}
          >
            <div className="modal-header">
              <h6 className="modal-title" id="modal-title-notification">
                Your confirmation is required
              </h6>
              <button
                aria-label="Close"
                className="close"
                data-dismiss="modal"
                type="button"
                onClick={() => this.toggleModal("notificationModal")}
              >
                <span aria-hidden={true}>×</span>
              </button>
            </div>
            <div className="modal-body">
              <div className="py-3 text-center">
                <i class="fas fa-trash-alt fa-3x"></i>
                <h4 className="heading mt-4">ATTENTION !!</h4>
                <p>Are you sure want to delete this Category ?</p>
                <p>You can't undo this operation!</p>
              </div>
            </div>
            <div className="modal-footer">
              <Button
                className="btn-white"
                color="default"
                type="button"
                onClick={() => this.DeleteShift("id")}
              >
                Ok, Delete
              </Button>
              <Button
                className="text-white ml-auto"
                color="link"
                data-dismiss="modal"
                type="button"
                onClick={() => this.toggleModal("notificationModal")}
              >
                Close
              </Button>
            </div>
          </Modal>
        </Col>
      </>
    );
  };
  SellerCategoryList = () => {
    let isEdit = this.state.isEdit;
    if (this.state.isLoaded) {
      return (
        <>
          <thead className="thead-light">
            <tr>
              <th scope="col">#</th>
              <th>Name</th>
              <th scope="text-end">Actions</th>
            </tr>
          </thead>
          <tbody>
            {this.state.sellers.items.map((data, key) => {
              return (
                <tr>
                  <td>{key + 1}</td>
                  <td>
                    {isEdit && data.id === this.state.update ? (
                      <Input
                        id="input-username"
                        placeholder="Name"
                        type="text"
                        name="name"
                        defaultValue={data.name}
                        onChange={this.handleChange}
                      />
                    ) : (
                      data.name
                    )}
                  </td>

                  <td scope="text-center">
                    {isEdit && data.id === this.state.update ? (
                      <Button
                        className="btn-tooltip mr-3"
                        color="success"
                        size="sm"
                        type="button"
                        onClick={() => this.PatchSellerCategory(data.id)}
                      >
                        <span className="btn-inner--icon">
                          <i className="ni ni-check-bold" />
                        </span>
                        <span className="btn-inner--text"> Save</span>
                      </Button>
                    ) : (
                      <Button
                        className="btn-tooltip mr-3"
                        color="success"
                        size="sm"
                        type="button"
                        onClick={() => this.handleEditClick(data.id)}
                      >
                        <span className="btn-inner--icon">
                          <i className="ni ni-ruler-pencil" />
                        </span>
                        <span className="btn-inner--text">Edit</span>
                      </Button>
                    )}

                    {isEdit && data.id === this.state.update ? (
                      <Button
                        className="btn-tooltip mr-3"
                        color="danger"
                        size="sm"
                        type="button"
                        onClick={this.handleEditClick}
                      >
                        <span className="btn-inner--icon">
                          <i className="ni ni-fat-remove" />
                        </span>
                        <span className="btn-inner--text">Close</span>
                      </Button>
                    ) : (
                      <Button
                        className="btn-tooltip mr-3"
                        color="danger"
                        size="sm"
                        type="button"
                        onClick={() =>
                          this.toggleModal("notificationModal", data.id)
                        }
                      >
                        <span className="btn-inner--icon">
                          <i className="fas fa-trash-alt" />
                        </span>
                        <span className="btn-inner--text"> Delete</span>
                      </Button>
                    )}
                  </td>
                </tr>
              );
            })}
          </tbody>
        </>
      );
    } else {
      return (
        <div className=" d-flex justify-content-center ">
          <Spinner
            style={{ width: "3rem", height: "3rem" }}
            type="grow"
            color="warning"
          />
        </div>
      );
    }
  };

  render() {
    return (
      <>
        <Header2 />
        <Container className=" mt--7" fluid>
          {/* Table */}
          <Row>
            <div className=" col">
              <Card className=" shadow">
                <CardHeader className=" bg-transparent">
                  <h3 className=" mb-0">Main Serivce Category</h3>
                </CardHeader>
                <CardBody>
                  <this.AddField />
                </CardBody>
              </Card>
              <this.Alert />
            </div>
          </Row>
        </Container>
        <br />
        <Container fluid>
          <Card className=" shadow">
            <CardHeader className=" bg-transparent">
              <h3 className=" mb-0">Main Serivce Category List</h3>
            </CardHeader>
            <Table className="align-items-center table-flush" responsive>
              <this.SellerCategoryList />
            </Table>

            <CardFooter className="py-4"></CardFooter>
          </Card>
          <this.Delete />
        </Container>
      </>
    );
  }
}
