import React, { Component } from "react";
import Header2 from "../../../components/Headers/Header2";
import {
  Card,
  CardHeader,
  Container,
  Row,
  Col,
  CardBody,
  FormGroup,
  Input,
  Button,
} from "reactstrap";

export default class Report extends Component {
  render() {
    return (
      <>
        <Header2 />
        <Container className=" mt--7" fluid>
          {/* Table */}
          <Row>
            <div className=" col">
              <Card className=" shadow">
                <CardHeader className=" bg-transparent">
                  <h3 className=" mb-0">Commision Report</h3>
                </CardHeader>
                <CardBody>
                  <Card
                    className="mb-4 mb-lg-0 shadow"
                    style={{
                      background:
                        "linear-gradient(90deg, rgba(45,206,143,1) 0%, rgba(45,206,200,1) 100%)",
                    }}
                  >
                    <CardBody>
                      <Row>
                        <Col md="6">
                          <FormGroup>
                            <label
                              className="form-control-label"
                              htmlFor="input-username"
                              style = {{"color" : "white", "fontSize" : "18px"}}
                            >
                              From Date
                            </label>
                            <Input
                              className="form-control-alternative"
                              id="exampleFormControlInput1"
                              placeholder="name@example.com"
                              type="date"
                            />
                          </FormGroup>
                        </Col>
                        <Col md="6">
                          <FormGroup>
                          <label
                              className="form-control-label"
                              htmlFor="input-username"
                              style = {{"color" : "white", "fontSize" : "18px"}}
                            >
                              End Date
                            </label>
                            <Input
                              className="form-control-alternative"
                              id="exampleFormControlInput1"
                              placeholder="name@example.com"
                              type="date"
                            />
                          </FormGroup>
                        </Col>
                      </Row>

                      <Row className=" d-flex justify-content-center mr-mt-md-4">
                        <Col lg="3">
                          <Button
                            className="btn-icon btn-2"
                            color="secondary"
                            type="button"
                            block
                          >
                            <span className="btn-inner--icon">
                              <i class="ni ni-spaceship"></i>
                            </span>
                            <span className="btn-inner--text">Download</span>
                          </Button>
                        </Col>
                      </Row>
                    </CardBody>
                  </Card>
                </CardBody>
              </Card>
            </div>
          </Row>
        </Container>
      </>
    );
  }
}
