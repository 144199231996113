import React from "react";
import Switch from "react-switch";
import { Link } from "react-router-dom";

import {
  Button,
  Card,
  CardHeader,
  CardBody,
  FormGroup,
  Form,
  Input,
  Container,
  Row,
  Col,
  Modal,
  Label,
  CustomInput,
  Spinner,
} from "reactstrap";
// core components
import Cookie from "js-cookie";
import Header2 from "components/Headers/Header2";
import { Redirect } from "react-router-dom";

const axios = require("axios").default;
const URL = require("../../ApiUrl");

class FranchiseDetails extends React.Component {
  state = {
    name: "",
    defaultModal: false,
    isLoaded: false,
    selectedParent: {},
    parentId: null,
    id: {},
    franchiseName: "",
    email: "",
    countryId: "",
    stateId: "",
    districtId: "",
    cityId: "",
    address: "",
    pincode: "",
    validPincodes: "",
    profileImageId: "",
    gstNo: "",
    panNo: "",
    idProof: "",
    nameInBank: "",
    accountType: "",
    accountNo: "",
    ifsc: "",
    mobile: "",
    countryData: "",
    stateData: "",
    districtData: "",
    cityData: "",
    commission: "",
    isEnabled: false,
    isAnyDeliverySurge: false,
  };
  PostState = async (e) => {
    e.preventDefault();
    const token = Cookie.get("patrickToken")
      ? Cookie.get("patrickToken")
      : null;
    const config = {
      headers: { Authorization: `Bearer ${token}` },
    };
    console.log(config);

    let data = {
      name: this.state.name,
      countryId: "60266020794814098914c874",
    };

    let response = await axios
      .post(`${URL.BASE_URL}/masters/countries/addstate`, data, config)
      .catch(function (error) {
        console.log(error);
      });

    if (response && response.data.statusCode === 200) {
      this.setState({
        succsess: true,
      });
    } else if (response && response.data.statusCode !== 200) {
      this.setState({
        error: true,
        errorCode: response.data.msg,
      });
    } else {
      this.setState({
        error: true,
        errorCode: "Oops something went wrong!!",
      });
    }
    this.GetLocation();
    setTimeout(() => {
      this.setState({
        status: false,
        error: false,
      });
    }, 2000);

    console.log(response);
  };
  PostDistrict = async (e) => {
    e.preventDefault();
    const token = Cookie.get("patrickToken")
      ? Cookie.get("patrickToken")
      : null;
    const config = {
      headers: { Authorization: `Bearer ${token}` },
    };
    console.log(config);

    let data = {
      name: this.state.name,

      countryId: "60266020794814098914c874",
      stateId: this.state.stateId,
    };

    let response = await axios
      .post(`${URL.BASE_URL}/masters/countries/adddist`, data, config)
      .catch(function (error) {
        console.log(error);
      });

    if (response && response.data.statusCode === 200) {
      this.setState({
        succsess: true,
      });
    } else if (response && response.data.statusCode !== 200) {
      this.setState({
        error: true,
        errorCode: response.data.msg,
      });
    } else {
      this.setState({
        error: true,
        errorCode: "Oops something went wrong!!",
      });
    }
    this.GetLocation();
    setTimeout(() => {
      this.setState({
        status: false,
        error: false,
      });
    }, 2000);

    console.log(response);
  };
  PostCity = async (e) => {
    e.preventDefault();
    const token = Cookie.get("patrickToken")
      ? Cookie.get("patrickToken")
      : null;
    const config = {
      headers: { Authorization: `Bearer ${token}` },
    };
    console.log(config);

    let data = {
      name: this.state.name,

      countryId: "60266020794814098914c874",
      stateId: this.state.stateId,
      districtId: this.state.districtId,
    };

    let response = await axios
      .post(`${URL.BASE_URL}/masters/countries/addcity`, data, config)
      .catch(function (error) {
        console.log(error);
      });

    if (response && response.data.statusCode === 200) {
      this.setState({
        succsess: true,
      });
    } else if (response && response.data.statusCode !== 200) {
      this.setState({
        error: true,
        errorCode: response.data.msg,
      });
    } else {
      this.setState({
        error: true,
        errorCode: "Oops something went wrong!!",
      });
    }
    this.GetLocation();
    setTimeout(() => {
      this.setState({
        status: false,
        error: false,
      });
    }, 2000);

    console.log(response);
  };
  GetLocation = async (name, val) => {
    console.log(name, val);
    let countryResponse, stateResponse, districtResponse, cityResponse;

    countryResponse = await axios
      .get(`${URL.BASE_URL}/masters/countries/list`)
      .catch(function (error) {
        console.log(error);
      });

    if (countryResponse && countryResponse.data !== null) {
      this.setState({
        countryData: countryResponse.data.data,
      });
    } else if (countryResponse && countryResponse.data.statusCode !== 200) {
      this.setState({
        error: true,
        errorCode: countryResponse.data.msg,
      });
    }

    if (name === "countryId") {
      stateResponse = await axios
        .get(`${URL.BASE_URL}/masters/states/list?countryId=${val}`)
        .catch(function (error) {
          console.log(error);
        });

      if (stateResponse && stateResponse.data !== null) {
        this.setState({
          stateData: stateResponse.data.data,
        });
      } else if (stateResponse && stateResponse.data.statusCode !== 200) {
        this.setState({
          error: true,
          errorCode: stateResponse.data.msg,
        });
      }
      console.log("stat++++++ ", stateResponse);
    }

    if (name === "stateId") {
      districtResponse = await axios
        .get(
          `${URL.BASE_URL}/masters/districts/list?countryId=${this.state.countryId}&stateId=${val}`
        )
        .catch(function (error) {
          console.log(error);
        });

      if (districtResponse && districtResponse.data !== null) {
        this.setState({
          districtData: districtResponse.data.data,
        });
      } else if (districtResponse && districtResponse.data.statusCode !== 200) {
        this.setState({
          error: true,
          errorCode: districtResponse.data.msg,
        });
      }
      console.log(districtResponse);
    }

    if (name === "districtId") {
      cityResponse = await axios
        .get(
          `${URL.BASE_URL}/masters/cities/list?countryId=${val}&stateId=${val}&districtId=${val}`
        )
        .catch(function (error) {
          console.log(error);
        });

      if (cityResponse && cityResponse.data !== null) {
        this.setState({
          cityData: cityResponse.data.data,
        });
      } else if (cityResponse && cityResponse.data.statusCode !== 200) {
        this.setState({
          error: true,
          errorCode: cityResponse.data.msg,
        });
      } else {
        this.setState({
          status: false,
        });
      }
      console.log(cityResponse);
    }
  };

  PatchNote = async (e) => {
    let id = this.props.match.params.id;
    const { isEnabled } = this.state;
    console.log(isEnabled);
    // console.log(id, "________________________________");
    const token = Cookie.get("patrickToken")
      ? Cookie.get("patrickToken")
      : null;
    const config = {
      headers: { Authorization: `Bearer ${token}` },
    };
    let Data = {
      isEnabled: this.state.isEnabled,
    };
    console.log(Data);

    let response = await axios
      .patch(
        `${URL.BASE_URL}/admin-franchises/${id}/status/update`,
        Data,
        config
      )
      .catch(function (error) {
        console.log(error);
      });
    if (response && response.data.statusCode === 200) {
      this.setState({
        status: true,
        updated: true,
        errorCode: response.data.msg,
      });
    } else if (response && response.data.statusCode !== 200) {
      this.setState({
        error: true,
        errorCode: response.data.msg,
      });
    } else {
      this.setState({
        status: false,
      });
    }
    setTimeout(() => {
      this.setState({
        status: false,
        error: false,
        updated: false,
      });
    }, 2000);
    console.log(response);
  };
  GetFranchiseDetails = async () => {
    const token = Cookie.get("patrickToken")
      ? Cookie.get("patrickToken")
      : null;
    const config = {
      headers: { Authorization: `Bearer ${token}` },
    };
    let id = this.props.match.params.id;
    let response = await axios
      .get(`${URL.BASE_URL}/admin-franchises/single?id=${id}`, config)
      .catch(function (error) {
        console.log(error);
      });

    console.log("👇👇👇👇👇👇👇👇👇👇");
    console.log(response);

    if (response && response.data !== null) {
      let franchisedetails = response.data.data;

      this.setState({
        franchisedetails,
        isEnabled: franchisedetails.isEnabled,
        isAnyDeliverySurge: franchisedetails.isAnyDeliverySurge,
        isLoaded: true,
      });
    } else if (response && response.data.statusCode !== 200) {
      this.setState({
        error: true,
        errorCode: response.data.msg,
      });
    } else {
      this.setState({
        status: false,
      });
    }
  };

  DeleteFranchise = async () => {
    let id = this.props.match.params.id;
    const token = Cookie.get("patrickToken")
      ? Cookie.get("patrickToken")
      : null;
    const config = {
      headers: { Authorization: `Bearer ${token}` },
    };
    let response = await axios
      .delete(`${URL.BASE_URL}/admin-franchises/delete?id=${id}`, config)
      .catch(function (error) {
        console.log(error);
      });
    if (response.data.statusCode === 200) {
      this.setState({
        isRedirect: true,
      });
    }
  };

  PatchFranchiseEdit = async (e) => {
    const { isAnyDeliverySurge } = this.state;
    console.log( isAnyDeliverySurge);
    const token = Cookie.get("patrickToken")
      ? Cookie.get("patrickToken")
      : null;
    const config = {
      headers: { Authorization: `Bearer ${token}` },
    };
    console.log(config);
    let id = this.props.match.params.id;
    let data = {
      id: this.props.match.params.id,
      franchiseName: this.state.franchiseName,
      email: this.state.email,
      countryId: this.state.countryId,
      stateId: this.state.stateId,
      districtId: this.state.districtId,
      cityId: this.state.cityId,
      address: this.state.address,
      pincode: this.state.pincode,
      validPincodes: this.state.validPincodes,
      profileImageId: this.state.profileImageId,
      gstNo: this.state.gstNo,
      panNo: this.state.panNo,
      idProof: this.state.idProof,
      nameInBank: this.state.nameInBank,
      accountType: this.state.accountType,
      accountNo: this.state.accountNo,
      ifsc: this.state.ifsc,
      mobile: this.state.mobile,
      countryData: this.state.countryData,
      stateData: this.state.stateData,
      districtData: this.state.districtData,
      cityData: this.state.districtData,
      commission: this.state.commission,
      isAnyDeliverySurge: this.state.isAnyDeliverySurge,
    };
    let response = await axios
      .patch(`${URL.BASE_URL}/admin-franchises/edit?id=${id}`, data, config)
      .catch(function (error) {
        console.log(error);
      });
    console.log(data);
    if (response && response.data.statusCode === 201) {
      this.setState({
        isRedirect: true,
      });
    } else if (response && response.data.statusCode !== 201) {
      this.setState({
        error: true,
        errorCode: response.data.msg,
      });
    } else {
      this.setState({
        error: true,
      });
    }

    console.log(response);
  };

  handleChange = (e) => {
    if (
      e.target.name === "countryId" ||
      "stateId" ||
      "districtId" ||
      "cityId"
    ) {
      this.GetLocation(e.target.name, e.target.value);
    }

    this.setState({
      [e.target.name]: e.target.value,
    });
  };
 

  handleRadio2 = async (e, isEnabled) => {
    const newState = {};
    newState[isEnabled] = !this.state[isEnabled];
    await this.setState({ ...this.state, ...newState });
    this.PatchNote();
  };
  handleRadio = async (e, isAnyDeliverySurge) => {
    const newState = {};
    newState[isAnyDeliverySurge] = !this.state[isAnyDeliverySurge];
    await this.setState({ ...this.state, ...newState });
    this.PatchFranchiseEdit();
  };

  componentDidMount() {
    this.GetLocation();
    this.GetFranchiseDetails();
  }

  toggleModal = (state) => {
    this.setState({
      [state]: !this.state[state],
    });
  };
  Delete = () => {
    return (
      <>
        <Col>
          <Button
            block
            className="mb-3"
            color="warning"
            type="button"
            onClick={() => this.toggleModal("notificationModal")}
          >
            Delete
          </Button>
          <Modal
            className="modal-dialog-centered modal-danger"
            contentClassName="bg-gradient-danger"
            isOpen={this.state.notificationModal}
            toggle={() => this.toggleModal("notificationModal")}
          >
            <div className="modal-header">
              <h6 className="modal-title" id="modal-title-notification">
                Your confirmation is required
              </h6>
              <button
                aria-label="Close"
                className="close"
                data-dismiss="modal"
                type="button"
                onClick={() => this.toggleModal("notificationModal")}
              >
                <span aria-hidden={true}>×</span>
              </button>
            </div>
            <div className="modal-body">
              <div className="py-3 text-center">
                <i class="ni ni-shop ni-3x"></i>
                <h4 className="heading mt-4">ATTENTION !!</h4>
                <p>Are you sure want to delete this Franchise ?</p>
                <p>You can't undo this operation!</p>
              </div>
            </div>
            <div className="modal-footer">
              <Button
                className="btn-white"
                color="default"
                type="button"
                onClick={() => this.DeleteFranchise()}
              >
                Ok, Delete
              </Button>
              <Button
                className="text-white ml-auto"
                color="link"
                data-dismiss="modal"
                type="button"
                onClick={() => this.toggleModal("notificationModal")}
              >
                Close
              </Button>
            </div>
          </Modal>
        </Col>
      </>
    );
  };

  AddFranchise = () => {
    let details = {
      idProof: {},
    };
    if (this.state.franchisedetails) {
      details = this.state.franchisedetails;
    }
    if (this.state.isLoaded) {
      return (
        <>
          <h6 className="heading-small text-muted mb-4">Seller information</h6>
          <div className="pl-lg-4">
            <Row>
              <Col lg="6">
                <FormGroup>
                  <label
                    className="form-control-label"
                    htmlFor="input-username"
                  >
                    Franchise name
                  </label>
                  <Input
                    className="form-control-alternative"
                    placeholder="Name"
                    type="text"
                    name="franchiseName"
                    defaultValue={details.franchiseName}
                    onChange={this.handleChange}
                  />
                </FormGroup>
              </Col>
              <Col lg="6">
                <FormGroup>
                  <label className="form-control-label">Phone Number</label>
                  <Input
                    className="form-control-alternative"
                    type="text"
                    name="mobile"
                    defaultValue={details.mobile}
                    onChange={this.handleChange}
                  />
                </FormGroup>
              </Col>
            </Row>
            <Row>
              <Col lg="12">
                <FormGroup>
                  <label className="form-control-label" htmlFor="input-email">
                    Email address
                  </label>
                  <Input
                    className="form-control-alternative"
                    id="input-email"
                    placeholder="abcd@example.com"
                    type="email"
                    name="email"
                    defaultValue={details.email}
                    onChange={this.handleChange}
                  />
                </FormGroup>
              </Col>
            </Row>
          </div>
          <hr className="my-4" />
          {/* Address */}
          <h6 className="heading-small text-muted mb-4">Contact information</h6>
          <div className="pl-lg-4">
            <Row>
              <Col md="12">
                <FormGroup>
                  <label className="form-control-label" htmlFor="input-address">
                    Address
                  </label>
                  <Input
                    className="form-control-alternative"
                    id="input-address"
                    placeholder="Home Address"
                    type="text"
                    name="address"
                    defaultValue={details.address}
                    onChange={this.handleChange}
                  />
                </FormGroup>
              </Col>
            </Row>
            <Row>
              <Col lg="4">
                <FormGroup>
                  <Label>Country</Label>
                  <CustomInput
                    type="select"
                    name="countryId"
                    onChange={this.handleChange}
                  >
                    <option value="">{details.countryId.name}</option>;
                    <option value="">Select</option>;
                    {this.state.countryData.map((data, key) => {
                      return <option value={data.id}>{data.name}</option>;
                    })}
                  </CustomInput>
                </FormGroup>
              </Col>
              <Col lg="4">
                <FormGroup>
                  <Label>State</Label>
                  <CustomInput
                    type="select"
                    name="stateId"
                    onChange={this.handleChange}
                  >
                    <option value="">{details.stateId.name}</option>;
                    <option value="">Select</option>;
                    {this.state.stateData &&
                      this.state.stateData.map((data, key) => {
                        return <option value={data.id}>{data.name}</option>;
                      })}
                  </CustomInput>
                </FormGroup>
              </Col>
              <Col lg="4">
                <FormGroup>
                  <Label>District</Label>
                  <CustomInput
                    type="select"
                    name="districtId"
                    onChange={this.handleChange}
                  >
                    <option value="">{details.districtId.name}</option>;
                    <option value="">Select</option>;
                    {this.state.districtData &&
                      this.state.districtData.map((data, key) => {
                        return <option value={data.id}>{data.name}</option>;
                      })}
                  </CustomInput>
                </FormGroup>
              </Col>
            </Row>
            <Row>
              <Col lg="6">
                <FormGroup>
                  <Label>City</Label>
                  <CustomInput
                    type="select"
                    name="cityId"
                    onChange={this.handleChange}
                  >
                    <option value="">{details.cityId.name}</option>;
                    <option value="">Select</option>;
                    {this.state.cityData &&
                      this.state.cityData.map((data, key) => {
                        return <option value={data.id}>{data.name}</option>;
                      })}
                  </CustomInput>
                </FormGroup>
              </Col>
              <Col lg="6">
                <FormGroup>
                  <label className="form-control-label">Postal code</label>
                  <Input
                    className="form-control-alternative"
                    placeholder="Number"
                    name="pincode"
                    type="number"
                    onChange={this.handleChange}
                    defaultValue={details.pincode}
                  />
                </FormGroup>
              </Col>
            </Row>
            <hr className="my-4" />
          </div>
          <h6 className="heading-small text-muted mb-4">
            Bank and ID proof Details
          </h6>
          <div className="pl-lg-4">
            <Row>
              <Col md="6">
                <FormGroup>
                  <label className="form-control-label" htmlFor="input-address">
                    Account number
                  </label>
                  <Input
                    className="form-control-alternative"
                    placeholder="Account Number"
                    name="accountNo"
                    type="number"
                    onChange={this.handleChange}
                    defaultValue={details.accountNo}
                  />
                </FormGroup>
              </Col>
              <Col md="6">
                <FormGroup>
                  <label className="form-control-label" htmlFor="input-address">
                    Account type
                  </label>
                  <Input
                    className="form-control-alternative"
                    placeholder="Type"
                    name="accountType"
                    type="text"
                    onChange={this.handleChange}
                    defaultValue={details.accountType}
                  />
                </FormGroup>
              </Col>
            </Row>
            <Row>
              <Col lg="6">
                <FormGroup>
                  <label className="form-control-label" htmlFor="input-country">
                    Name in bank
                  </label>
                  <Input
                    className="form-control-alternative"
                    placeholder="Name"
                    name="nameInBank"
                    type="text"
                    onChange={this.handleChange}
                    defaultValue={details.nameInBank}
                  />
                </FormGroup>
              </Col>
              <Col lg="6">
                <FormGroup>
                  <label className="form-control-label" htmlFor="input-country">
                    IFSC code
                  </label>
                  <Input
                    className="form-control-alternative"
                    placeholder="Code"
                    name="ifsc"
                    type="text"
                    onChange={this.handleChange}
                    defaultValue={details.ifsc}
                  />
                </FormGroup>
              </Col>
            </Row>
            <Row>
              <Col lg="6">
                <FormGroup>
                  <label className="form-control-label" htmlFor="input-city">
                    PAN number
                  </label>
                  <Input
                    className="form-control-alternative"
                    placeholder="Number"
                    name="panNo"
                    type="text"
                    onChange={this.handleChange}
                    defaultValue={details.panNo}
                  />
                </FormGroup>
              </Col>
              <Col lg="6">
                <FormGroup>
                  <label className="form-control-label" htmlFor="input-country">
                    GST number
                  </label>
                  <Input
                    className="form-control-alternative"
                    placeholder="Number"
                    name="gstNo"
                    type="text"
                    onChange={this.handleChange}
                    defaultValue={details.gstNo}
                  />
                </FormGroup>
              </Col>
            </Row>
            <Row>
              <Col lg="6">
                <FormGroup>
                  <label className="form-control-label" htmlFor="input-country">
                    Commision
                  </label>
                  <Input
                    className="form-control-alternative"
                    placeholder="Number"
                    name="commission"
                    type="number"
                    onChange={this.handleChange}
                    defaultValue={details.commission}
                  />
                </FormGroup>
              </Col>
              <Col lg="6">
                <FormGroup>
                  <label className="form-control-label" htmlFor="input-city">
                    ID Proof
                  </label>
                  <br />
                  {details.idProof ? (
                    <Button
                      color="primary"
                      type="button"
                      href={details.idProof.publicUrl}
                      target="_blank"
                    >
                      Show
                    </Button>
                  ) : (
                    <Button color="primary" type="button" target="_blank">
                      Show
                    </Button>
                  )}
                </FormGroup>
              </Col>
            </Row>
          </div>
          <hr className="my-4" />
        </>
      );
    } else {
      return (
        <>
          <Spinner
            color="warning"
            style={{ width: "4rem", height: "4rem", marginLeft: "45%" }}
          />{" "}
        </>
      );
    }
  };
  CardDetails = () => {
    // const { isEnabled } = this.state;
    // console.log(isEnabled);
    let card = {
      franchiseName: " ",
      profileImageId: {},
    };
    if (this.state.franchisedetails) {
      card = this.state.franchisedetails;
      console.log(card.isAnyDeliverySurge,"🎈🎈🎈🎈🎈🎈🎈")
    }

    return (
      <Card className="card-profile shadow">
        <Row className="justify-content-center">
          <Col className="order-lg-2" lg="3">
            <div className="card-profile-image">
              <a href="#pablo" onClick={(e) => e.preventDefault()}>
                {card.profileImageId ? (
                  <img
                    alt="Loading..."
                    src={card.profileImageId.publicUrl}
                    width="150px"
                  />
                ) : (
                  <img
                    alt="..."
                    className="rounded-circle"
                    src={require("assets/img/theme/team-4-800x800.jpg")}
                  />
                )}
              </a>
            </div>
          </Col>
        </Row>
        <CardHeader className="text-center border-0 pt-8 pt-md-4 pb-0 pb-md-4"></CardHeader>
        <CardBody className="pt-0 pt-md-4">
          <Row>
            <div className="col">
              <div className="card-profile-stats d-flex justify-content-center mt-md-5"></div>
            </div>
          </Row>
          <div className="text-center">
            <h3>{card.franchiseName}</h3>

            <hr className="my-4" />
            <Row className="justify-content-lg-center">
              <span>Enable &nbsp; </span>
              {/* <Switch
                onChange={this.handleChangeSwitchEnable}
                checked={this.state.isEnabled}
              /> */}

              <label className="custom-toggle">
                <input
                  name="isEnabled"
                  type="checkbox"
                  defaultValue={card.isEnabled}
                  checked={this.state.isEnabled}
                  onChange={(e) => this.handleRadio2(e, "isEnabled")}
                />
                <span className="custom-toggle-slider rounded-circle" />
              </label>
            </Row>
            <br />
            <Row className="justify-content-lg-center">
              <span>Delivery Surge &nbsp; </span>

              <label className="custom-toggle">
                <input
                  name="isEnabled"
                  type="checkbox"
                  defaultValue={card.isAnyDeliverySurge}
                  checked={this.state.isAnyDeliverySurge}
                  onChange={(e) => this.handleRadio(e, "isAnyDeliverySurge")}
                />
                <span className="custom-toggle-slider rounded-circle" />
              </label>
            </Row>
            <br />
          </div>
        </CardBody>
      </Card>
    );
  };

  SellerButton = () => {
    let id;
    if (this.state.franchisedetails) {
      id = this.state.franchisedetails.id;
    }

    return (
      <>
        <Col className="text-right" xs="4">
          <Link to={`/admin/seller/${id}`}>
            <Button color="warning" size="md">
              Seller list
            </Button>
          </Link>
        </Col>
      </>
    );
  };

  render() {
    if (this.state.isRedirect) {
      return <Redirect to="/admin/franchise-list" />;
    }
    return (
      <>
        <Header2 />
        {/* Page content */}
        <Container className="mt--7" fluid>
          <Row>
            <Col className="order-xl-2 mb-5 mb-xl-0" xl="4">
              <this.CardDetails />
            </Col>
            <Col className="order-xl-1" xl="8">
              <Card className="bg-secondary shadow">
                <CardHeader className="bg-white border-0">
                  <Row className="align-items-center">
                    <Col xs="8">
                      <h3 className="mb-0">Franchise Details</h3>
                    </Col>
                    <this.SellerButton />
                  </Row>
                </CardHeader>
                <CardBody>
                  <this.AddFranchise />

                  <Row className="justify-content-md-center">
                    <Col lg="0">
                      <Button
                        color="default"
                        type="button"
                        onClick={() => this.PatchFranchiseEdit()}
                      >
                        Save Changes
                      </Button>
                    </Col>
                    <Col lg="0">
                      <this.Delete />
                    </Col>
                  </Row>
                </CardBody>
              </Card>
            </Col>
          </Row>
        </Container>
      </>
    );
  }
}

export default FranchiseDetails;
