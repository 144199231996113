import React from "react";
import Switch from "react-switch";
import { Spinner } from "reactstrap";
import { Link, Redirect } from "react-router-dom";

// reactstrap components
import {
  Card,
  CardHeader,
  CardBody,
  FormGroup,
  Form,
  Input,
  Container,
  Row,
  Col,
  UncontrolledCarousel,
  Badge,
  Button,
  FormFeedback,
} from "reactstrap";
// core components
import Cookie from "js-cookie";
import Header2 from "components/Headers/Header2";
import FormText from "reactstrap/lib/FormText";
const axios = require("axios").default;
const URL = require("../../ApiUrl");

class ProductDetails extends React.Component {
  constructor() {
    super();
    this.state = {
      checked: false,
      isLoaded: false,
      isRejected: false,
      isAproved: false,
      isEnabled: false,
      shopDiscount: "",
      adminCommission: "",
      displayPrice: "",
      error: false,
      adminCommissionError: "",
      shopDiscountError: "",
      displayPriceError: ""
    };
    this.handleChange = this.handleChange.bind(this);
    this.GetProductDetails = this.GetProductDetails.bind(this);
    this.ProductInformation = this.ProductInformation.bind(this);
    this.SellerContact = this.SellerContact.bind(this);
    this.CustomerReview = this.CustomerReview.bind(this);
    this.CustomerCard = this.CustomerCard.bind(this);
    this.CustomerDetails = this.CustomerDetails.bind(this);
  }

  async GetProductDetails() {
    const token = Cookie.get("patrickToken")
      ? Cookie.get("patrickToken")
      : null;
    const config = {
      headers: { Authorization: `Bearer ${token}` },
    };
    let id = this.props.match.params.id;
    let response = await axios
      .get(`${URL.BASE_URL}/admin-products/${id}/detail`, config)
      .catch(function (error) {
        console.log(error);
      });

    console.log("👇👇👇👇👇👇👇👇👇👇");
    console.log(response);

    if (response && response.data !== null) {
      this.setState({
        productdetail: response.data.data,
        isEnabled: response.data.data.isEnabled,
        isLoaded: true,
      });
    } else if (response && response.data.statusCode !== 200) {
      this.setState({
        error: true,
        errorCode: response.data.msg,
      });
    } else {
      this.setState({
        status: false,
      });
    }
  }

  PatchEnable = async (e) => {
    let id = this.props.match.params.id;
    const { isEnabled } = this.state;
    console.log(isEnabled);
    // console.log(id, "________________________________");
    const token = Cookie.get("patrickToken")
      ? Cookie.get("patrickToken")
      : null;
    const config = {
      headers: { Authorization: `Bearer ${token}` },
    };
    let Data = {
      isEnabled: this.state.isEnabled,
    };
    console.log(Data);

    let response = await axios
      .patch(`${URL.BASE_URL}/admin-products/${id}/status`, Data, config)
      .catch(function (error) {
        console.log(error);
      });
    if (response && response.data.statusCode === 200) {
      this.setState({
        status: true,
        updated: true,
        errorCode: response.data.msg,
      });
    } else if (response && response.data.statusCode !== 200) {
      this.setState({
        error: true,
        errorCode: response.data.msg,
      });
    } else {
      this.setState({
        status: false,
      });
    }
    setTimeout(() => {
      this.setState({
        status: false,
        error: false,
        updated: false,
      });
    }, 2000);
    console.log(response);
  };

  PatchReject = async (e) => {
    let id = this.props.match.params.id;
    const { isRejected } = this.state;
    console.log(isRejected);
    // console.log(id, "________________________________");
    const token = Cookie.get("patrickToken")
      ? Cookie.get("patrickToken")
      : null;
    const config = {
      headers: { Authorization: `Bearer ${token}` },
    };
    let Data = {
      isRejected: this.state.isRejected,
      isApproved: false,
    };
    console.log(Data);

    let response = await axios
      .patch(
        `${URL.BASE_URL}/admin-products/${id}/request/status`,
        Data,
        config
      )
      .catch(function (error) {
        console.log(error);
      });
    if (response && response.data.statusCode === 200) {
      this.setState({
        status: true,
        updated: true,
        isRedirect: true,
        errorCode: response.data.msg,
      });
    } else if (response && response.data.statusCode !== 200) {
      this.setState({
        error: true,
        errorCode: response.data.msg,
      });
    } else {
      this.setState({
        status: false,
      });
    }
    setTimeout(() => {
      this.setState({
        status: false,
        error: false,
        updated: false,
      });
    }, 2000);
    console.log(response);
  };
  PatchApproved = async (e) => {
    const token = Cookie.get("patrickToken")
      ? Cookie.get("patrickToken")
      : null;
    const config = {
      headers: { Authorization: `Bearer ${token}` },
    };
    console.log(config);
    this.setState({
      adminCommissionError: "",
      shopDiscountError: "",
      displayPriceError: ""
    });
    let id = this.props.match.params.id;
    let data = {
      isRejected: false,
      isApproved: true,
      adminCommission: this.state.adminCommission,
      shopDiscount: this.state.shopDiscount,
      displayPrice: this.state.displayPrice,
    };
  
    if (this.state.adminCommission === "" || this.state.shopDiscount === "" || this.state.displayPrice === "") {
      if (this.state.adminCommission === "") {
        this.setState({
          adminCommissionError: "*Admin commission required",
        });
      }
      if (this.state.shopDiscount === "") {
        this.setState({
          shopDiscountError: "*Shop Commision requied",
        });
      }
      if (this.state.displayPrice === "") {
        this.setState({
          displayPriceError: "*Display Price requied",
        });
      }
      return;
    }
    console.log(data, "🎈🎈🎈🎈🎈🎈🎈🎈🎈🎈🎈");
    let response = await axios
      .patch(
        `${URL.BASE_URL}/admin-products/${id}/request/status`,
        data,
        config
      )
      .catch(function (error) {
        console.log(error);
      });
    console.log(data);
    if (response && response.data.statusCode === 200) {
      this.setState({
        isRedirect: true,
      });
    } else if (response && response.data.statusCode !== 200) {
      this.setState({
        error: true,
        error: response.data.msg,
      });
    } else {
      this.setState({
        error: true,
      });
    }

    console.log(response);
  };
  handleChange(e) {
    this.setState({
      [e.target.data]: e.target.value,
    });
  }

  componentDidMount() {
    this.GetProductDetails();
  }
  handleChangeSwitch(checked) {
    this.setState({});
  }

  // handleChangeSwitchRejected(checked) {
  //   this.setState({ isRejected: checked });
  //   console.log(this.state.isRejected, "🧧🎑🧧🎑🧧🧧🎑🧧🎑");
  //   this.PatchStatus();
  // }
  handleChange = (e) => {
    this.setState({
      [e.target.name]: e.target.value,
    });
  };

  handleRadio1 = async (e, isRejected) => {
    const newState = {};
    newState[isRejected] = !this.state[isRejected];
    await this.setState({ ...this.state, ...newState });
    this.PatchReject();
  };
  handleRadio3 = async (e, isEnabled) => {
    const newState = {};
    newState[isEnabled] = !this.state[isEnabled];
    await this.setState({ ...this.state, ...newState });
    this.PatchEnable();
  };

  // handleChangeSwitchAproved(checked) {
  //   this.setState({ isAproved: checked });
  //   this.PatchStatus();
  // }
  // handleChangeSwitchEnable(checked) {
  //   this.setState({ isEnable: checked });
  //   this.PatchStatus();
  // }

  ProductInformation() {
    let information;
    if (this.state.productdetail) {
      information = this.state.productdetail;
    }
    return (
      <>
        <h6 className="heading-small text-muted mb-4">Product information</h6>
        <div className="pl-lg-4">
          <Row>
            <Col lg="6">
              <FormGroup>
                <label className="form-control-label">Name</label>
                <Input
                  className="form-control-alternative"
                  type="text"
                  value={information.name}
                  disabled
                />
              </FormGroup>
            </Col>
            <Col lg="6">
              <FormGroup>
                <label className="form-control-label">Type</label>
                <Input
                  className="form-control-alternative"
                  type="text"
                  value={information.type}
                  disabled
                />
              </FormGroup>
            </Col>
          </Row>
          <Row>
            <Col lg="4">
              <FormGroup>
                <label className="form-control-label">Model</label>
                <Input
                  className="form-control-alternative"
                  type="text"
                  value={information.model}
                  disabled
                />
              </FormGroup>
            </Col>
            <Col lg="4">
              <FormGroup>
                <label className="form-control-label">Color</label>
                <Input
                  className="form-control-alternative"
                  type="text"
                  value={information.color}
                  disabled
                />
              </FormGroup>
            </Col>
            <Col lg="4">
              <FormGroup>
                <label className="form-control-label">Size</label>
                <Input
                  className="form-control-alternative"
                  type="text"
                  value={information.size}
                  disabled
                />
              </FormGroup>
            </Col>
          </Row>
          <Row>
            <Col lg="4">
              <FormGroup>
                <label className="form-control-label">Length</label>
                <Input
                  className="form-control-alternative"
                  type="text"
                  value={information.length}
                  disabled
                />
              </FormGroup>
            </Col>
            <Col lg="4">
              <FormGroup>
                <label className="form-control-label">width</label>
                <Input
                  className="form-control-alternative"
                  type="text"
                  value={information.width}
                  disabled
                />
              </FormGroup>
            </Col>
            <Col lg="4">
              <FormGroup>
                <label className="form-control-label">Height</label>
                <Input
                  className="form-control-alternative"
                  type="text"
                  value={information.height}
                  disabled
                />
              </FormGroup>
            </Col>
          </Row>
          <Row>
            <Col lg="6">
              <FormGroup>
                <label className="form-control-label">Weight</label>
                <Input
                  className="form-control-alternative"
                  type="text"
                  value={information.weight}
                  disabled
                />
              </FormGroup>
            </Col>
            <Col lg="6">
              <FormGroup>
                <label className="form-control-label">Price</label>
                <Input
                  className="form-control-alternative"
                  type="text"
                  value={information.actualPrice}
                  disabled
                />
              </FormGroup>
            </Col>
          </Row>
          <div className="pl-lg-0">
            <FormGroup>
              <label>Description</label>
              <Input
                className="form-control-alternative"
                rows="4"
                type="textarea"
                value={information.description}
                disabled
              />
            </FormGroup>
          </div>
        </div>
        <hr className="my-4" />
      </>
    );
  }
  OtherInformation = () => {
    let information;
    if (this.state.productdetail) {
      information = this.state.productdetail;
    }

    let d = new Date(information.discountDateFrom);
    let d1 = new Date(information.discountDateTo);
    let date1 = d1.getDate();
    let month1 = d1.getMonth() + 1; // Since getMonth() returns month from 0-11 not 1-12
    let year1 = d1.getFullYear();
    let dateStr1 = date1 + "/" + month1 + "/" + year1;
    let date = d.getDate();
    let month = d.getMonth() + 1; // Since getMonth() returns month from 0-11 not 1-12
    let year = d.getFullYear();

    let dateStr = date + "/" + month + "/" + year;

    return (
      <>
        <h6 className="heading-small text-muted mb-4">Other information</h6>
        <div className="pl-lg-4">
          <Row>
            <Col lg="6">
              <FormGroup>
                <label className="form-control-label">Max Discount</label>
                <Input
                  className="form-control-alternative"
                  type="text"
                  value={information.maxDiscount}
                  disabled
                />
              </FormGroup>
            </Col>
            <Col lg="6">
              <FormGroup>
                <label className="form-control-label">Quantity</label>
                <Input
                  className="form-control-alternative"
                  type="text"
                  value={information.quantity}
                  disabled
                />
              </FormGroup>
            </Col>
          </Row>
          <Row>
            <Col lg="6">
              <FormGroup>
                <label className="form-control-label">Discount Date From</label>
                <Input
                  className="form-control-alternative"
                  type="text"
                  value={dateStr}
                  disabled
                />
              </FormGroup>
            </Col>
            <Col lg="6">
              <FormGroup>
                <label className="form-control-label">Discount Date To</label>
                <Input
                  className="form-control-alternative"
                  type="text"
                  value={dateStr1}
                  disabled
                />
              </FormGroup>
            </Col>
          </Row>
          <Row>
            <Col lg="6">
              <FormGroup>
                <label className="form-control-label">Tag</label>
                <Input
                  className="form-control-alternative"
                  type="text"
                  value={information.tag}
                  disabled
                />
              </FormGroup>
            </Col>

            <Col lg="6">
              <FormGroup>
                <label className="form-control-label">Category</label>
                <Input
                  className="form-control-alternative"
                  type="text"
                  disabled
                />
              </FormGroup>
            </Col>
          </Row>
        </div>
        <hr className="my-4" />
      </>
    );
  };
  SellerContact() {
    let contact;
    if (this.state.productdetail) {
      contact = this.state.productdetail;
    }

    return (
      <>
        <h6 className="heading-small text-muted mb-4">Seller information</h6>
        <div className="pl-lg-4">
          <Row>
            <Col md="6">
              <FormGroup>
                <label className="form-control-label">Seller name</label>
                <Input
                  className="form-control-alternative"
                  placeholder="Home Address"
                  type="text"
                  value={contact.sellerId.sellerName}
                  disabled
                />
              </FormGroup>
            </Col>
            <Col md="6">
              <FormGroup>
                <label className="form-control-label">Mobile number</label>
                <Input
                  className="form-control-alternative"
                  placeholder="Home Address"
                  type="text"
                  value={contact.sellerId.mobile}
                  disabled
                />
              </FormGroup>
            </Col>
          </Row>
          <Row>
            <Col lg="12">
              <FormGroup>
                <label className="form-control-label">Email</label>
                <Input
                  className="form-control-alternative"
                  value={contact.sellerId.email}
                  type="email"
                  disabled
                />
              </FormGroup>
            </Col>
          </Row>
        </div>
        <hr className="my-4" />
      </>
    );
  }

  ProductImages = () => {
    let items = [];
    if (this.state.productdetail) {
      let imgary = this.state.productdetail.imgUrl;
      for (let i = 0; i < imgary.length; i++) {
        items.push({
          src: imgary[i].publicUrl,
          altText: "Slide 2",
          caption: "",
          header: "",
          key: "2",
        });
      }
    }
    return (
      <Row className="justify-content-center mt-4">
        <Col md="8" className="mx-auto">
          <UncontrolledCarousel items={items} />
        </Col>
      </Row>
    );
  };
  CustomerReview = () => {
    let review = [];
    if (this.state.productdetail) {
      review = this.state.productdetail.reviews;
      return (
        <>
          <h6 className="heading-small text-muted mb-4">Reviews</h6>
          <div className="pl-lg-4">
            <FormGroup>
              <h3>Customer Reviews</h3>
              {review.map((data, key) => {
                return (
                  <>
                    <br />
                    Customer : &nbsp;
                    <Link to={`/admin/customerdetails/${data.customerId.id}`}>
                      {data.customerId.firstName}
                      &nbsp;
                      {data.customerId.lastName}
                    </Link>
                    <br />
                    <br />
                    <Badge href="#" color="info">
                      Rating: {data.rating}/5
                    </Badge>
                    &nbsp;{data.reviewTitle}
                    <br />
                    &nbsp;
                    <Form>
                      <Input
                        className="form-control-alternative"
                        rows="3"
                        type="textarea"
                        value={data.review}
                        disabled
                      />
                    </Form>
                  </>
                );
              })}
            </FormGroup>
          </div>
        </>
      );
    } else {
      return <></>;
    }
  };
  CustomerCard() {
    const { isRejected, isEnabled, isAproved } = this.state;
    console.log(isEnabled, isRejected, isAproved);
    let card = {
      firstName: " ",
      isVerified: false,
      rewardPoint: "",
      profileImageId: {},
    };
    if (this.state.productdetail) {
      card = this.state.productdetail;
    }
    return (
      <>
        <Card className="card-profile shadow">
          <this.ProductImages />

          <CardBody className="pt-0 pt-md-4">
            <Row>
              <div className="col">
                <div className="card-profile-stats d-flex justify-content-center mt-md-5">
                  <div>
                    <span className="heading">{card.name}</span>
                  </div>
                </div>
              </div>
            </Row>

            <div className="text-center">
              <h3>{card.sellerName}</h3>
              {card.isRejected === true ? (
                <></>
              ) : (
                <>
                  {card.isApproved === true ? (
                    <>
                      <hr className="my-4" />
                      <Row className="justify-content-lg-center">
                        <span>Enable &nbsp;</span>
                        {/* <Switch
                  onChange={this.handleChangeSwitchRejected}
                  checked={this.state.isRejected}
                /> */}
                        <label className="custom-toggle">
                          <input
                            type="checkbox"
                            name="isEnabled"
                            defaultValue={card.isEnabled}
                            checked={this.state.isEnabled}
                            onChange={(e) => this.handleRadio3(e, "isEnabled")}
                          />
                          <span className="custom-toggle-slider rounded-circle" />
                        </label>
                      </Row>
                      <hr className="my-4" />
                      <Col lg="12">
                        <FormGroup>
                          <label className="form-control-label">
                            Shop Discount
                          </label>
                          <Input
                            className="form-control-alternative"
                            disabled
                            type="number"
                            defaultValue={card.shopDiscount}
                            name="shopDiscount"
                            onChange={this.handleChange}
                          />
                          <FormText className="text-warning">
                            {this.state.shopDiscountError}
                          </FormText>
                        </FormGroup>
                      </Col>
                      <Col lg="12">
                        <FormGroup>
                          <label className="form-control-label">
                            Admin Commission
                          </label>
                          <Input
                            className="form-control-alternative"
                            defaultValue={card.adminCommission}
                            disabled
                            type="number"
                            name="adminCommission"
                            onChange={this.handleChange}
                          />
                          <FormText>{this.state.adminCommissionError}</FormText>
                          {/* <FormText>{this.state.error}</FormText> */}
                        </FormGroup>
                      </Col>
                      <Col lg="12">
                        <FormGroup>
                          <label className="form-control-label">
                            Display Price
                          </label>
                          <Input
                            className="form-control-alternative"
                            defaultValue={card.displayPrice}
                            disabled
                            type="number"
                            name="displayPrice"
                            onChange={this.handleChange}
                          />
                          <FormText>{this.state.displayPriceError}</FormText>
                          <FormText>{this.state.error}</FormText>
                        </FormGroup>
                      </Col>
                      <Col lg="0">
                        <Button
                          color="default"
                          type="button"
                          value={true}
                          disabled
                          onClick={() => this.PatchApproved()}
                        >
                          Approved
                        </Button>
                      </Col>
                    </>
                  ) : (
                    <>
                    <hr className="my-4" />
                      <Row className="justify-content-lg-center">
                        <span>Rejected &nbsp; </span>
                        <label className="custom-toggle">
                          <input
                            type="checkbox"
                            name="isRejected"
                            value="true"
                            checked={this.state.isRejected}
                            onChange={(e) => this.handleRadio1(e, "isRejected")}
                          />
                          <span className="custom-toggle-slider rounded-circle" />
                        </label>
                      </Row>
                      <hr className="my-4" />
                      <Col lg="12">
                        <FormGroup>
                          <label className="form-control-label">
                            Shop Discount
                          </label>
                          <Input
                            className="form-control-alternative"
                            type="number"
                            name="shopDiscount"
                            onChange={this.handleChange}
                          />
                          <FormText className="text-warning">
                            {this.state.shopDiscountError}
                          </FormText>
                        </FormGroup>
                      </Col>
                      <Col lg="12">
                        <FormGroup>
                          <label className="form-control-label">
                            Admin Commission
                          </label>
                          <Input
                            className="form-control-alternative"
                            type="number"
                            name="adminCommission"
                            onChange={this.handleChange}
                          />
                          <FormText>{this.state.adminCommissionError}</FormText>
                        </FormGroup>
                      </Col>
                      <Col lg="12">
                        <FormGroup>
                          <label className="form-control-label">
                            Display Price
                          </label>
                          <Input
                            className="form-control-alternative"
                            // disabled
                            type="number"
                            name="displayPrice"
                            onChange={this.handleChange}
                          />
                          <FormText>{this.state.displayPriceError}</FormText>
                          <FormText>{this.state.error}</FormText>
                        </FormGroup>
                      </Col>
                      <Col lg="0">
                        <Button
                          color="default"
                          type="button"
                          value={true}
                          onClick={() => this.PatchApproved()}
                        >
                          Approve
                        </Button>
                      </Col>
                    </>
                  )}
                </>
              )}
            </div>
          </CardBody>
        </Card>
        <br />
      </>
    );
  }

  CustomerDetails() {
    if (this.state.isLoaded) {
      return (
        <>
          <Form>
            <this.ProductInformation />
            <this.OtherInformation />
            {/* Address */}
            <this.SellerContact />
            {/* Reviews */}
            <this.CustomerReview />
          </Form>
        </>
      );
    } else {
      return (
        <>
          <Spinner
            color="warning"
            style={{ width: "4rem", height: "4rem", marginLeft: "45%" }}
          />{" "}
        </>
      );
    }
  }

  render() {
    if (this.state.isRedirect) {
      return <Redirect to="/admin/products" />;
    }
    return (
      <>
        <Header2 />
        {/* Page content */}
        <Container className="mt--7" fluid>
          <Row>
            <Col className="order-xl-2 mb-5 mb-xl-0" xl="4">
              <this.CustomerCard />
            </Col>
            <Col className="order-xl-1" xl="8">
              <Card className="bg-secondary shadow">
                <CardHeader className="bg-white border-0">
                  <Row className="align-items-center">
                    <Col xs="8">
                      <h3 className="mb-0">Product Details</h3>
                    </Col>
                  </Row>
                </CardHeader>
                <CardBody>
                  <this.CustomerDetails />
                </CardBody>
              </Card>
            </Col>
          </Row>
        </Container>
      </>
    );
  }
}

export default ProductDetails;
