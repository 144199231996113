import React, { Component } from "react";
import {
  Card,
  CardHeader,
  Container,
  Row,
  Col,
  CardBody,
  FormGroup,
  Input,
  Button,
  CustomInput,
  UncontrolledAlert,
  Table,
  Spinner,
  CardFooter,
  Modal,
  Progress,
} from "reactstrap";
import Cookie from "js-cookie";
import Header2 from "components/Headers/Header2";
import { Redirect } from "react-router-dom";
const axios = require("axios").default;
const URL = require("../../ApiUrl");
export default class Silderdetails extends Component {
  constructor(props) {
    super(props);
    this.state = {
      title: "",
      type: "",
      mainImage: "",
      isRedirect: false,
      succsess: false,
      error: false,
      productdetails: [],
      sellerdetails: [],
      categories: [],

      productId: "",
      categoryId: "",
      SellerId: "",
      isLoaded: false,
      selectedOption: null,
      seller: {},
      orginalData: [],
    };
  }
  GetLocation = async (name, value) => {
    console.log(name, value, "///////////////////////////");
    const token = Cookie.get("patrickToken")
      ? Cookie.get("patrickToken")
      : null;
    const config = {
      headers: { Authorization: `Bearer ${token}` },
    };

    let response;
    response = await axios
      .get(`${URL.BASE_URL}/admin-products/seller/list/names`, config)
      .catch(function (error) {
        console.log(error);
      });

    console.log("👇👇👇👇👇👇👇👇👇👇");
    console.log(response);

    if (response && response.data !== null) {
      this.setState({
        sellerdetails: response.data.data,
      });
    } else if (response && response.data.statusCode !== 200) {
      this.setState({
        error: true,
        errorCode: response.data.msg,
      });
    }

    if (name === "SellerId") {
      let response1 = await axios
        .get(
          `${URL.BASE_URL}/admin-products/list/names?sellerId=${value}`,
          config
        )
        .catch(function (error) {
          console.log(error);
        });

      console.log("👇👇👇👇👇👇👇👇👇👇");
      console.log(response1);

      if (response1 && response1.data !== null) {
        this.setState({
          productdetails: response1.data.data,
        });
      } else if (response1 && response1.data.statusCode !== 200) {
        this.setState({
          error: true,
          errorCode: response1.data.msg,
        });
      }
    }
  };
  Getdeliveryboydetails = async () => {
    const token = Cookie.get("patrickToken")
      ? Cookie.get("patrickToken")
      : null;
    const config = {
      headers: { Authorization: `Bearer ${token}` },
    };
    let id = this.props.match.params.id;
    let response = await axios
      .get(`${URL.BASE_URL}/admin-products/sliders/${id}/detail`, config)
      .catch(function (error) {
        console.log(error);
      });

    console.log("👇👇👇👇👇👇👇👇👇👇");
    console.log(response);

    if (response && response.data !== null) {
      this.setState({
        seller: response.data.data,
        type: response.data.data.type,
        isLoaded: true,
      });
    } else if (response && response.data.statusCode !== 200) {
      this.setState({
        error: true,
        errorCode: response.data.msg,
      });
    } else {
      this.setState({
        status: false,
      });
    }
  };
  async GetCategory() {
    const token = Cookie.get("patrickToken")
      ? Cookie.get("patrickToken")
      : null;
    const config = {
      headers: { Authorization: `Bearer ${token}` },
    };

    let response = await axios
      .get(`${URL.BASE_URL}/admin-products/category/get`, config)
      .catch(function (error) {
        console.log(error);
      });
    console.log("👇👇👇👇👇👇👇👇👇👇");
    console.log(response);
    if (response && response.data !== null) {
      this.setState({
        categories: response.data,
      });
    } else if (response && response.data.statusCode !== 200) {
      this.setState({
        error: true,
        errorCode: response.data.msg,
      });
    } else {
      this.setState({
        status: false,
      });
    }
  }
  componentDidMount = () => {
    this.Getdeliveryboydetails();
    this.GetLocation();
    this.GetCategory();
  };

  handleChange = (e) => {
    if (e.target.name === "SellerId") {
      this.GetLocation(e.target.name, e.target.value);
    }

    this.setState({
      [e.target.name]: e.target.value,
    });

    console.log(e.target.value, "/////////");
  };

  DeleteFranchise = async () => {
    let id = this.props.match.params.id;
    const token = Cookie.get("patrickToken")
      ? Cookie.get("patrickToken")
      : null;
    const config = {
      headers: { Authorization: `Bearer ${token}` },
    };
    let response = await axios
      .delete(`${URL.BASE_URL}/admin-products/sliders/${id}/delete`, config)
      .catch(function (error) {
        console.log(error);
      });
    if (response.data.statusCode === 200) {
      this.setState({
        isRedirect: true,
        isLoaded: true,
      });
    }
  };

  onFileChangeProfile = async (e) => {
    this.setState({ isRedirec: true });

    const formData = new FormData();

    const token = Cookie.get("patrickToken")
      ? Cookie.get("patrickToken")
      : null;
    const config = {
      headers: { Authorization: `Bearer ${token}` },
    };

    formData.append("file", e.target.files[0]);
    formData.append("type", this.state.type);

    axios.post(`${URL.BASE_URL}/mediaupload`, formData, config).then((res) => {
      console.log(res);
      this.setState({ mainImage: res.data.data._id });
      console.log(res.data.data);
      if (res && res.data.statusCode === 201) {
        this.setState({
          isLoaded: false,
        });
      } else if (res && res.data.statusCode !== 201) {
        this.setState({
          error: true,
          errorCode: res.data.msg,
        });
      } else {
        this.setState({
          error: true,
        });
      }
    });
  };
  Proof = () => {
    if (this.state.isLoaded) {
      return (
        <>
          <Progress animated color="success" value="100" className="mt-2" />
        </>
      );
    } else {
      return <></>;
    }
  };
  PatchFranchiseEdit = async (e) => {
    const token = Cookie.get("patrickToken")
      ? Cookie.get("patrickToken")
      : null;
    const config = {
      headers: { Authorization: `Bearer ${token}` },
    };
    console.log(config);
    let id = this.props.match.params.id;
    let data = {
      // sellerId: this.state.SellerId,
      title: this.state.title,
      // product: this.state.productId,
      // type: this.state.type,
      mainImage: this.state.mainImage,
    };
    if(this.state.title === ''){
      data.title = null;
    }
    if(this.state.mainImage === ''){
      data.mainImage = null;
    }
    
    console.log("data")
    console.log(data)
    console.log("data")
    let response = await axios
      .patch(
        `${URL.BASE_URL}/admin-products/sliders/${id}/update`,
        data,
        config
      )
      .catch(function (error) {
        console.log(error);
      });
    console.log(data);
    if (response && response.data.statusCode === 200) {
      this.setState({
        isRedirect: true,
        succsess: true,
      });
    } else if (response && response.data.statusCode !== 200) {
      this.setState({
        error: true,
        errorCode: response.data.msg,
      });
    } else {
      this.setState({
        error: true,
      });
    }

    console.log(response);
  };

  SellerDetails = () => {
    let information;
    if (this.props.match.params.id) {
      information = this.state.seller;
    }
    if (this.state.isLoaded) {
      return (
        <>
          <h6 className="heading-small text-muted mb-4">
            Slider Details information
          </h6>
          <div className="pl-lg-4">
            <Row>
              <Col lg="6">
                <FormGroup>
                  <label
                    className="form-control-label"
                    htmlFor="input-username"
                  >
                    Title
                  </label>
                  <Input
                    className="form-control-alternative"
                    type="text"
                    name="title"
                    defaultValue={information.title}
                    onChange={this.handleChange}
                  />
                </FormGroup>
              </Col>
              <Col lg="6">
                <FormGroup>
                  <label className="form-control-label">Type</label>

                  <FormGroup>
                    <CustomInput
                      type="select"
                      name="type"
                      onChange={this.handleChange}
                      disabled
                    >
                      <option>{information.type}</option>
                      <option value="adds">Adds</option>
                      <option value="sliders">Sliders</option>
                      <option value="banner">Banner</option>
                       <option value="festivals">Festival</option>
                    </CustomInput>
                  </FormGroup>
                </FormGroup>
              </Col>

              <Col lg="4">
                <FormGroup>
                  <label className="form-control-label">Category</label>

                  <FormGroup>
                    <CustomInput
                      type="select"
                      name="category"
                      onChange={this.handleChange}
                      disabled
                    >
                      <option>{information.category}</option>
                      <option value="adds">Seller</option>
                      <option value="sliders">Product</option>
                      <option value="category">Category</option>
                    </CustomInput>
                  </FormGroup>
                </FormGroup>
              </Col>

              <Col lg="4">
                <FormGroup>
                  <label className="form-control-label">Seller Name</label>
                  {information.sellerId ? (

                  <CustomInput
                    type="select"
                    name="SellerId"
                    onChange={this.handleChange}
                    disabled
                  >
                    <option value="">{information.sellerId.sellerName}</option>
                    {this.state.sellerdetails.map((data, key) => {
                      return <option value={data.id}>{data.sellerName}</option>;
                    })}
                  </CustomInput>
                      ) : (
                        <Col className="text-danger">
                          <> Nil</>
                        </Col>
                      )}
                </FormGroup>
              </Col>
              <Col lg="4">
                <FormGroup>
                  <label
                    className="form-control-label"
                    htmlFor="input-first-name"
                  >
                    Product Name
                  </label>
                  {information.product[0] ? (
                    <CustomInput
                      type="select"
                      name="productId"
                      onChange={this.handleChange}
                      disabled
                    >
                      <option value="">{information.product[0].name}</option>
                      {this.state.productdetails.map((data, key) => {
                        return <option value={data.id}>{data.name}</option>;
                      })}
                    </CustomInput>
                  ) : (
                    <Col className="text-danger">
                      <> Nil</>
                    </Col>
                  )}
                </FormGroup>
              </Col>
              <Col lg="4">
                <FormGroup>
                  <label
                    className="form-control-label"
                    htmlFor="input-first-name"
                  >
                Category
                  </label>
                  {information.categoryId ? (
                    <CustomInput
                      type="select"
                      name="categoryId"
                      onChange={this.handleChange}
                      disabled
                    >
                      <option value="">{information.categoryId.name}</option>
                      {this.state.categories.map((data, key) => {
                        return <option value={data.id}>{data.name}</option>;
                      })}
                    </CustomInput>
                  ) : (
                    <Col className="text-danger">
                      <> Nil</>
                    </Col>
                  )}
                </FormGroup>
              </Col>
            </Row>

            <Row>
              <Col lg="6">
                <FormGroup>
                  <label className="form-control-label" htmlFor="input-city">
                    Main Image
                  </label>
                  <CustomInput
                    type="file"
                    id="exampleCustomFileBrowser"
                    name="mainImage"
                    onChange={this.onFileChangeProfile}
                  />
                  <br />
                </FormGroup>
              </Col>
              <Col lg="6" className="mt-4">
                {" "}
                <FormGroup>
                  {information.mainImage ? (
                    <Button
                      color="primary"
                      type="button"
                      href={information.mainImage.publicUrl}
                      target="_blank"
                    >
                      Show
                    </Button>
                  ) : (
                    <Button color="primary" type="button" target="_blank">
                      Show
                    </Button>
                  )}
                </FormGroup>
              </Col>
            </Row>
            <Row className="justify-content-md-center mt-5">
              <Col lg="0">
                <Button
                  color="default"
                  type="button"
                  onClick={() => this.PatchFranchiseEdit()}
                >
                  Save Changes
                </Button>
              </Col>
              <Col lg="0">
                <Col>
                  <Button
                    block
                    className="mb-3"
                    color="warning"
                    type="button"
                    onClick={() => this.toggleModal("notificationModal")}
                  >
                    Delete
                  </Button>
                  <Modal
                    className="modal-dialog-centered modal-danger"
                    contentClassName="bg-gradient-danger"
                    isOpen={this.state.notificationModal}
                    toggle={() => this.toggleModal("notificationModal")}
                  >
                    <div className="modal-header">
                      <h6 className="modal-title" id="modal-title-notification">
                        Your confirmation is required
                      </h6>
                      <button
                        aria-label="Close"
                        className="close"
                        data-dismiss="modal"
                        type="button"
                        onClick={() => this.toggleModal("notificationModal")}
                      >
                        <span aria-hidden={true}>×</span>
                      </button>
                    </div>
                    <div className="modal-body">
                      <div className="py-3 text-center">
                        <i class="fas fa-user-slash fa-3x"></i>
                        <h4 className="heading mt-4">ATTENTION !!</h4>
                        <p>Are you sure want to delete this slider ?</p>
                        <p>You can't undo this operation!</p>
                      </div>
                    </div>
                    <div className="modal-footer">
                      <Button
                        className="btn-white"
                        color="default"
                        type="button"
                        onClick={() => this.DeleteFranchise()}
                      >
                        Ok, Delete
                      </Button>
                      <Button
                        className="text-white ml-auto"
                        color="link"
                        data-dismiss="modal"
                        type="button"
                        onClick={() => this.toggleModal("notificationModal")}
                      >
                        Close
                      </Button>
                    </div>
                  </Modal>
                </Col>
              </Col>
            </Row>
          </div>
        </>
      );
    } else {
      return (
        <>
          <Spinner
            color="warning"
            style={{ width: "4rem", height: "4rem", marginLeft: "45%" }}
          />{" "}
        </>
      );
    }
  };

  toggleModal = (state) => {
    this.setState({
      [state]: !this.state[state],
    });
  };
  Alert = () => {
    if (this.state.succsess) {
      return (
        <UncontrolledAlert color="success" fade={false}>
          <span className="alert-inner--icon">
            <i className="ni ni-like-2" />
          </span>{" "}
          <span className="alert-inner--text">
            <strong>Success!</strong>slider Added Successfully
          </span>
        </UncontrolledAlert>
      );
    } else if (this.state.error) {
      return (
        <UncontrolledAlert color="danger" fade={false}>
          <span className="alert-inner--icon"></span>{" "}
          <span className="alert-inner--text">
            <strong>{this.state.errorCode}</strong>
          </span>
        </UncontrolledAlert>
      );
    } else {
      return <></>;
    }
  };
  render() {
    if (this.state.isRedirect) {
      return <Redirect to="/admin/silder" />;
    }
    return (
      <>
        <Header2 />
        <Container className=" mt--7" fluid>
          {/* Table */}
          <Row>
            <div className=" col">
              <Card className=" shadow">
                <CardHeader className=" bg-transparent">
                  <h3 className=" mb-0">Slider details</h3>
                </CardHeader>
                <CardBody>
                  <this.SellerDetails />
                </CardBody>
              </Card>

              <this.Alert />
              <br />
            </div>
          </Row>
        </Container>
      </>
    );
  }
}
