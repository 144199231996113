import Index from "views/Index.js";
import Logout from "./views/LogoutLoading.js";
import Login from "views/screens/Login.js";
import Category from "views/screens/Category.js";
import SubscriptionPlan from "views/screens/SubscriptionPlan";
import CustomerMessage from "views/screens/Customer/CustomerMessage";
import Customer from "./views/screens/Customer/Customer";
import Seller from "./views/screens/Seller/Seller.js";
import Orders from "./views/screens/Order/Orders.js";
import Deliverys from "./views/screens/Delivery/Deliverys.js";
import Deliverydetails from "./views/screens/Delivery/Deliverydetails.js";
import Franchise from "./views/screens/Franchice/AddFranchise.js";
import Services from "views/screens/Services.js";
import Commision from "views/screens/Commision.js";
import Payment from "views/screens/Payment.js";
import Silder from "views/screens/Slider/Silder.js";
import Silderdetails from "views/screens/Slider/Sliderdetails.js";
import SellerDetails from "views/screens/Seller/SellerDetails.js";
import CustomerDetails from "./views/screens/Customer/CustomerDetails";
import Products from "views/screens/Products/Products-list.js";
import ProductDetails from "views/screens/Products/ProductDetails.js";
import OrderDetails from "views/screens/Order/Orderdetails.js";
import ListFranchise from "views/screens/Franchice/ListFranchise.js";
import FranchiseDetails from "views/screens/Franchice/FranchiseDetails.js";
import Location from "views/screens/Location/Location.js";
import AddLocation from "views/screens/Location/Add Location.js";
import Category2 from "views/screens/Category2.js";
import Error from "views/screens/Error.js";
import Settings from "views/screens/Settings.js";
import Master from "views/screens/Master.js";
import AddShift from "views/screens/Shift/AddShift.js";
import CustomerDashboardSettings from "views/screens/Customer/CustomerDashboardSettings.js";
import Shopboosting from "views/screens/Shopboosting.js";
import SellerCategory from "views/screens/Seller/SellerCategory.js";
import Offers from "views/screens/Offers/Offer.js";
import MainSerivceCategory from "views/screens/MainServiceCategory.js";
import ShopType from "views/screens/Seller/ShopType.js";
import Attributes from "views/screens/Attributes.js";
import AddOffers from "views/screens/Offers/AddOffer";
import Report from "views/screens/Report/Report.js";
import DailyDeals from "views/screens/Products/DailyDeals.js";
var routes = [
  {
    path: "/index",
    name: "Dashboard",
    icon: "ni ni-tv-2 text-primary",
    component: Index,
    layout: "/admin",
    visible: true,
  },

  //Franchise......
  {
    name: "Franchise",
    icon: "ni ni-shop text-red",
    visible: true,
    child: [
      {
        path: "/franchise-list",
        name: "Add & List Franchise",
        icon: "ni ni-shop text-red",
        component: ListFranchise,
        layout: "/admin",
        visible: true,
      },

      {
        path: "/offers",
        name: "Coupon Code (Offers)",
        icon: "ni ni-bell-55 text-orange",
        component: Offers,
        layout: "/admin",
        visible: true,
      },
    ],
  },
  {
    path: "/franchise-list",
    name: "Add & List Franchise",
    icon: "ni ni-shop text-red",
    component: ListFranchise,
    layout: "/admin",
    visible: false,
  },

  {
    path: "/offers",
    name: "Coupon Code (Offers)",
    icon: "ni ni-bell-55 text-orange",
    component: Offers,
    layout: "/admin",
    visible: false,
  },
  {
    path: "/add-franchise",
    name: "Add Franchise",
    icon: "ni ni-fat-add text-yellow",
    component: Franchise,
    layout: "/admin",
    visible: false,
  },
  {
    path: "/franchise/details/:id",
    name: "Franchise Details",
    icon: "ni ni-shop text-yellow",
    component: FranchiseDetails,
    layout: "/admin",
    visible: false,
  },
  {
    path: "/add-offers",
    name: "Coupon Code (Offers)",
    icon: "ni ni-bell-55 text-orange",
    component: AddOffers,
    layout: "/admin",
    visible: false,
  },
  {
    path: "/offer-view/:id",
    name: "Products View",
    icon: "ni ni-fat-add text-blue",
    component: AddOffers,
    layout: "/admin",
    visible: false,
  },

  //Seller.....
  {
    name: "Seller",
    icon: "ni ni-circle-08 text-primary",
    visible: true,
    child: [
      {
        path: "/seller/:id",
        name: "Seller List",
        icon: "ni ni-circle-08 text-primary",
        component: Seller,
        layout: "/admin",
        visible: true,
      },
    ],
  },

  {
    path: "/seller/:id",
    name: "Seller List",
    icon: "ni ni-circle-08 text-primary",
    component: Seller,
    layout: "/admin",
    visible: false,
  },
  {
    path: "/sellerdetails/:id",
    name: "Seller Details",
    icon: "ni ni-button-power text-orange",
    component: SellerDetails,
    layout: "/admin",
    visible: false,
  },
  // {
  //   path: "/sevices",
  //   name: "Services",
  //   icon: "fas fa-concierge-bell text-warning",
  //   component: Services,
  //   layout: "/admin",
  //   visible: false
  // },

  // Customer..............

  {
    name: "Customer",
    icon: "fas fa-id-badge text-yellow",
    visible: true,
    child: [
      {
        path: "/customer",
        name: "Customer List",
        icon: "fas fa-id-badge text-yellow",
        component: Customer,
        layout: "/admin",
        visible: true,
      },

      {
        path: "/orders",
        name: "Orders",
        icon: "ni ni-box-2 text-orange",
        component: Orders,
        layout: "/admin",
        visible: true,
      },
      {
        path: "/message",
        name: "Message",
        icon: "ni ni-box-2 text-orange",
        component:  CustomerMessage,
        layout: "/admin",
        visible: true,
      },
    ],
  },

  {
    path: "/customer",
    name: "Customer List",
    icon: "fas fa-id-badge text-yellow",
    component: Customer,
    layout: "/admin",
    visible: false,
  },

  {
    path: "/customerdetails/:id",
    name: "Customer Details",
    icon: "ni ni-button-power text-gold",
    component: CustomerDetails,
    layout: "/admin",
    visible: false,
  },
  {
    path: "/orders",
    name: "Orders",
    icon: "ni ni-box-2 text-orange",
    component: Orders,
    layout: "/admin",
    visible: false,
  },
  {
    path: "/message",
    name: "Message",
    icon: "ni ni-box-2 text-orange",
    component:  CustomerMessage,
    layout: "/admin",
    visible: false,
  },
  {
    path: "/ordersdetails/:id/detail",
    name: "Orders Details",
    icon: "ni ni-box-2 text-orange",
    component: OrderDetails,
    layout: "/admin",
    visible: false,
  },

  // DeliverysBoys.....

  {
    name: "Delivery Boys",
    icon: "ni ni-delivery-fast text-black",
    visible: true,
    child: [
      {
        path: "/delivery-boys",
        name: "Delivery Boys List",
        // icon: "fa fa-truck text-black",
        icon: "ni ni-delivery-fast text-black",
        component: Deliverys,
        layout: "/admin",
        visible: true,
      },

      {
        path: "/addshift",
        name: "Add Shift",
        icon: "ni ni-watch-time text-green",
        component: AddShift,
        layout: "/admin",
        visible: true,
      },
    ],
  },
  {
    path: "/delivery-boys",
    name: "Delivery Boys List",
    // icon: "fa fa-truck text-black",
    icon: "ni ni-delivery-fast text-black",
    component: Deliverys,
    layout: "/admin",
    visible: false,
  },
  {
    path: "/addshift",
    name: "Add Shift",
    icon: "ni ni-watch-time text-green",
    component: AddShift,
    layout: "/admin",
    visible: false,
  },
  {
    path: "/deliverydetails/:id",
    name: "Delivery details",
    icon: "ni ni-truck-2 text-orange",
    component: Deliverydetails,
    layout: "/admin",
    visible: false,
  },

  // Products.....
  {
    path: "/products",
    name: "Products",
    icon: "ni ni-cart text-indigo",
    component: Products,
    layout: "/admin",
    visible: true,
  },
  {
    path: "/daily-deals",
    name: "Daily Deals",
    icon: "ni ni-tag text-pink",
    component: DailyDeals,
    layout: "/admin",
    visible: true,
  },
  {
    path: "/productsdetails/:id/detail",
    name: "Products Details",
    icon: "ni ni-circle-08 text-orange",
    component: ProductDetails,
    layout: "/admin",
    visible: false,
  },
  // Location.....
  {
    path: "/location",
    name: "Location",
    icon: "fas fa-map-marker-alt text-green",
    component: Location,
    layout: "/admin",
    visible: true,
  },
  {
    path: "/add-location",
    name: "Add Location",
    icon: "fas fa-map-marker-alt text-green",
    component: AddLocation,
    layout: "/admin",
    visible: false,
  },
  // {
  //   path: "/payment",
  //   name: "Order Payment",
  //   icon: "ni ni-credit-card text-red",
  //   component: Payment,
  //   layout: "/admin",
  //   visible: true
  // },

  // {
  //   path: "/commision",
  //   name: "Commisions",
  //   icon: "ni ni-money-coins text-green",
  //   component: Commision,
  //   layout: "/admin",
  //   visible: true
  // },

  // Category.....
  {
    name: "Category",
    icon: "ni ni-bullet-list-67 text-blue",
    visible: true,
    child: [
      {
        path: "/shoptype",
        name: "Shop Type",
        icon: "ni ni-bullet-list-67 text-blue",
        component: ShopType,
        layout: "/admin",
        visible: true,
      },
      {
        path: "/seller-category",
        name: "Seller Category",
        icon: "ni ni-bullet-list-67 text-blue",
        component: SellerCategory,
        layout: "/admin",
        visible: true,
      },
      {
        path: "/category",
        name: "Parent & Product Category (Category)",
        icon: "ni ni-bullet-list-67 text-blue",
        component: Category2,
        layout: "/admin",
        visible: true,
      },
      {
        path: "/mainservice-category",
        name: "Main Service Category",
        icon: "ni ni-bullet-list-67 text-blue",
        component: MainSerivceCategory,
        layout: "/admin",
        visible: true,
      },
    ],
  },
  {
    path: "/shoptype",
    name: "Shop Type",
    icon: "ni ni-bullet-list-67 text-blue",
    component: ShopType,
    layout: "/admin",
    visible: false,
  },
  {
    path: "/seller-category",
    name: "Seller Category",
    icon: "ni ni-bullet-list-67 text-blue",
    component: SellerCategory,
    layout: "/admin",
    visible: false,
  },
  {
    path: "/category",
    name: "Parent & Product Category (Category)",
    icon: "ni ni-bullet-list-67 text-blue",
    component: Category2,
    layout: "/admin",
    visible: false,
  },
  {
    path: "/mainservice-category",
    name: "Main Service Category",
    icon: "ni ni-bullet-list-67 text-blue",
    component: MainSerivceCategory,
    layout: "/admin",
    visible: false,
  },

  // Settings

  {
    name: "Settings",
    icon: "fa fa-cogs text-red",
    visible: true,
    child: [
      {
        path: "/silder",
        name: "Sliders",
        icon: "ni ni-album-2 text-yellow",
        component: Silder,
        layout: "/admin",
        visible: true,
      },

      {
        path: "/customerdashboardsetting",
        name: "Customer Dashboard",
        icon: "ni ni-ui-04 text-black",
        component: CustomerDashboardSettings,
        layout: "/admin",
        visible: true,
      },
      {
        path: "/subscription-plan",
        name: "Subscription Plan",
        icon: "ni ni-tag text-orange",
        component: SubscriptionPlan,
        layout: "/admin",
        visible: true,
      },
      {
        path: "/shopboosting",
        name: "Shop boosting",
        icon: "ni ni-tag text-blue",
        component: Shopboosting,
        layout: "/admin",
        visible: true,
      },
      {
        path: "/attributes",
        name: "Attributes",
        icon: "ni ni-archive-2 text-green",
        component: Attributes,
        layout: "/admin",
        visible: true,
      },
      {
        path: "/config",
        name: "Config",
        icon: "fa fa-cog text-blue",
        component: Settings,
        layout: "/admin",
        visible: true,
      },
      {
        path: "/master",
        name: "Master",
        icon: "fa fa-cogs text-red",
        component: Master,
        layout: "/admin",
        visible: true,
      },
    ],
  },
  {
    path: "/silder",
    name: "Sliders",
    icon: "ni ni-album-2 text-yellow",
    component: Silder,
    layout: "/admin",
    visible: false,
  },
  {
    path: "/sliderdetails/:id",
    name: "Slider details",
    icon: "ni ni-button-power text-orange",
    component: Silderdetails,
    layout: "/admin",
    visible: false,
  },
  {
    path: "/customerdashboardsetting",
    name: "Customer Dashboard",
    icon: "ni ni-ui-04 text-black",
    component: CustomerDashboardSettings,
    layout: "/admin",
    visible: false,
  },
  {
    path: "/subscription-plan",
    name: "Subscription Plan",
    icon: "ni ni-tag text-orange",
    component: SubscriptionPlan,
    layout: "/admin",
    visible: false,
  },
  {
    path: "/shopboosting",
    name: "Shop boosting",
    icon: "ni ni-tag text-blue",
    component: Shopboosting,
    layout: "/admin",
    visible: false,
  },
  {
    path: "/attributes",
    name: "Attributes",
    icon: "ni ni-archive-2 text-green",
    component: Attributes,
    layout: "/admin",
    visible: false,
  },
  {
    path: "/config",
    name: "Config",
    icon: "fa fa-cog text-blue",
    component: Settings,
    layout: "/admin",
    visible: false,
  },
  {
    path: "/master",
    name: "Master",
    icon: "fa fa-cogs text-red",
    component: Master,
    layout: "/admin",
    visible: false,
  },
  // Report
  {
    path: "/report",
    name: "Report",
    icon: "ni ni-chart-bar-32 text-orange",
    component: Report,
    layout: "/admin",
    visible: true,
  },


  // logout
  {
    path: "/logout",
    name: "Logout",
    icon: "ni ni-button-power text-info",
    component: Logout,
    layout: "/admin",
    visible: true,
  },

  // {
  //   path: "/category",
  //   name: "Category",
  //   icon: "ni ni-bullet-list-67 text-blue",
  //   component: Category,
  //   layout: "/admin",
  //   visible: true
  // },

  {
    path: "/login",
    name: "Login",
    icon: "ni ni-key-25 text-red",
    component: Login,
    layout: "/auth",
    visible: false,
  },
];
export default routes;
