import React, { Component } from "react";
import { Link } from "react-router-dom";
import Select from "react-select";
import {
  Card,
  CardHeader,
  Container,
  Row,
  Col,
  Button,
  Table,
  Pagination,
  PaginationItem,
  PaginationLink,
  CardFooter,
  FormGroup,
  Input,
  InputGroupAddon,
  InputGroupText,
  InputGroup,
  Spinner,
} from "reactstrap";
import Cookie from "js-cookie";
import Header2 from "components/Headers/Header2";
const axios = require("axios").default;
const URL = require("../../ApiUrl");

export default class Deliverys extends Component {
  state = {
    succsess: false,
    error: false,
    isLoaded: false,
    loading: false,
    limit: 20,
    page: 1,
    delivery: {},
    hasNextPage: false,
    originalData: [],
    category: [],
    id:""
  };

  GetFranchiseList = async () => {
    const token = Cookie.get("patrickToken")
      ? Cookie.get("patrickToken")
      : null;
    const config = {
      headers: { Authorization: `Bearer ${token}` },
    };

    let response = await axios
      .get(
        `${URL.BASE_URL}/admin-franchises/list?page=${1}&limit=${100}`,
        config
      )
      .catch(function (error) {
        console.log(error);
      });
    console.log("👇👇👇👇👇👇👇👇👇👇");
    console.log(response);
    if (response && response.data !== null) {
      this.setState({
        category: response.data.data.items,
      });
    } else if (response && response.data.statusCode !== 200) {
      this.setState({
        error: true,
        errorCode: response.data.msg,
      });
    } else {
      this.setState({
        status: false,
      });
    }
  };

  Getdelivery = async (p) => {
    this.setState({ isLoaded: false });
    const token = Cookie.get("patrickToken")
      ? Cookie.get("patrickToken")
      : null;
    const config = {
      headers: { Authorization: `Bearer ${token}` },
    };
    let id = this.state.id
    console.log(id,".......")
    let page = p ? p : this.state.page;
    let limit = this.state.limit;
    let response = await axios
      .get(`${URL.BASE_URL}/admin-delivery-boys/list?page=${page}&limit=${limit}&franchiseId=${id}`, config)
      .catch(function (error) {
        console.log(error);
      });

    console.log("👇👇👇👇👇👇👇👇👇👇");
    console.log(response);

    if (response && response.data !== null) {
      this.setState({
        delivery: response.data.data,
        orginalData: response.data.data,
        isLoaded: true,
      });
    } else if (response && response.data.statusCode !== 200) {
      this.setState({
        error: true,
        errorCode: response.data.msg,
      });
    } else {
      this.setState({
        status: false,
      });
    }
  };
  componentDidMount() {
    this.GetFranchiseList();
    this.Getdelivery();
  }

  handleChange2 = async (selectedOptionType) => {
    this.setState({ selectedOptionType });
    console.log(`Option selected:`, selectedOptionType);
    console.log(selectedOptionType.value);
    await this.setState({
      id: selectedOptionType.value,
    });
    this.Getdelivery();
  };
  FarnchiseSelect = () => {
    const { selectedOptionType } = this.state;
    let options = [{ value: "", label: "All" }];
    this.state.category.map((item) =>
      options.push({
        value: item.id,
        label: item.franchiseName,
      })
    );
    return (
      <>
        <Select
          value={selectedOptionType}
          onChange={this.handleChange2}
          options={options}
          placeholder="Select Franchise"
        />
      </>
    );
  };
  DelliveryDetails = () => {
    if (this.state.isLoaded) {
      return (
        <>
          <thead className="thead-light">
            <tr>
              <th scope="col">#</th>
              <th scope="col">Name</th>
              <th scope="col">Franchise Name</th>
              <th scope="col">Email</th>
              <th scope="col">Phone Number</th>
              <th scope="col">Action</th>
            </tr>
          </thead>
          <tbody>
            {this.state.delivery &&
              this.state.delivery.items.map((data, key) => {
                return (
                  <tr>
                    <td>{key + 1}</td>
                    <td>{data.deliveryBoyName}</td>
                    <td>{data.franchiseId.franchiseName}</td>
                    <td>{data.email}</td>
                    <td>{data.mobile}</td>
                    <td>
                      <Link to={`/admin/deliverydetails/${data.id}/details`}>
                        <Button
                          className=" btn-icon"
                          color="info"
                          size="sm"
                          type="button"
                        >
                          <i className=" ni ni-circle-08 pt-1"></i>
                          <span className="btn-inner--text">View</span>
                        </Button>
                      </Link>
                    </td>
                  </tr>
                );
              })}
          </tbody>
        </>
      );
    } else {
      return (
        <div className=" d-flex justify-content-center mr-mt-md-4">
          <Spinner
            style={{ width: "3rem", height: "3rem" }}
            type="grow"
            color="warning"
          />
        </div>
      );
    }
  };

  render() {
    return (
      <>
        <Header2 />

        <Container className=" mt--7" fluid>
          {/* Table */}
          <Row>
            <div className=" col">
              <Card className=" shadow">
                <CardHeader className=" border-0">
                  <Row>
                    <Col>
                      <h2 className="mb-0">Delivery-Boys</h2>
                    </Col>
                    <Col md="3">
                      <this.FarnchiseSelect />
                    </Col>
                    <Col md="4">
                      <FormGroup>
                        <InputGroup className="mb-2">
                          <InputGroupAddon addonType="prepend">
                            <InputGroupText>
                              <i className="ni ni-zoom-split-in" />
                            </InputGroupText>
                          </InputGroupAddon>
                          <Input
                            type="text"
                            id="search-input"
                            placeholder="Search for..."
                          />
                        </InputGroup>
                      </FormGroup>
                    </Col>
                  </Row>
                </CardHeader>

                <Table className="align-items-center table-flush" responsive>
                  <this.DelliveryDetails />
                </Table>

                <CardFooter className="py-4"></CardFooter>
              </Card>
            </div>
          </Row>
        </Container>
      </>
    );
  }
}
