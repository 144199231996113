import React from "react";
import { Redirect } from "react-router-dom";

// reactstrap components
import {
  Card,
  CardHeader,
  Container,
  Row,
  Button,
  Col,
  CardBody,
  FormGroup,
  Input,
  UncontrolledAlert,
  ListGroup,
  ListGroupItem,
  Modal,
  Table,
  Spinner,
  ModalBody,
  CardFooter,
} from "reactstrap";
// core components
import Header2 from "../../components/Headers/Header2";

import Cookie from "js-cookie";
import CustomInput from "reactstrap/lib/CustomInput";
import { isThisTypeNode } from "typescript";
const axios = require("axios").default;
const URL = require("../ApiUrl");

class Category extends React.Component {
  constructor(props) {
    super(props);
    this.PostCategory = this.PostCategory.bind(this);
    this.SelectParent = this.SelectParent.bind(this);
    this.handleSelectedParent = this.handleSelectedParent.bind(this);
    this.EditOption = this.EditOption.bind(this);
    this.EditOptionChild = this.EditOptionChild.bind(this);
    this.DeleteCategory = this.DeleteCategory.bind(this);

    this.state = {
      name: "",
      category: [],
      succsess: false,
      error: false,
      selectedParent: {},
      defaultModal: false,
      categoryName: "",
      categorySubName: "",
      id: [],
      commission: "",
      categoryCommission: "",
      categoryCommission1: "",
      categorySubCommission: "",
    };
  }

  async PostCategory() {
    const token = Cookie.get("patrickToken")
      ? Cookie.get("patrickToken")
      : null;
    const config = {
      headers: { Authorization: `Bearer ${token}` },
    };
    console.log(config);
    let data = {
      name: this.state.name,
    };
    if (this.state.parentId) {
      data.parentId = this.state.parentId;
    }
    let response = await axios
      .post(`${URL.BASE_URL}/admin-products/category/add`, data, config)

      .catch(function (error) {
        console.log(error);
      });
    console.log(data);
    if (response && response.data.statusCode === 200) {
      this.setState({
        succsess: true,
      });
    } else if (response && response.data.statusCode !== 200) {
      this.setState({
        error: true,
        errorCode: response.data.msg,
      });
    } else {
      this.setState({
        error: true,
      });
    }
    this.GetCategory();
    setTimeout(() => {
      this.setState({
        status: false,
        error: false,
      });
    }, 2000);

    console.log(response);
  }

  DeleteCategory = async () => {
    const token = Cookie.get("patrickToken")
      ? Cookie.get("patrickToken")
      : null;
    const config = {
      headers: { Authorization: `Bearer ${token}` },
    };

    let id = this.state.selectedParent._id;

    let response = await axios
      .delete(
        `${URL.BASE_URL}/admin-products/category/deletecateg?id=${id}`,
        config
      )
      .catch(function (error) {
        console.log(error);
      });
    if (response.data.statusCode === 200) {
      this.setState({
        isRedirect: true,
      });
    }
  };
  DeleteSubCategory = async (id1) => {
    const token = Cookie.get("patrickToken")
      ? Cookie.get("patrickToken")
      : null;
    const config = {
      headers: { Authorization: `Bearer ${token}` },
    };

    let response = await axios
      .delete(
        `${URL.BASE_URL}/admin-products/category/deletecateg?id=${id1}`,
        config
      )
      .catch(function (error) {
        console.log(error);
      });
    if (response.data.statusCode === 200) {
      this.setState({
        isRedirect: true,
      });
    }
  };

  async GetCategory() {
    const token = Cookie.get("patrickToken")
      ? Cookie.get("patrickToken")
      : null;
    const config = {
      headers: { Authorization: `Bearer ${token}` },
    };

    let response = await axios
      .get(`${URL.BASE_URL}/admin-products/category/get`, config)
      .catch(function (error) {
        console.log(error);
      });
    console.log("👇👇👇👇👇👇👇👇👇👇");
    console.log(response);
    if (response && response.data !== null) {
      this.setState({
        category: response.data,
      });
    } else if (response && response.data.statusCode !== 200) {
      this.setState({
        error: true,
        errorCode: response.data.msg,
      });
    } else {
      this.setState({
        status: false,
      });
    }
  }

  PatchSubCategoryEdit = async (id1) => {
    const token = Cookie.get("patrickToken")
      ? Cookie.get("patrickToken")
      : null;
    const config = {
      headers: { Authorization: `Bearer ${token}` },
    };
    console.log(config);
    let data = {
      name: this.state.categorySubName,
      id: id1,
    };
    console.log(data);
    if (this.state.parentId) {
      data.parentId = this.state.parentId;
    }

    let response = await axios
      .patch(
        `${URL.BASE_URL}/admin-products/category/editcateg?${id1}`,
        data,
        config
      )

      .catch(function (error) {
        console.log(error);
      });
    if (response && response.data.statusCode === 200) {
      this.setState({
        succsess: true,
        isRedirect: true,
      });
    } else if (response && response.data.statusCode !== 200) {
      this.setState({
        error: true,
        errorCode: response.data.msg,
      });
    } else {
      this.setState({
        error: true,
      });
    }

    console.log(response);
  };
  PatchCategoryEdit = async () => {
    const token = Cookie.get("patrickToken")
      ? Cookie.get("patrickToken")
      : null;
    const config = {
      headers: { Authorization: `Bearer ${token}` },
    };
    console.log(config);
    let data = {
      name: this.state.categoryName,

      id: this.state.selectedParent._id,
    };
    console.log(data);
    let id = this.state.selectedParent._id;
    let response = await axios
      .patch(
        `${URL.BASE_URL}/admin-products/category/editcateg?${id}`,
        data,
        config
      )
      .catch(function (error) {
        console.log(error);
      });
    if (response && response.data.statusCode === 200) {
      this.setState({
        succsess: true,
        isRedirect: true,
      });
    } else if (response && response.data.statusCode !== 200) {
      this.setState({
        error: true,
        errorCode: response.data.msg,
      });
    } else {
      this.setState({
        error: true,
      });
    }

    console.log(response);
  };

  handleChange = (e) => {
    this.setState({
      [e.target.name]: e.target.value,
    });
    console.log(e.target.value);
  };

  handleSelectedParent(e) {
    console.log(e.target.value);
    this.setState({
      selectedParent: this.state.category[e.target.value],
    });
  }

  componentDidMount() {
    this.GetCategory();
  }
  Alert = () => {
    if (this.state.succsess) {
      return (
        <UncontrolledAlert color="success" fade={false}>
          <span className="alert-inner--icon">
            <i className="ni ni-like-2" />
          </span>{" "}
          <span className="alert-inner--text">
            <strong>Success!</strong> Category Added Successfully
          </span>
        </UncontrolledAlert>
      );
    } else if (this.state.error) {
      return (
        <UncontrolledAlert color="danger" fade={false}>
          <span className="alert-inner--icon"></span>{" "}
          <span className="alert-inner--text">
            <strong>Opps!</strong> Something Went Wrong !
          </span>
        </UncontrolledAlert>
      );
    } else {
      return <></>;
    }
  };
  toggleModal = (state, id) => {
    this.setState({
      [state]: !this.state[state],
    });
  };

  Delete = () => {
    return (
      <>
        <Col md="12">
          <Button
            color="danger"
            type="button"
            onClick={() => this.toggleModal("notificationModal")}
          >
            Delete
          </Button>
          <Modal
            className="modal-dialog-centered modal-danger"
            contentClassName="bg-gradient-danger"
            isOpen={this.state.notificationModal}
            toggle={() => this.toggleModal("notificationModal")}
          >
            <div className="modal-header">
              <h6 className="modal-title" id="modal-title-notification">
                Your confirmation is required
              </h6>
              <button
                aria-label="Close"
                className="close"
                data-dismiss="modal"
                type="button"
                onClick={() => this.toggleModal("notificationModal")}
              >
                <span aria-hidden={true}>×</span>
              </button>
            </div>
            <div className="modal-body">
              <div className="py-3 text-center">
                <i class="fas fa-trash-alt fa-3x"></i>
                <h4 className="heading mt-4">ATTENTION !!</h4>
                <p>Are you sure want to delete this Category ?</p>
                <p>You can't undo this operation!</p>
              </div>
            </div>
            <div className="modal-footer">
              <Button
                className="btn-white"
                color="default"
                type="button"
                onClick={() => this.DeleteCategory()}
              >
                Ok, Delete
              </Button>

              <Button
                className="text-white ml-auto"
                color="link"
                data-dismiss="modal"
                type="button"
                onClick={() => this.toggleModal("notificationModal")}
              >
                Close
              </Button>
            </div>
          </Modal>
        </Col>
      </>
    );
  };
  DeleteChild = ({ id1 }) => {
    return (
      <>
        <Col md="12">
          <Button
            color="danger"
            type="button"
            size="sm"
            onClick={() => this.toggleModal("notificationModal2")}
          >
            Delete
          </Button>
          <Modal
            className="modal-dialog-centered modal-danger"
            contentClassName="bg-gradient-danger"
            isOpen={this.state.notificationModal2}
            toggle={() => this.toggleModal("notificationModal2")}
          >
            <div className="modal-header">
              <h6 className="modal-title" id1="modal-title-notification">
                Your confirmation is required
              </h6>
              <button
                aria-label="Close"
                className="close"
                data-dismiss="modal"
                type="button"
                onClick={() => this.toggleModal("notificationModal2")}
              >
                <span aria-hidden={true}>×</span>
              </button>
            </div>
            <div className="modal-body">
              <div className="py-3 text-center">
                <i class="fas fa-trash-alt fa-3x"></i>
                <h4 className="heading mt-4">ATTENTION !!</h4>
                <p>Are you sure want to delete this Sub Category ?</p>
                <p>You can't undo this operation!</p>
              </div>
            </div>
            <div className="modal-footer">
              <Button
                className="btn-white"
                color="default"
                type="button"
                onClick={() => this.DeleteSubCategory(id1)}
              >
                Ok, Delete
              </Button>

              <Button
                className="text-white ml-auto"
                color="link"
                data-dismiss="modal"
                type="button"
                onClick={() => this.toggleModal("notificationModal2")}
              >
                Close
              </Button>
            </div>
          </Modal>
        </Col>
      </>
    );
  };
  AddCategory = () => {
    return (
      <>
        <Container className=" mt--7" fluid>
          {/* Table */}
          <Row>
            <div className=" col">
              <Card className=" shadow">
                <CardHeader className=" bg-transparent">
                  <h3 className=" mb-0">Add Category</h3>
                </CardHeader>
                <CardBody>
                  <Row className=" icon-examples">
                    <Col lg="6" md="4">
                      <FormGroup>
                        <Input
                          placeholder="Name"
                          type="text"
                          name="name"
                          onChange={this.handleChange}
                        />
                      </FormGroup>
                    </Col>

                    <Col lg="6" md="4">
                      <this.SelectParent parentName="parentId" />
                    </Col>
                  </Row>
                  <Row className=" d-flex justify-content-center mr-mt-md-4">
                    <Col lg="3">
                      <Button
                        className="btn-icon btn-2"
                        color="info"
                        type="button"
                        block
                        onClick={() => this.PostCategory()}
                      >
                        <span className="btn-inner--icon">
                          <i class="fas fa-plus"></i>
                        </span>
                        <span className="btn-inner--text">Add</span>
                      </Button>
                    </Col>
                  </Row>
                </CardBody>
              </Card>
            </div>
          </Row>
        </Container>
      </>
    );
  };

  EditOption() {
    const [modalOpen, setModalOpen] = React.useState(false);
    return (
      <>
        <Button
          className="btn-icon btn-3"
          color="success"
          type="button"
          onClick={() => setModalOpen(!modalOpen)}
        >
          <span className="btn-inner--icon">
            <i className="ni ni-ruler-pencil" />
          </span>
          <span className="btn-inner--text">Edit</span>
        </Button>
        <Modal toggle={() => setModalOpen(!modalOpen)} isOpen={modalOpen}>
          <div className=" modal-header">
            <h4 className=" modal-title">Edit</h4>
            <button
              aria-label="Close"
              className=" close"
              type="button"
              onClick={() => setModalOpen(!modalOpen)}
            >
              <span aria-hidden={true}>×</span>
            </button>
          </div>
          <ModalBody>
            <div>
              <Row className="justify-content-lg-center">
                <Col lg="6">
                  <FormGroup>
                    <Input
                      placeholder="Name"
                      defaultValue={this.state.selectedParent.name}
                      type="text"
                      name="categoryName"
                      onChange={this.handleChange}
                    />
                  </FormGroup>
                </Col>
              </Row>

              <Row className="justify-content-lg-center">
                <Col lg="0">
                  <Button
                    className="btn-tooltip mr-3"
                    color="info"
                    type="button"
                    size="lg"
                    onClick={() => this.PatchCategoryEdit()}
                  >
                    <span className="btn-inner--text">Save Changes</span>
                  </Button>
                </Col>
              </Row>
            </div>
          </ModalBody>
        </Modal>
      </>
    );
  }
  EditOptionChild({ name, id1 }) {
    let children;

    if (this.state.selectedParent) {
      children = this.state.selectedParent.children;

      if (children) {
        const [modalOpen, setModalOpen] = React.useState(false);
        return (
          <>
            <Button
              className="btn-tooltip mr-3"
              color="success"
              type="button"
              size="sm"
              onClick={() => setModalOpen(!modalOpen)}
            >
              <span className="btn-inner--icon">
                <i className="ni ni-ruler-pencil" />
              </span>
              <span className="btn-inner--text">Edit</span>
            </Button>

            <Modal toggle={() => setModalOpen(!modalOpen)} isOpen={modalOpen}>
              <div className=" modal-header">
                <h4 className=" modal-title">Edit</h4>
                <button
                  aria-label="Close"
                  className=" close"
                  type="button"
                  onClick={() => setModalOpen(!modalOpen)}
                >
                  <span aria-hidden={true}>×</span>
                </button>
              </div>
              <ModalBody>
                <div>
                  <Row className="justify-content-lg-center">
                    <Col lg="6">
                      <FormGroup>
                        <Input
                          placeholder="Name"
                          name="categorySubName"
                          defaultValue={name}
                          type="text"
                          onChange={this.handleChange}
                        />
                      </FormGroup>
                    </Col>
                  </Row>

                  <Row className="justify-content-lg-center">
                    <Col lg="0">
                      <Button
                        className="btn-tooltip mr-3"
                        color="info"
                        type="button"
                        size="lg"
                        onClick={() => this.PatchSubCategoryEdit(id1)}
                      >
                        <span className="btn-inner--text">Save Changes</span>
                      </Button>
                    </Col>
                  </Row>
                </div>
              </ModalBody>
            </Modal>
          </>
        );
      } else {
        return <></>;
      }
    } else {
      return <></>;
    }
  }

  SelectParent({ parentName }) {
    if (parentName === "selectedParent") {
      return (
        <>
          <CustomInput
            type="select"
            name={parentName}
            onChange={this.handleSelectedParent}
          >
            <option value={null}>Select Parent</option>
            {this.state.category.map((data, key) => {
              return <option value={key}>{data.name}</option>;
            })}
          </CustomInput>
        </>
      );
    } else {
      return (
        <>
          <CustomInput
            type="select"
            name={parentName}
            // onChange={this.handleChange}
          >
            {/* <option value={null}>Select Parent</option>
            {this.state.category.map((data) => {
              return <option value={data._id}>{data.name}</option>;
            })} */}
          </CustomInput>
        </>
      );
    }
  }

  CategoryTable = () => {
    let children;

    if (this.state.selectedParent) {
      children = this.state.selectedParent.children;
      if (children) {
        return (
          <>
            <thead className="thead-light">
              <tr>
                <th scope="col">#</th>
                <th scope="col">Category Name</th>

                <th scope="col">Action</th>
              </tr>
            </thead>
            <tbody>
              {children.map((list, key) => (
                <tr>
                  <td>{key + 1}</td>
                  <td>{list.name}</td>

                  <td>
                    <Row>
                      <Col lg="0">
                        <this.EditOptionChild name={list.name} id1={list._id} />
                      </Col>
                      <Col lg="0">
                        <this.DeleteChild id1={list._id} />
                      </Col>
                    </Row>
                  </td>
                </tr>
              ))}
            </tbody>
          </>
        );
      } else {
        return <></>;
      }
    } else {
      return <></>;
    }
  };

  render() {
    if (this.state.isRedirect) {
      return window.location.reload(false);
    }

    return (
      <>
        <Header2 />
        {/* Page content */}
        <this.AddCategory />
        <Container className="mt-6" fluid></Container>

        <this.Alert />
        <Container className="mt--15" fluid>
          {/* Table */}
          <Row>
            <div className="col">
              <Card className="shadow">
                <CardHeader className="border-0">
                  <Row className="align-items-center">
                    <Col className="col">
                      <this.SelectParent parentName="selectedParent" />
                    </Col>
                    <Col lg="0">
                      <this.EditOption />
                    </Col>
                    <Col lg="0">
                      <this.Delete />
                    </Col>
                  </Row>
                </CardHeader>
                <Table className="align-items-center table-flush" responsive>
                  <this.CategoryTable />{" "}
                </Table>
                <CardFooter className="py-4"></CardFooter>
              </Card>
            </div>
          </Row>
        </Container>
      </>
    );
  }
}

export default Category;
