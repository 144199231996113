import React, { Component } from "react";
import { Link } from "react-router-dom";
import {
  Card,
  CardHeader,
  Container,
  Row,
  Col,
  CardBody,
  Form,
  FormGroup,
  Input,
  CustomInput,
  Button,
  UncontrolledAlert,
  Table,
  Spinner,
  CardFooter,
  InputGroupText,
  InputGroupAddon,
  InputGroup,
  Label,
} from "reactstrap";
import Header2 from "components/Headers/Header2.js";
import Cookie from "js-cookie";
const axios = require("axios").default;
const URL = require("../../ApiUrl");
export default class Location extends Component {
  state = {
    isLoaded: true,
    countryData: [],
    stateData: [],
    districtData: [],
    cityData: [],
  };

  GetLocation = async (name, val) => {
    console.log(name, val);
    let countryResponse, stateResponse, districtResponse, cityResponse;

    countryResponse = await axios
      .get(`${URL.BASE_URL}/masters/countries/list`)
      .catch(function (error) {
        console.log(error);
      });

    if (countryResponse && countryResponse.data !== null) {
      this.setState({
        countryData: countryResponse.data.data,
      });
    } else if (countryResponse && countryResponse.data.statusCode !== 200) {
      this.setState({
        error: true,
        errorCode: countryResponse.data.msg,
      });
    }

    if (countryResponse && countryResponse.data !== null) {
      stateResponse = await axios
        .get(
          `${URL.BASE_URL}/masters/states/list?countryId=60266020794814098914c874`
        )
        .catch(function (error) {
          console.log(error);
        });

      if (stateResponse && stateResponse.data !== null) {
        this.setState({
          stateData: stateResponse.data.data,
        });
      } else if (stateResponse && stateResponse.data.statusCode !== 200) {
        this.setState({
          error: true,
          errorCode: stateResponse.data.msg,
        });
      }
      console.log("stat++++++ ", stateResponse);
    }

    if (name === "stateId") {
      districtResponse = await axios
        .get(
          `${URL.BASE_URL}/masters/districts/list?countryId=60266020794814098914c874&stateId=${val}`
        )
        .catch(function (error) {
          console.log(error);
        });

      if (districtResponse && districtResponse.data !== null) {
        this.setState({
          districtData: districtResponse.data.data,
        });
      } else if (districtResponse && districtResponse.data.statusCode !== 200) {
        this.setState({
          error: true,
          errorCode: districtResponse.data.msg,
        });
      }
      console.log(districtResponse);
    }

    if (name === "districtId") {
      this.setState({ isLoaded: false });
      cityResponse = await axios
        .get(
          `${URL.BASE_URL}/masters/cities/list?countryId=60266020794814098914c874&stateId=${val}&districtId=${val}`
        )
        .catch(function (error) {
          console.log(error);
        });

      if (cityResponse && cityResponse.data !== null) {
        this.setState({
          cityData: cityResponse.data.data,
          isLoaded: true,
        });
      } else if (cityResponse && cityResponse.data.statusCode !== 200) {
        this.setState({
          error: true,
          errorCode: cityResponse.data.msg,
        });
      } else {
        this.setState({
          status: false,
        });
      }
      console.log(cityResponse);
    }
  };
  componentDidMount() {
    this.GetLocation();
  }
  handleChange = (e) => {
    if (
      e.target.name === "countryId" ||
      "stateId" ||
      "districtId" ||
      "cityId"
    ) {
      this.GetLocation(e.target.name, e.target.value);
    }

    this.setState({
      [e.target.name]: e.target.value,
    });
  };

  SelectCountry = () => {
    return (
      <>
        <Col lg="4">
          <FormGroup>
            <CustomInput
              type="select"
              name="countryId"
              onChange={this.handleChange}
            >
              <option value="">Select Country</option>;
              {this.state.countryData.map((data, key) => {
                return <option value={data.id}>{data.name}</option>;
              })}
            </CustomInput>
          </FormGroup>
        </Col>
      </>
    );
  };
  SelectState = () => {
    return (
      <>
        <Col lg="14">
          <FormGroup>
            <CustomInput
              type="select"
              name="stateId"
              onChange={this.handleChange}
            >
              <option value="">Select State</option>;
              {this.state.stateData.map((data, key) => {
                return <option value={data.id}>{data.name}</option>;
              })}
            </CustomInput>
          </FormGroup>
        </Col>
      </>
    );
  };

  SelectDistrict = () => {
    return (
      <>
        <Col lg="16">
          <FormGroup>
            <CustomInput
              type="select"
              name="districtId"
              onChange={this.handleChange}
            >
              <option value="">Select District</option>;
              {this.state.districtData.map((data, key) => {
                return <option value={data.id}>{data.name}</option>;
              })}
            </CustomInput>
          </FormGroup>
        </Col>
      </>
    );
  };

  LocationList = () => {
    if (this.state.isLoaded) {
      return (
        <>
          <thead>
            <tr>
              <th scope="col"></th>
              <th scope="col">#</th>
              <th scope="col">City</th>
            </tr>
          </thead>
          <tbody>
            {this.state.cityData.map((data, key) => {
              return (
                <tr>
                  <td></td>
                
                  <td>{key + 1}</td>
                  <td>{data.name}</td>
                </tr>
              );
            })}
          </tbody>
        </>
      );
    } else {
      return (
        <div className=" d-flex justify-content-center mr-mt-md-4">
          <Spinner
            style={{ width: "3rem", height: "3rem" }}
            type="grow"
            color="warning"
          />
        </div>
      );
    }
  };

  render() {
    return (
      <>
        <Header2 />
        {/* Page content */}
        <Container className="mt--7" fluid>
          {/* Table */}
          <Row>
            <Col className="order-xl-1" xl="12">
              <Card className="shadow">
                <CardHeader className="border-0">
                  <Row className="justify-content-center">
                    <Col>
                      <h2 className="mb-0">Location</h2>
                    </Col>
                    <Row>
                      <Col lg="0">
                        <this.SelectState />
                      </Col>
                      <Col>
                        <this.SelectDistrict />
                      </Col>
                    </Row>
                    <Col md="3">
                      <FormGroup>
                        {/* <InputGroup className="mb-2">
                          <InputGroupAddon addonType="prepend">
                            <InputGroupText>
                              <i className="ni ni-zoom-split-in" />
                            </InputGroupText>
                          </InputGroupAddon>
                          <Input
                            type="text"
                            id="search-input"
                            placeholder="Search for..."
                          />
                        </InputGroup> */}
                        <Link to={`/admin/add-location`}>
                          <Button
                            className=" btn-icon"
                            outline
                            color="success"
                            size="md"
                            block
                            type="button"
                          >
                            <i class="fas fa-location-arrow"></i>{" "}
                            <span className="btn-inner--text">
                              Add Location
                            </span>
                          </Button>
                        </Link>
                      </FormGroup>
                    </Col>
                  </Row>
                </CardHeader>
                <CardBody>
                  <Table className="align-items-center table-flush" responsive>
                    <this.LocationList />{" "}
                  </Table>
                </CardBody>
                <CardFooter className="py-4">
                  <nav aria-label="..."></nav>
                </CardFooter>
              </Card>
            </Col>
          </Row>
        </Container>
      </>
    );
  }
}
