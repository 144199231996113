import React, { Component } from "react";
import { Link } from "react-router-dom";
import Header2 from "../../../components/Headers/Header2";
import Select from "react-select";

import {
  Card,
  CardHeader,
  Container,
  Row,
  Button,
  Col,
  CardBody,
  FormGroup,
  Input,
  UncontrolledAlert,
  ListGroup,
  ListGroupItem,
  Modal,
  InputGroup,
  InputGroupAddon,
  InputGroupText,
  Table,
  Spinner,
  ModalBody,
  CardFooter,
} from "reactstrap";
import Cookie from "js-cookie";
const axios = require("axios").default;
const URL = require("../../ApiUrl");

export default class Offers extends Component {
  state = {
    franchisedata: [],
    category: [],
    page: 1,
    limit: 20,
    deleteId: "",
    isLoad: false,
    offerlistdata: {},
    offertype: "All",
    offerstatus: null,
  };

  GetOffersList = async () => {
    const { offertype, offerstatus } = this.state;
    console.log(offerstatus, offertype, "🎎🎎🎎🎎🎎");
    const token = Cookie.get("patrickToken")
      ? Cookie.get("patrickToken")
      : null;
    const config = {
      headers: { Authorization: `Bearer ${token}` },
    };
    let url = `${
      URL.BASE_URL
    }/admin-offers/list?type=${offertype}&isActive=${offerstatus}&page=${1}&limit=${100}`;

    let response = await axios.get(url, config).catch(function (error) {
      console.log(error);
    });
    console.log("👇👇👇👇👇👇👇👇👇👇");
    console.log(response);
    if (response && response.data !== null) {
      this.setState({
        offerlistdata: response.data.data.items,
        isLoaded: true,
      });
      console.log(this.state.offerlistdata, "🎄🎄🎄🎄🎄🎄🎄🎄🎄");
    } else if (response && response.data.statusCode !== 200) {
      this.setState({
        error: true,
        errorCode: response.data.msg,
      });
    } else {
      this.setState({
        status: false,
      });
    }
  };

  componentDidMount() {
    this.GetOffersList();
  }

  handleChange = async (selectedOptionStatus) => {
    this.setState({ selectedOptionStatus });
    let status = "";
    console.log(`Option selected:`, selectedOptionStatus);
    if (selectedOptionStatus.value === true) {
      status = true;
    } else if (selectedOptionStatus.value === false) {
      status = false;
    } else {
      status = "All";
    }
    await this.setState({
      offerstatus: status,
    });
    this.GetOffersList();
  };

  OfferStatusType = () => {
    const { selectedOptionStatus } = this.state;
    const options = [
      { value: true, label: "Yes" },
      { value: false, label: "No" },
      { value: "All", label: "All" },
    ];
    return (
      <>
        <Select
          value={selectedOptionStatus}
          onChange={this.handleChange}
          options={options}
          placeholder="Offer Status Type"
        />
      </>
    );
  };

  handleChange2 = async (selectedOptionType) => {
    this.setState({ selectedOptionType });
    let type = "";
    console.log(`Option selected:`, selectedOptionType);
    if (selectedOptionType.value === "referral") {
      type = "referral";
    } else if (selectedOptionType.value === "coupon") {
      type = "coupon";
    } else {
      type = "All";
    }
    await this.setState({
      offertype: type,
    });
    this.GetOffersList();
  };

  OfferType = () => {
    const { selectedOptionType } = this.state;
    const options = [
      { value: "referral", label: "Referal Code" },
      { value: "coupon", label: "CouponCode" },
      { value: "All", label: "All" },
    ];
    return (
      <>
        <Select
          value={selectedOptionType}
          onChange={this.handleChange2}
          options={options}
          placeholder="Offer Type"
        />
      </>
    );
  };

  OffersList = () => {
    let today;
    if (this.state.isLoaded) {
      return (
        <>
          <thead className="thead-light">
            <tr>
              <th>#</th>
              <th>Offer code</th>
              <th>Offer Name</th>
              <th>Offer Percentage</th>
              <th>Shipping Amount</th>
              <th>Offer Type</th>
              <th>Status</th>
              <th>Expiry Date</th>
              <th>Actions</th>
            </tr>
          </thead>
          <tbody>
            {this.state.offerlistdata &&
              this.state.offerlistdata.map((data, key) => {
                const formatYmd = (date) => date.toISOString().slice(0, 10);
                today = formatYmd(new Date(data.expiryDate));
                console.log(today, "🎎🎎🎎🎎🎎");
                return (
                  <tr>
                    <td>
                      {(this.state.page - 1) * this.state.limit + key + 1}
                    </td>
                    <td>{data.code}</td>
                    <td>{data.name}</td>
                    <td className="text-center">{data.percentage}</td>
                    <td className="text-center">{data.shoppingAmount}</td>
                    <td>{data.type}</td>
                    <td>{data.isActive === true ? "Yes" : "No"}</td>
                    <td>
                      {today}
                    </td>
                    <td>
                      <Link to={`offer-view/${data.id}`}>
                        <Button
                          className=" btn-icon"
                          color="info"
                          size="sm"
                          type="button"
                        >
                          <i className=" ni ni-circle-08 pt-1"></i>
                          <span className="btn-inner--text">View</span>
                        </Button>
                      </Link>
                    </td>
                  </tr>
                );
              })}
          </tbody>
        </>
      );
    } else {
      return (
        <div className=" d-flex justify-content-center ">
          <Spinner
            style={{ width: "3rem", height: "3rem" }}
            type="grow"
            color="warning"
          />
        </div>
      );
    }
  };
  render() {
    return (
      <>
        <Header2 />
        <Container className=" mt--7" fluid>
          {/* Table */}
          <Row>
            <div className=" col">
              <Card className=" shadow">
                <CardHeader className=" border-0">
                  <Row>
                    <Col>
                      <h2 className="mb-0">Offers List</h2>
                    </Col>
                    <Col md="3">
                      <this.OfferType />
                    </Col>
                    <Col md="3">
                      <this.OfferStatusType />
                    </Col>

                    <Col md="4">
                      <FormGroup>
                        <Link to={`/admin/add-offers`}>
                          <Button
                            className=" btn-icon"
                            outline
                            color="success"
                            size="md"
                            block
                            type="button"
                          >
                            <i class="fas fa-user-plus"></i>
                            <span className="btn-inner--text">Add Offers</span>
                          </Button>
                        </Link>
                      </FormGroup>
                    </Col>
                  </Row>
                </CardHeader>

                <Table className="align-items-center table-flush" responsive>
                  <this.OffersList />
                </Table>

                <CardFooter className="py-4">
                  {/* <this.Pagination/> */}
                </CardFooter>
              </Card>
            </div>
          </Row>
        </Container>
      </>
    );
  }
}
