import React, { Component } from "react";

import {
  Card,
  CardHeader,
  Container,
  Row,
  Col,
  CardBody,
  Form,
  FormGroup,
  Input,
  Label,
  CustomInput,
  Button,
  UncontrolledAlert,
  Modal,
  InputGroup,
  InputGroupAddon,
  InputGroupText,
} from "reactstrap";
import Header2 from "components/Headers/Header2.js";
import Cookie from "js-cookie";

const axios = require("axios").default;
const URL = require("../../ApiUrl");
export default class AddCredential extends Component {
  state = {
    formModal1: "",
    formModal2: "",
    formModal3: "",

    name: "",
    defaultModal: false,
    succsess: false,
    error: false,
    isLoaded: true,
    countryData: [],
    stateData: [],
    districtData: [],
    cityData: [],
    countryId: "",
    stateId: "",
    districtId: "",
    cityId: "",
  };
  toggleModal = (state) => {
    this.setState({
      [state]: !this.state[state],
    });
  };

  PostState = async (e) => {
    e.preventDefault();
    const token = Cookie.get("patrickToken")
      ? Cookie.get("patrickToken")
      : null;
    const config = {
      headers: { Authorization: `Bearer ${token}` },
    };
    console.log(config);

    let data = {
      name: this.state.name,
      countryId: "60266020794814098914c874",
    };

    let response = await axios
      .post(`${URL.BASE_URL}/masters/countries/addstate`, data, config)
      .catch(function (error) {
        console.log(error);
      });

    if (response && response.data.statusCode === 200) {
      this.setState({
        succsess: true,
      });
    } else if (response && response.data.statusCode !== 200) {
      this.setState({
        error: true,
        errorCode: response.data.msg,
      });
    } else {
      this.setState({
        error: true,
        errorCode: "Oops something went wrong!!",
      });
    }
    this.GetLocation();
    this.toggleModal("formModal1");

    setTimeout(() => {
      this.setState({
        status: false,
        error: false,
      });
    }, 2000);

    console.log(response);
  };
  PostDistrict = async (e) => {
    e.preventDefault();
    const token = Cookie.get("patrickToken")
      ? Cookie.get("patrickToken")
      : null;
    const config = {
      headers: { Authorization: `Bearer ${token}` },
    };
    console.log(config);

    let data = {
      name: this.state.name,

      countryId: "60266020794814098914c874",
      stateId: this.state.stateId,
    };

    let response = await axios
      .post(`${URL.BASE_URL}/masters/countries/adddist`, data, config)
      .catch(function (error) {
        console.log(error);
      });

    if (response && response.data.statusCode === 200) {
      this.setState({
        succsess: true,
      });
    } else if (response && response.data.statusCode !== 200) {
      this.setState({
        error: true,
        errorCode: response.data.msg,
      });
    } else {
      this.setState({
        error: true,
        errorCode: "Oops something went wrong!!",
      });
    }
    this.GetLocation();
    this.toggleModal("formModal2");
    setTimeout(() => {
      this.setState({
        status: false,
        error: false,
      });
    }, 2000);

    console.log(response);
  };
  PostCity = async (e) => {
    e.preventDefault();
    const token = Cookie.get("patrickToken")
      ? Cookie.get("patrickToken")
      : null;
    const config = {
      headers: { Authorization: `Bearer ${token}` },
    };
    console.log(config);

    let data = {
      name: this.state.name,

      countryId: "60266020794814098914c874",
      stateId: this.state.stateId,
      districtId: this.state.districtId,
    };

    let response = await axios
      .post(`${URL.BASE_URL}/masters/countries/addcity`, data, config)
      .catch(function (error) {
        console.log(error);
      });

    if (response && response.data.statusCode === 200) {
      this.setState({
        succsess: true,
      });
    } else if (response && response.data.statusCode !== 200) {
      this.setState({
        error: true,
        errorCode: response.data.msg,
      });
    } else {
      this.setState({
        error: true,
        errorCode: "Oops something went wrong!!",
      });
    }
    this.GetLocation();
    this.toggleModal("formModal3");
    setTimeout(() => {
      this.setState({
        status: false,
        error: false,
      });
    }, 2000);

    console.log(response);
  };
  GetLocation = async (name, val) => {

    console.log(name, val,"🎈🎈🎈🎈🎈🎈");
    let countryResponse, stateResponse, districtResponse, cityResponse;

    countryResponse = await axios
      .get(`${URL.BASE_URL}/masters/countries/list`)
      .catch(function (error) {
        console.log(error);
      });

    if (countryResponse && countryResponse.data !== null) {
      this.setState({
        countryData: countryResponse.data.data,
      });
    } else if (countryResponse && countryResponse.data.statusCode !== 200) {
      this.setState({
        error: true,
        errorCode: countryResponse.data.msg,
      });
    }

    if (countryResponse && countryResponse.data !== null) {
      stateResponse = await axios
        .get(
          `${URL.BASE_URL}/masters/states/list?countryId=60266020794814098914c874`
        )
        .catch(function (error) {
          console.log(error);
        });

      if (stateResponse && stateResponse.data !== null) {
        this.setState({
          stateData: stateResponse.data.data,
        });
      } else if (stateResponse && stateResponse.data.statusCode !== 200) {
        this.setState({
          error: true,
          errorCode: stateResponse.data.msg,
        });
      }
      console.log("stat++++++ ", stateResponse);
    }

    if (name === "stateId") {
      districtResponse = await axios
        .get(
          `${URL.BASE_URL}/masters/districts/list?countryId=60266020794814098914c874&stateId=${val}`
        )
        .catch(function (error) {
          console.log(error);
        });

      if (districtResponse && districtResponse.data !== null) {
        this.setState({
          districtData: districtResponse.data.data,
        });
      } else if (districtResponse && districtResponse.data.statusCode !== 200) {
        this.setState({
          error: true,
          errorCode: districtResponse.data.msg,
        });
      }
      console.log("dis++++++++++++++", districtResponse);
    }

    if (name === "districtId") {
      cityResponse = await axios
        .get(
          `${URL.BASE_URL}/masters/cities/list?countryId=60266020794814098914c874&stateId=${val}&districtId=${val}`
        )
        .catch(function (error) {
          console.log(error);
        });

      if (cityResponse && cityResponse.data !== null) {
        this.setState({
          cityData: cityResponse.data.data,
        });
      } else if (cityResponse && cityResponse.data.statusCode !== 200) {
        this.setState({
          error: true,
          errorCode: cityResponse.data.msg,
        });
      } else {
        this.setState({
          status: false,
        });
      }
      console.log("cit++++++++++++++++++=", cityResponse);
    }
  };

  handleChange = async (e) => {
    console.log(e.target.name,"🎈🎈🎈🎈🎈🎈")
    if (
      e.target.name === "countryId" ||
      "stateId" ||
      "districtId" ||
      "cityId"
    ) {
      this.GetLocation(e.target.name, e.target.value);
    }

  await  this.setState({
      [e.target.name]: e.target.value,
    });
  };

  componentDidMount() {
    this.GetLocation();
  }

  Alert = () => {
    if (this.state.succsess) {
      return (
        <UncontrolledAlert color="success" fade={false}>
          <span className="alert-inner--icon">
            <i className="ni ni-like-2" />
          </span>{" "}
          <span className="alert-inner--text">
            <strong>Success!</strong> Location Added Successfully
          </span>
        </UncontrolledAlert>
      );
    } else if (this.state.error) {
      return (
        <UncontrolledAlert color="danger" fade={false}>
          <span className="alert-inner--icon"></span>{" "}
          <span className="alert-inner--text">
            <strong>Opps!</strong> Something Went Wrong !
          </span>
        </UncontrolledAlert>
      );
    } else {
      return <></>;
    }
  };

  AddCredential = () => {
    let val2 = this.state.districtData;
    console.log(val2, "🎈🎈🎈🎈🎈🎈🎈");
    return (
      <Row>
        <Col lg="4">
          <Button
            block
            color="warning"
            type="button"
            onClick={() => this.toggleModal("formModal1")}
          >
            Add state
          </Button>
          <Modal
            className="modal-dialog-centered"
            size="sm"
            isOpen={this.state.formModal1}
            toggle={() => this.toggleModal("formModal1")}
          >
            <div>
              <Card className="bg-secondary shadow border-0">
                <CardBody className="px-lg-5 py-lg-5">
                  <FormGroup className="mb-3">
                    <Input
                      placeholder="Enter the state"
                      type="text"
                      name="name"
                      onChange={this.handleChange}
                    />
                  </FormGroup>
                  <Row className=" d-flex justify-content-center mr-mt-md-4">
                    <Col lg="12">
                      <Button
                        className="text-white ml-auto"
                        color="warning"
                        data-dismiss="modal"
                        type="button"
                        onClick={() => this.toggleModal("formModal1")}
                      >
                        Close
                      </Button>

                      <Button
                        className="btn-icon btn-2"
                        color="info"
                        type="button"
                        onClick={this.PostState}
                      >
                        <span className="btn-inner--icon">
                          <i class="fas fa-plus"></i>
                        </span>
                        <span className="btn-inner--text">Add</span>
                      </Button>
                    </Col>
                  </Row>
                </CardBody>
              </Card>
            </div>
          </Modal>
        </Col>

        <Col lg="4">
          <Button
            block
            color="warning"
            type="button"
            onClick={() => this.toggleModal("formModal2")}
          >
            Add district
          </Button>
          <Modal
            className="modal-dialog-centered"
            size="sm"
            isOpen={this.state.formModal2}
            toggle={() => this.toggleModal("formModal2")}
          >
            <div>
              <Card className="bg-secondary shadow border-0">
                <CardBody className="px-lg-5 py-lg-5">
                  <FormGroup className="mb-3">
                    <InputGroup className="input-group-alternative">
                      <CustomInput
                        type="select"
                        name="stateId"
                        onChange={this.handleChange}
                      >
                        <option value="">Select state</option>;
                        {this.state.stateData &&
                          this.state.stateData.map((data, key) => {
                            return <option value={data.id}>{data.name}</option>;
                          })}
                      </CustomInput>
                    </InputGroup>
                    <br />
                    <Input
                      placeholder="Enter the district"
                      type="text"
                      name="name"
                      onChange={this.handleChange}
                    />
                  </FormGroup>
                  <Row className=" d-flex justify-content-center mr-mt-md-4">
                    <Col lg="12">
                      <Button
                        className="text-white ml-auto"
                        color="warning"
                        data-dismiss="modal"
                        type="button"
                        onClick={() => this.toggleModal("formModal2")}
                      >
                        Close
                      </Button>
                      <Button
                        className="btn-icon btn-2"
                        color="info"
                        type="button"
                        onClick={this.PostDistrict}
                      >
                        <span className="btn-inner--icon">
                          <i class="fas fa-plus"></i>
                        </span>
                        <span className="btn-inner--text">Add</span>
                      </Button>
                    </Col>
                  </Row>
                </CardBody>
              </Card>
            </div>
          </Modal>
        </Col>

        <Col lg="4">
          <Button
            block
            color="warning"
            type="button"
            onClick={() => this.toggleModal("formModal3")}
          >
            Add City
          </Button>
          <Modal
            className="modal-dialog-centered"
            size="sm"
            isOpen={this.state.formModal3}
            toggle={() => this.toggleModal("formModal3")}
          >
            <div>
              <Card className="bg-secondary shadow border-0">
                <CardBody className="px-lg-5 py-lg-5">
                  <FormGroup className="mb-3">
                    <InputGroup className="input-group-alternative">
                      <CustomInput
                        type="select"
                        name="stateId"
                        onChange={this.handleChange}
                      >
                        <option value="">Select state</option>;
                        {this.state.stateData.map((data, key) => {
                          return <option value={data.id}>{data.name}</option>;
                        })}
                      </CustomInput>
                    </InputGroup>
                    <br />
                    <InputGroup className="input-group-alternative">
                      <CustomInput
                        type="select"
                        name="districtId"
                        onChange={this.handleChange}
                      >
                        <option value="">Select district</option>;
                        {this.state.districtData &&
                          this.state.districtData.map((data, key) => {
                            return <option value={data.id}>{data.name}</option>;
                          })}
                      </CustomInput>
                    </InputGroup>

                    <br />

                    <Input
                      placeholder="Enter the city"
                      type="text"
                      name="name"
                      onChange={this.handleChange}
                    />
                  </FormGroup>
                  <Row className=" d-flex justify-content-center mr-mt-md-4">
                    <Col lg="12">
                      <Button
                        className="text-white ml-auto"
                        color="warning"
                        data-dismiss="modal"
                        type="button"
                        onClick={() => this.toggleModal("formModal3")}
                      >
                        Close
                      </Button>
                      <Button
                        className="btn-icon btn-2"
                        color="info"
                        type="button"
                        onClick={this.PostCity}
                      >
                        <span className="btn-inner--icon">
                          <i class="fas fa-plus"></i>
                        </span>
                        <span className="btn-inner--text">Add</span>
                      </Button>
                    </Col>
                  </Row>
                </CardBody>
              </Card>
            </div>
          </Modal>
        </Col>
      </Row>
    );
  };

  AddCity = () =>{
    return(
      <>
      
      
      </>
    )
  }
  render() {
    return (
      <>
        <Header2 />
        <Container className=" mt--7" fluid>
          {/* Table */}
          <Row>
            <div className=" col">
              <Card className=" shadow">
                <CardHeader className=" bg-transparent">
                  <h3 className=" mb-0">Add Location</h3>
                </CardHeader>

                <CardBody>
                  <this.AddCredential />
                </CardBody>
              </Card>
            </div>
          </Row>
          <this.Alert />
        </Container>
      </>
    );
  }
}
